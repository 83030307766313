import React, { useContext } from 'react';
import { Navbar, Container,Offcanvas,Nav,NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../App';
import NavContext from './NavContext';
import { Trans, useTranslation } from 'react-i18next';

const imgurl="../../assets/wellness/new/"
const Nav2 = () => {
    const authstate = useContext(AuthContext)
    const { t, i18n } = useTranslation()
    
    return (
        <div style={{ paddingBottom: '90px', backgroundColor: 'white', textDecoration: 'underline' }}>
            {authstate.authstate?<>
        <Navbar collapseOnSelect expand="lg" bg='light'  fixed='top' variant='light'  >
  <Container>
  <Navbar.Brand href="#home"> <img src="../../assets/wellness/Logo.png" height="50px" /></Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <NavContext />
                            
                            
      
    </Nav>
                        <Nav  className="flex-list" id="menu">
                            
                        <Nav.Link href="/#home" className="pipe"style={{paddingLeft:'10px',paddingRight:'10px'}} > <img src={imgurl+t('wellness.nav_icons.home')} height="50px" /></Nav.Link>
                        <Nav.Link  href="/#resources" className="pipe" style={{paddingLeft:'10px',paddingRight:'10px'}}> <img src={imgurl+t('wellness.nav_icons.resources')} height="50px" /></Nav.Link>
                        <Nav.Link  href="/#notification" className="pipe" style={{paddingLeft:'10px',paddingRight:'10px'}}> <img src={imgurl+t('wellness.nav_icons.notification')} height="50px" /></Nav.Link>
    <div style={{paddingTop:'30px'}}>
                                {/* <img src="../../assets/wellness/avatar.png" height="60px" />                  */}
                                
                                <table>
                                    <tr style={{paddingTop:'-30px'}}>
                                        <td>
                                            <i class="fas fa-user"></i>
                                            
                                        </td>
                                            <td>
                                                {/* <i class="fas fa-user"></i> */}
                                            <NavDropdown title="Hi" id="collasible-nav-dropdown" style={{paddingLeft:'2px',paddingRight:'20px'}}>
        <NavDropdown.Item href="/#my-profile-data">Profile Data</NavDropdown.Item>
        <NavDropdown.Item href="/#changepassword">Change Password</NavDropdown.Item>
        <NavDropdown.Item href="/#contact-us">Contact Us</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item href="/#signout">Logout</NavDropdown.Item>
      </NavDropdown>
                                        </td>
                                        <td style={{display:'hidden'}}>
                                            &nbsp;
                                
                                        </td>
                                        <td style={{display:'hidden'}}>
                                            &nbsp;
                                            
                                        </td>
                                        <td style={{display:'hidden'}}>
                                            &nbsp;
                                            
                                        </td>
                                    </tr>
      </table>
      </div>
      {/* <Nav.Link href="#deets">More deets</Nav.Link>
      <Nav.Link eventKey={2} href="#memes">
        Dank memes
      </Nav.Link> */}
                        </Nav>
                        
  </Navbar.Collapse>
                </Container>
                
                </Navbar>
            </> : <>
                    <br />
                    <center><NavContext /></center>
            
            </>}
            
            </div>
     );
}
 
export default Nav2;