import axios from 'axios';
import React,{useContext, useEffect, useState} from 'react';
import { Card, Button,Spinner } from 'react-bootstrap'
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { QuestionContext } from '../../App';
import url from '../url';
const year=new Date().getFullYear()-10

const MyProfiledata = () => {
    const [redirect, setRedirect] = useState(false)
    const [picture, setPicture] = useState(null);
  const [imgData, setImgData] = useState(null);
  const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);
    const [spin, setSpin] = useState('false')
    const { questionstate, dispatchQuestion,updateProfile,allergystate, dispatAllery,dispatRecentAcute ,dispatRecentHistory} = useContext(QuestionContext)
    const [years, setYears] = useState([])
    const [races, setRaces] = useState([])
    const [occupations, setOccupations] = useState([])
    const [levels, setLevels] = useState([])
    const [redirectprofile,setRedirectProfile]=useState(false)
  const onChangePicture = e => {
    if (e.target.files[0]) {
      console.log("picture: ", e.target.files);
      console.log("picture: ", 'e.target.files[0]');
      console.log("picture: ", e.target.files[0]);
        setPicture(e.target.files[0]);
        dispatchQuestion({type:'picture', value:e.target.files[0]})
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
    
    useEffect(() => {
        getCountries()
        getCities()
        
        fetchBio()
        // getOccupations()
        // getLevels()
        // renderyears()

        getRaces()
        getOccupations()
        getLevels()
        renderyears()
        
        

        
    }, [])

    const getRaces = () => {
        setSpin('border')
        
        var token=sessionStorage.getItem('mytoken', false)
                const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        axios.get(url + 'wellness/races', config)
            .then(res => {
                // console.log(res.data.result)
                setRaces(res.data.result)
            
            }).catch(e => {
                console.log(e)
            
            }).then(res => {
                setSpin(false)
            
        })
    }
    const getOccupations = () => {
        setSpin('border')
        
        var token=sessionStorage.getItem('mytoken', false)
                const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        axios.get(url + 'wellness/occupations', config)
            .then(res => {
                // console.log(res.data.result)
                setOccupations(res.data.result)
            
            }).catch(e => {
                console.log(e)
            
            }).then(res => {
                setSpin(false)
            
        })
    }
    const getLevels = () => {
        // setSpin('border')
        
        var token=sessionStorage.getItem('mytoken', false)
                const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        axios.get(url + 'wellness/educations', config)
            .then(res => {
                // console.log(res.data.result)
                setLevels(res.data.result)
            
            }).catch(e => {
                console.log(e)
            
            }).then(res => {
                setSpin(false)
            
        })
    }


    const fetchBio = () => {
        var token=sessionStorage.getItem('mytoken', false)
        const config = {
            headers: { Authorization: `Bearer ${token}` }
            
        };
        
        setSpin('border')
        axios.get(url + 'wellness/user/profile', config)
            .then(res => {
                console.log(res.data.result.first_name)
                var dt=res.data.result
                // 
                dispatchQuestion({type:'first_name', value:dt.first_name})
                dispatchQuestion({type:'last_name', value:dt.last_name})
                dispatchQuestion({type:'country', value:dt.country_id})
                dispatchQuestion({type:'city', value:dt.city_id})
                dispatchQuestion({type:'birth_year', value:dt.year_of_birth})
                dispatchQuestion({type:'gender', value:dt.gender})
                dispatchQuestion({type:'race', value:dt.race_id})
                dispatchQuestion({type:'occupation', value:dt.occupation_id})
                dispatchQuestion({type:'occupation', value:dt.occupation_id})
                dispatchQuestion({type:'education_level', value:dt.education_level_id})
                dispatchQuestion({type:'blood_type', value:dt.blood_type})
                dispatchQuestion({ type: 'rhesus_factor', value: dt.rhesus_factor })
                dispatAllery({ type: 'all_state', value: dt.allergy })
                dispatRecentAcute({ type: 'all_state', value: dt.recent_acute_diseases })
                dispatRecentAcute({ type: 'all_state', value: dt.recent_acute_diseases })
                dispatRecentHistory({ type: 'all_state', value: dt.surgery_history })

                console.log("Start Allergy")
                console.log(dt.allergy)
                console.log("End Allergy")
                
                // format 
                var text = dt.weight
                
                const myArray = text.split(" ");

                
                

                dispatchQuestion({ type: 'weight', value: myArray[0] })
                dispatchQuestion({ type: 'weight_unit_measure', value: myArray[1] })

                // Format height

                var height = dt.height
                const heights = height.split(" ");

                console.log(dt)
                console.log(heights)


                dispatchQuestion({ type: 'height_feet', value: heights[0] })
                dispatchQuestion({ type: 'height_inches', value: heights[2] })
            
            }).catch(e => {
                console.log(e.message)
                if (e.message=='Request failed with status code 404') {
                    setRedirectProfile(true)
                }
            
            }).then(r => {
                setSpin('false')
            
        })
    }

    const getCountries = () => {
        var token=sessionStorage.getItem('mytoken', false)
                const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        axios.get(url + 'wellness/countries', config)
            .then(res => {
                // console.log(res.data.result)
                setCountries(res.data.result)
            
            }).catch(e => {
                console.log(e)
            
            }).then(res => {
                setSpin('false')
            
        })
    }
    const getCities = () => {
        var token=sessionStorage.getItem('mytoken', false)
                const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        axios.get(url + 'wellness/cities', config)
            .then(res => {
                // console.log(res.data.result)
                setCities(res.data.result)
            
            }).catch(e => {
                console.log(e)
            
            }).then(res => {
            
        })
    }

    const rendercountries = countries.map((country, index) => (
        <option value={country.id} key={index}>{ country.name}</option>
    ))
    const rendercities = cities.map((city, index) => (
        <option value={city.id} key={index}>{ city.capital}</option>
    ))

    const renderraces = races.map((race, index) => (
        <option value={race.id} key={index}>{ race.name}</option>
    ))
    const renderoccupations = occupations.map((race, index) => (
        <option value={race.id} key={index}>{ race.name}</option>
    ))
    const renderlevels = levels.map((race, index) => (
        <option value={race.id} key={index}>{ race.name}</option>
    ))
        const renderyears = () => {
        for (let i = year; i > 1950; i--) {
            setYears(years=>[...years,i])
//             return (
//       <option>Hello</option>
//   )
}
    }
    // console.log(years)
    const yearsrender = years.map((year, index) => (
        <option value={year} key={index}>{ year}</option>
    ))

    var errors=questionstate.save_error
    const saveerrors = errors.map((error,index) => (
        <li key={index}>{error }</li>
    ))
    return (<div className="container">
        {redirect ? (<>
            <Redirect to="/demographics" />
            
            

        </>) : (<></>)}

        {redirectprofile ? (<>
            <Redirect to="/profile-data" />

            
            
            

        </>) : (<></>)}
        
        
        
        
        {/* <div className="col-sm-3" /> */}
        
        
            
            <div className="col-sm-12">
                <div className="row">
            <div className=" darkbluebackground alert text-white col-sm-12">
                            <div className="row">
                                
                            <div className="col-12 text-center">
                              <h4>  Profile data</h4>

                            </div>
                            </div>
                           
                           
                        </div>
        </div>
              
                        
              
                        
            <br />
            <div className="row">
            <div className="col-sm-12">
                {/* <div className="row"> */}
                    {questionstate.save_spin ? <>
                        <center><Spinner animation="border" variant="primary" /> Wait saving your data</center>
                    
                    </> : <> </>}
                    {/* <center><Spinner animation={questionstate.save_spin} variant="primary" /></center> */}
                    <div className="alert">
                        {saveerrors}
                    </div>
                        {/* </div> */}
            </div>
        </div>
                
                    <div className="row">
                <div className="col-sm-4">
                    {/* </div> */}
                    <Spinner animation={spin} variant="primary" />
                        
                        

                        <div className="row">
                            <div className="col-sm-6">
                        
                        <button onClick={()=>document.getElementById('profile-image').click()} className="btn btn-sm btn-primary"> Add Photo</button>
                            </div>
                            <div className="col-sm-4">
                        <img className="img-thumbnail" src={imgData} />
                        
                        
                        
                        <input id="profilePic" type="file" onChange={onChangePicture} id="profile-image" accept="image/*" style={{ visibility: "hidden" }} />
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label>
                                        First Name
                                    </label>
                            <input type="text" className="form-control" value={questionstate.first_name}
                                onChange={(e) => dispatchQuestion({ type: 'first_name', value: e.target.value })} placeholder="Enter your first name" />
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label>
                                        Last Name
                                    </label>
                                    <input type="text" className="form-control"  value={questionstate.last_name} onChange={(e) => dispatchQuestion({ type: 'last_name', value: e.target.value })} placeholder="Enter your last name" />
                                </div>
                            </div>

                        </div>
                        {/* <hr /> */}
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>
                                        Country
                                    </label>
                                     <select className="form-control" value={questionstate.country} onChange={(e) => dispatchQuestion({ type: 'country', value: e.target.value })}>
                                    <option>Select...</option>
                                    {rendercountries}
                                </select>
                                </div>
                               
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>
                                        City
                                    </label>
                                     <select className="form-control" value={questionstate.city} onChange={(e) => dispatchQuestion({ type: 'city', value: e.target.value })}>
                                    <option>Select...</option>
                                    {rendercities}
                                </select>
                                </div>
                               
                            </div>
                        </div>
                        {/* <hr /> */}


                <br />
                
                <br />
               
              
                </div>

                <div className="col-sm-4">
                    {/* Demographics */}
                    <div class="form-group row">
                        <label for="inputPassword" class="col-sm-4 col-form-label">Birth Year</label>
                        <div class="col-sm-4">
                            <select className="form-control" value={questionstate.birth_year} onChange={(e) => dispatchQuestion({ type: 'birth_year', value: e.target.value })}>
                            <option value="">Select...</option>
                            {yearsrender}
                                                        
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
    <label for="inputPassword" class="col-sm-4 col-form-label">Gender</label>
    <div class="col-sm-4">
        <select className="form-control" value={questionstate.gender} onChange={(e) => dispatchQuestion({ type: 'gender', value: e.target.value })}>
        <option value="">Select...</option>
        <option value="M">Male</option>
        <option value="F">Female</option>
        
                                    
         </select>
    </div>
                    </div>
                    <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label>
                                        Race
                                    </label>
                                    <select className="form-control" value={questionstate.race} onChange={(e) => dispatchQuestion({ type: 'race', value: e.target.value })}>
                                        <option value="">Select...</option>
                                        {renderraces}

                                    </select>
                                </div>
                            </div>

                    </div>
                    <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label>
                                        Occupation
                                    </label>
                                     <select className="form-control" value={questionstate.occupation} onChange={(e) => dispatchQuestion({ type: 'occupation', value: e.target.value })}>
                                    <option value="">Select...</option>
                                    {renderoccupations}
                                </select>
                                </div>
                               
                            </div>
                            
                    </div>
                    <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label>
                                        Level of education
                                    </label>
                                     <select className="form-control" value={questionstate.education_level} onChange={(e) => dispatchQuestion({ type: 'education_level', value: e.target.value })}>
                                    <option value="">Select...</option>
                                        {renderlevels}
                                    
                                </select>
                                </div>
                               
                            </div>
                            
                        </div>
                </div>
                <div className="col-sm-4">
                    {/* Question 2 */}

                    <div className="row">

                    
                    <div className="col-sm-4">
                                <div className="form-group">
                                    <label>
                                        Blood Type
                                    </label>
                            <select className="form-control" value={questionstate.blood_type}
                                onChange={(e) => dispatchQuestion({ type: 'blood_type', value: e.target.value })}>
                                        <option value="">Select Blood Group</option>
                                        <option value="A">A</option>
                                        <option value="B">B</option>
                                        <option value="AB">AB</option>
                                        <option value="O">O</option>
                                    </select>
                                </div>
                    </div>
                    <div className="col-sm-5">
                                <div className="form-group">
                                    <label>
                                        &nbsp;
                                    </label>
                                    <select className="form-control" value={questionstate.rhesus_factor}
                                onChange={(e) => dispatchQuestion({ type: 'rhesus_factor', value: e.target.value })}>
                                        <option value="">Select...</option>
                                        <option value="positive">Positive</option>
                                        <option value="negative">Negative</option>
                                    </select>
                                </div>
                        </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>
                                        Weight
                                    </label>
                                    <input type="text" className="form-control" placeholder="Enter Your Weight" value={questionstate.weight}
                                onChange={(e) => dispatchQuestion({ type: 'weight', value: e.target.value })} />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    
                                    <div style={{paddingTop:'40px'}} value={questionstate.weight_unit_measure}
                                onChange={(e) => dispatchQuestion({ type: 'weight_unit_measure', value: e.target.value })} >
                                        <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="Kgs" checked />
                                    <label class="form-check-label" for="inlineRadio1">KG</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="pounds" />
                                    <label class="form-check-label" for="inlineRadio2">Pound</label>
                                    </div>
                                    </div>

                                </div>
                            </div>

                    </div>
                    <div className="row">
                            <div className="col-sm-3">
                                <div className="form-group">
                                    <label>
                                        Height
                                    </label>
                                    <input type="text" className="form-control"  value={questionstate.height_cm}
                                onChange={(e) => dispatchQuestion({ type: 'height_cm', value: e.target.value })} />
                                     
                                </div>
                               
                            </div>
                            <div className="col-sm-4">
                                <div style={{paddingTop:'37px'}}>
                                    <small>cm</small>
                                </div>
                               
                            </div>
                            
                    </div>
                    <div className="row">
                            <div className="col-sm-12">
                                or
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="form-group">
                                    
                                    <input type="text" className="form-control" value={questionstate.height_feet}
                                onChange={(e) => dispatchQuestion({ type: 'height_feet', value: e.target.value })} />
                                     
                                </div>
                               
                            </div>
                            <div className="col-sm-2">
                                <div style={{paddingTop:'1px'}}>
                                    <small>Feet</small>
                                </div>
                               
                            </div>
                            <div className="col-sm-4">
                                <div className="form-group">
                                    
                                    <input type="text" className="form-control" value={questionstate.height_inches}
                                onChange={(e) => dispatchQuestion({ type: 'height_inches', value: e.target.value })} />
                                     
                                </div>
                               
                            </div>
                            <div className="col-sm-2">
                                <div style={{paddingTop:'1px'}}>
                                    <small>Inches</small>
                                </div>
                               
                            </div>
                            
                        </div>
                </div>
                
            
</div>
            
        </div>

        <div className="row">
            <div className="col-sm-6">
                <center ><button onClick={()=>updateProfile()} className="btn btn-primary curveresults">Update</button></center>
            </div>
            <div className="col-sm-6">

                
                <center ><Button variant="success" as={Link} size="sm" to="/question3edit" style={{ color: 'white', fontWeight: '600' }} > Questions</Button></center>
            </div>
        </div>
        


    </div>);
}
 
export default MyProfiledata;