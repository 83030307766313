import React,{useState,useContext} from 'react';
import { Card, Button, ToggleButton, ButtonGroup,ToggleButtonGroup } from 'react-bootstrap'
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import ToggleButtonExample from './Testbuttons';
import { QuestionContext } from '../../App';
import { Trans, useTranslation } from 'react-i18next';

const RecentAcuteEdit = () => {
    const {questionstate,dispatchQuestion,recentacutestate, dispatRecentAcute} = useContext(QuestionContext);
    const [redirect, setRedirect] = useState(false)
    const [highbloodpressure, setHighBloodPressure] = useState(false);
    const [heartattack, setHeartAttack] = useState(false);
    const [highcholestorol, setHighChelosterol] = useState(false);
    const [diabeties, setDiabeties] = useState(false);
    const [cancer, setCancer] = useState(false);
    const [heartfailure, setHeartFailure] = useState(false);
    const [arrhythmia, setArrhythmia] = useState(false);
    const [stroke, setStroke] = useState(false);
    const [clothingdisorder, setClothingDisorder] = useState(false);
    const { t, i18n } = useTranslation()
    
    
    const latexfn = (e) => {
        // setLatex(e.currentTarget.checked)
    }
    return (<div className="container">
        {redirect ? (<>
            <Redirect to="/question2" />
            
            

        </>) : (<></>)}

        
        {/* <ToggleButtonExample /> */}
        
        
        <div className="row">
            <div className="col-sm-2" />
            <div className="col-sm-7">
                {/* <Card style={{ border: 'rounded-pill', borderRadius: "30px" }} >
                    <Card.Body> */}
                        
                <div className="darkbluebackground row alert text-white ">
                    <table>
                        <tr>
                            <td>
                                <div className="col-sm-2">
                        <Button variant="link" as={Link} size="lg" to="/question3edit" style={{ color: 'white', fontWeight: '600' }} ><i class="fa fa-arrow-left" aria-hidden="true"></i></Button>
                    </div>
                                
                            </td>
                            <td>
                                <div className="col-sm-6" >
                            <div className="row">
                                
                            <div className="col-12">
                            <h4>{t('wellness.optional.optional')}</h4>

                            </div>
                            </div>
                           
                           
                        </div>

                            </td>
                        </tr>
                    </table>
                    
            
        </div>
                        
                    

                    
                        <h4 className="text-center">{t('wellness.optional.recent_acute')}</h4>
                        <div className="text-center">{t('wellness.optional.recent_acute_sub')}</div>
                        <hr />

                        
                        <table>
                            <tr>
                                <td>
                                    <ToggleButton
                                    className="mb-2"
                                    id="iodine"
                                    type="checkbox"
                                    variant="outline-primary"
                                    checked={recentacutestate.highbloodpressure}
                                    value="1"
                                    onChange={(e) => dispatRecentAcute({type:"highbloodpressure"})}
                                >
                                       &nbsp;{t('wellness.recent_accute.high_blood_pressure')}
                                    </ToggleButton>

                                </td>
                                <td>
                                    <ToggleButton
                                    className="mb-2"
                                    id="latex"
                                    type="checkbox"
                                    variant="outline-primary"
                                    checked={recentacutestate.heartattack}
                                    value="1"
                                    onChange={(e) => dispatRecentAcute({type:"heartattack"})}
                                >
                                    &nbsp;{t('wellness.recent_accute.heart_attack')}
                                </ToggleButton>
                        </td>
                        <td>
                                    <ToggleButton
                                    className="mb-2"
                                    id="gluten"
                                    type="checkbox"
                                    variant="outline-primary"
                                    checked={recentacutestate.highcholestorol}
                                    value="1"
                                    onChange={(e) => dispatRecentAcute({type:"highcholestorol"})}
                                >
                                       &nbsp;{t('wellness.recent_accute.high_cholesterol')}
                                </ToggleButton>

                                </td>
                                
                            </tr>
                            <tr>
                                
                                <td>
                                    <ToggleButton
                                    className="mb-2"
                                    id="gluten"
                                    type="checkbox"
                                    variant="outline-primary"
                                    checked={recentacutestate.diabeties}
                                    value="1"
                                    onChange={(e) => dispatRecentAcute({type:"diabeties"})}
                                >
                                       &nbsp;{t('wellness.recent_accute.diabeties')}
                                </ToggleButton>

                        </td>
                        <td>
                                    
                                <ToggleButton
                                    className="mb-2"
                                    id="gluten"
                                    type="checkbox"
                                    variant="outline-primary"
                                    checked={recentacutestate.cancer}
                                    value="1"
                                    onChange={(e) => dispatRecentAcute({type:"cancer"})}
                                >
                                       &nbsp;{t('wellness.recent_accute.cancer')}
                                </ToggleButton>
                            
                                    
                        </td>
                        <td>
                                    
                                <ToggleButton
                                    className="mb-2"
                                    id="gluten"
                                    type="checkbox"
                                    variant="outline-primary"
                                    checked={recentacutestate.heartfailure}
                                    value="1"
                                    onChange={(e) => dispatRecentAcute({type:"heartfailure"})}
                                >
                                       &nbsp;{t('wellness.recent_accute.heart_failure')}
                                </ToggleButton>
                            

                                </td>
                            </tr>
                            <tr>
                                
                                
                            </tr>

                            <tr>
                                <td>
                                    <ToggleButton
                                    className="mb-2"
                                    id="gluten"
                                    type="checkbox"
                                    variant="outline-primary"
                                    checked={recentacutestate.arrhythmia}
                                    value="1"
                                    onChange={(e) => dispatRecentAcute({type:"arrhythmia"})}
                                >
                                       &nbsp;  {t('wellness.recent_accute.arrhythmia')}
                                </ToggleButton>
                                    
                                </td>
                                <td>
                                    <ToggleButton
                                    className="mb-2"
                                    id="gluten"
                                    type="checkbox"
                                    variant="outline-primary"
                                    checked={recentacutestate.stroke}
                                    value="1"
                                    onChange={(e) => dispatRecentAcute({type:"stroke"})}
                                >
                                       &nbsp; {t('wellness.recent_accute.stroke')}
                                </ToggleButton>

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <ToggleButton
                                    className="mb-2"
                                    id="gluten"
                                    type="checkbox"
                                    variant="outline-primary"
                                    checked={recentacutestate.clothingdisorder}
                                    value="1"
                                    onChange={(e) => dispatRecentAcute({type:"clothingdisorder"})}
                                >
                                      &nbsp; {t('wellness.recent_accute.clothing_disorder')}
                                </ToggleButton>
                                    
                                </td>
                                
                            </tr>
                        </table>
                        
                <div className="row">
                    
                
                            
                            
                            
                            
                            <div className="col-sm-5">
                                
                            </div>

                        </div>

                        <br />
                        

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label>{t('wellness.optional.cant_find')}</label>
                            <textarea rows="4" className="form-control" onChange={(e) => dispatchQuestion({ type: "recentacute_other", value: e.target.value })} >{questionstate.recentacute_other }</textarea>
                                </div>
                            </div>
                        </div>
                        
                        
                        
                        
                        
                        <div className="row" style={{paddingTop:'20px'}}>
                            <div className="col-sm-4">
                                
                                
                               
                            </div>
                            <div className="col-sm-6">
                                
                                <div>
                                    
                                    <Button variant="link" as={Link} size="lg" to="/question3edit" style={{ color: '#1d7ced', fontWeight: '600' }} ><i class="fa fa-arrow-left" aria-hidden="true"></i></Button><br />
                                    {t('wellness.optional.back')}
                                    
                                </div>
                               
                            </div>
                            
                        </div>
                        


                <br />
                
                
                {/* <button class="btn btn-primary btn-block" type="button" onClick={()=>setRedirect(true)} width="100%">Save</button> */}
                {/* </Card.Body>
                </Card> */}
            </div>
            

            
        </div>
        


    </div>);
}
 
export default RecentAcuteEdit;