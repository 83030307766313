import React,{useContext, useState} from 'react';
import { Card, Button } from 'react-bootstrap'
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { QuestionContext } from '../../App';
import { Spinner } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
const QuickQuestion3Edit = () => {
    const [redirect, setRedirect] = useState(false)
    const { saveProfile,questionstate ,updateProfile} = useContext(QuestionContext)
    const { t, i18n } = useTranslation()
    
    // console.log('saveProfile')
    // console.log(saveProfile)
    // console.log(saveProfile)

    var errors=questionstate.save_error
    const saveerrors = errors.map((error,index) => (
        <li key={index}>{error }</li>
    ))
    return (<div className="container">
        {questionstate.save_success ? (<>
            {/* <Redirect to="/home" /> */}
            
            

        </>) : (<></>)}
        
       
        
        <div className="row">
            <div className="col-sm-3" />
            <div className="col-sm-6">
                {/* <Card style={{ border: 'rounded-pill', borderRadius: "30px" }} >
                    <Card.Body>
                        
                        <br /> */}

                    
                <div className=" darkbluebackground row alert text-white ">

                    <table>
                        <tr>
                            <td>
                                <div className="col-sm-2">
                        <Button variant="link" as={Link} size="lg" to="/my-profile-data" style={{ color: 'white', fontWeight: '600' }} ><i class="fa fa-arrow-left" aria-hidden="true"></i></Button>
                    </div>
                                
                            </td>
                            <td>
                                <div className="col-sm-6" >
                            <div className="row">
                                
                            <div className="col-12">
                              

                               <h4> {t('wellness.optional.optional')}</h4>

                            </div>
                            </div>
                           
                           
                        </div>

                            </td>
                        </tr>
                    </table>
                    
            
        </div>

                <div className="row">
                    {questionstate.save_spin ? <>
                        <center><Spinner animation="border" variant="primary" />  Wait saving your data</center>
                    
                    </> : <> </>}
                    {/* <center><Spinner animation={questionstate.save_spin} variant="primary" /></center> */}
                    <div className="alert">
                        {saveerrors}
                    </div>
                        </div>
                        

                            <table className="table table-bordered">
                                <tr>
                                    <td>
                                        
                                             {t('wellness.optional.allergy')}<br />
                                            <small>{t('wellness.optional.allergy_sub')}</small>
                                
                                        
                                        
                                    </td>
                                    <td>
                                       
                                            
                                            <Button variant="link" as={Link} size="lg" to="/allergyedit" style={{ color: '#1d7ced', fontWeight: '600' }} > <i class="fa fa-caret-right" aria-hidden="true"></i></Button>
                                
                                        

                                    </td>
                    </tr>
                    
                    <tr>
                                    <td>
                                        
                                             {t('wellness.optional.recent_acute')}<br />
                                            <small> {t('wellness.optional.recent_acute_sub')}</small>
                                
                                        
                                        
                                    </td>
                                    <td>
                                        
                                            
                                            <Button variant="link" as={Link} size="lg" to="/recent-acute-edit" style={{ color: '#1d7ced', fontWeight: '600' }} > <i class="fa fa-caret-right" aria-hidden="true"></i></Button>
                                
                                        

                                    </td>
                    </tr>
                    <tr>
                                    <td>
                                        
                                             {t('wellness.optional.habit')} <br />
                                            <small>{t('wellness.optional.habit_sub')}</small>
                                
                                        
                                        
                                    </td>
                                    <td>
                                        
                                            
                                            <Button variant="link" as={Link} size="lg" to="/habitsedit" style={{ color: '#1d7ced', fontWeight: '600' }} > <i class="fa fa-caret-right" aria-hidden="true"></i></Button>
                                
                                        

                                    </td>
                    </tr>
                    <tr>
                                    <td>
                                        
                                             {t('wellness.optional.surgery_history')} <br />
                                            
                                
                                        
                                        
                                    </td>
                                    <td>
                                        
                                            
                                            <Button variant="link" as={Link} size="lg" to="/surgery-history-edit" style={{ color: '#1d7ced', fontWeight: '600' }} > <i class="fa fa-caret-right" aria-hidden="true"></i></Button>
                                
                                       

                                    </td>
                                </tr>

                            </table>
                            
                            

                        
                        
                   
                        
                        
                        
                        
                        <div className="row" style={{paddingTop:'20px'}}>
                            <div className="col-sm-6">
                                {/* <div>
                                    
                                    <Button variant="link" as={Link} to="/question2" style={{ color: '#1d7ced', fontWeight: '600' }} > <i class="icons-edit1"></i><i className="fas1 fa-edit2"></i>Previous</Button>
                                </div> */}
                                
                               
                            </div>
                            <div className="col-sm-12">
                                
                                <div className="float-right">
                                    
                                    <Button variant="primary" style={{ color: 'white', fontWeight: '600' }} onClick={()=>updateProfile()} > <i class="icons-edit1"></i><i className="fas1 fa-edit2"></i>Update Profile</Button>
                                    
                                </div>
                               
                            </div>
                            
                        </div>
                        


                <br />
                
                
                {/* <button class="btn btn-primary btn-block" type="button" onClick={()=>setRedirect(true)} width="100%">Save</button> */}
                {/* </Card.Body>
                </Card> */}
            </div>
            

            
        </div>
        


    </div>);
}
 
export default QuickQuestion3Edit;