import React,{useContext, useState} from 'react';
import { Card, Button } from 'react-bootstrap'
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { QuestionContext } from '../../App';
import { Trans, useTranslation } from 'react-i18next';

const QuickQuestion2 = () => {
    const { t, i18n } = useTranslation()
    const [redirect, setRedirect] = useState(false)
    const { questionstate, dispatchQuestion } = useContext(QuestionContext)
    const [checkbox,setChaeckbox]=useState('')
    

    return (<div className="container">
        {redirect ? (<>
            <Redirect to="/question2" />
            
            

        </>) : (<></>)}
        
        {checkbox}
        
        <div className="row">
            <div className="col-sm-2" />
            <div className="col-sm-7">
                {/* <Card style={{ border: 'rounded-pill', borderRadius: "30px" }} >
                    <Card.Body> */}
                        
                        <br />

                    
                       
                <div className="row alert text-white " style={{ background: '#a6cfeb' }}>

                    <table>
                        <tr>
                            <td>
                                <div className="col-sm-2">
                        <Button variant="link" as={Link} size="lg" to="/demographics" style={{ color: '#1d7ced', fontWeight: '600' }} ><i class="fa fa-arrow-left" aria-hidden="true"></i></Button>
                    </div>
                                
                            </td>
                            <td>
                                <div className=" col-sm-6" >
                            <div className="row">
                                
                            <div className="col-12">
                              <h4> {t('wellness.form.quick_questions')} </h4>

                            </div>
                            </div>
                           
                           
                        </div>

                            </td>
                        </tr>
                    </table>
                    
            
        </div>

                        
                        <div className="row">
                            <div className="col-sm-8">
                                <div className="form-group">
                                    <label>
                                        {t('wellness.form.blood_type')}
                                    </label>
                            <select className="form-control" value={questionstate.blood_type}
                                onChange={(e) => dispatchQuestion({ type: 'blood_type', value: e.target.value })}>
                                        <option value="">{t('wellness.optional.select')}...</option>
                                        <option value="A">A</option>
                                        <option value="B">B</option>
                                        <option value="AB">AB</option>
                                        <option value="O">O</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="form-group">
                                    <label>
                                        &nbsp;
                                    </label>
                                    <select className="form-control" value={questionstate.rhesus_factor}
                                onChange={(e) => dispatchQuestion({ type: 'rhesus_factor', value: e.target.value })}>
                                        <option value="">{t('wellness.optional.select')}...</option>
                                        <option value="positive">{t('wellness.form.Rh(+)')}</option>
                                        <option value="negative">{t('wellness.form.Rh(-)')}</option>
                                    </select>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>
                                        Weight
                                    </label>
                                    <input type="text" className="form-control" placeholder="Enter Your Weight" value={questionstate.weight}
                                onChange={(e) => dispatchQuestion({ type: 'weight', value: e.target.value })} />
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    
                                    <div style={{paddingTop:'40px'}} value={questionstate.weight_unit_measure}
                                onChange={(e) => dispatchQuestion({ type: 'weight_unit_measure', value: e.target.value })} >
                                        <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="kgs" />
                                    <label class="form-check-label" for="inlineRadio1">KG</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="pounds" />
                                    <label class="form-check-label" for="inlineRadio2">Pound</label>
                                    </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        
                        <div className="row">
                            <div className="col-sm-3">
                                <div className="form-group">
                                    <label>
                                        Height
                                    </label>
                                    <input type="text" className="form-control"  value={questionstate.height_cm}
                                onChange={(e) => dispatchQuestion({ type: 'height_cm', value: e.target.value })} />
                                     
                                </div>
                               
                            </div>
                            <div className="col-sm-4">
                                <div style={{paddingTop:'37px'}}>
                                    <small>cm</small>
                                </div>
                               
                            </div>
                            
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                or
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="form-group">
                                    
                                    <input type="text" className="form-control" value={questionstate.height_feet}
                                onChange={(e) => dispatchQuestion({ type: 'height_feet', value: e.target.value })} />
                                     
                                </div>
                               
                            </div>
                            <div className="col-sm-2">
                                <div style={{paddingTop:'1px'}}>
                                    <small>Feet</small>
                                </div>
                               
                            </div>
                            <div className="col-sm-4">
                                <div className="form-group">
                                    
                                    <input type="text" className="form-control" value={questionstate.height_inches}
                                onChange={(e) => dispatchQuestion({ type: 'height_inches', value: e.target.value })} />
                                     
                                </div>
                               
                            </div>
                            <div className="col-sm-2">
                                <div style={{paddingTop:'1px'}}>
                                    <small>Inches</small>
                                </div>
                               
                            </div>
                            
                        </div>
                        <div className="row" style={{paddingTop:'20px'}}>
                            <div className="col-sm-6">
                                {/* <div>
                                    
                                    <Button variant="link" as={Link} to="/demographics" style={{ color: '#1d7ced', fontWeight: '600' }} > <i class="icons-edit1"></i><i className="fas1 fa-edit2"></i>Previous</Button>
                                </div> */}
                                
                               
                            </div>
                            <div className="col-sm-6">
                                
                                <div className="float-right">
                                    
                                    <Button variant="link" as={Link} to="/question3" style={{ color: '#1d7ced', fontWeight: '600' }} > <i class="icons-edit1"></i><i className="fas1 fa-edit2"></i><img src='../assets/wellness/Next.png' class="responsive" style={{width:'40px'}} />< br />Next</Button>
                                    
                                </div>
                               
                            </div>
                            
                        </div>
                        


                <br />
                
                
                {/* <button class="btn btn-primary btn-block" type="button" onClick={()=>setRedirect(true)} width="100%">Save</button> */}
                {/* </Card.Body>
                </Card> */}
            </div>
            

            
        </div>
        


    </div>);
}
 
export default QuickQuestion2;