import _ from 'lodash'
import React, { Component } from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Segment,
  Visibility,
} from 'semantic-ui-react'
import SignIn from './auth/SignIn';
import SignUp from './auth/SignUp';
import SignOut from './auth/SignOut';
import Dashboard from './Dashboard';
import PrivateRoute from '../components/auth/PrivateRoute'
import RegistrationSuccess from './RegistrationSuccess'
import Faq from './Faq';
import Profile from './Profile';
import SendLink from '../components/auth/SendLink'
import DownloadApk from '../components/DownloadApk'
import {
  HashRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import ChangePassword from './auth/ChangePassword';
import CompleteReset from './auth/CompleteReset';
import ResendVerication from './auth/ResendVerification';
import Verify from './auth/Verify';
import AllDiagnosis from './AllDiagnosis';
import RecordVideo from './RecordVideo';
import Translate from './tests/Translate';
import NavContext from './Nav/NavContext';
import Navbar2 from './Nav/Nav2';
import Navbar from './Nav/Navbar';
import '../assets/global.css'
import ValidateDiagnosis from './ValidateDiagnosis';
import ValidateDiagnosisNoParams from './ValidateDiagnosis-no-params';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Terms from './wellness/Terms';
import ProfileData from './wellness/Profiledata';
import Demographics from './wellness/Demographics';
import QuickQuestion1 from './wellness/quickquestion1';
import QuickQuestion2 from './wellness/quickquestion2';
import QuickQuestion3 from './wellness/question3';
import Allergy from './wellness/Allergy';
import RecentAcute from './wellness/RecentAcute';
import Habits from './wellness/Habits';
import SurgeryHistory from './wellness/SurgeryHistory';
import Home from './wellness/Home';
import HeartRate from './wellness/HeartRate';
import Resources from './wellness/Resources';
import Notification from './wellness/Notification';
import Contact from './wellness/Contact';
import MyProfiledata from './wellness/MyProfiledata';
import NotificationDetails from './wellness/NotificationDetails';
import Landing from './wellness/Landing';
import QuickQuestion3Edit from './wellness/question3edit';
import AllergyEdit from './wellness/AllergyEdit';
import RecentAcuteEdit from './wellness/RecentAcuteEdit';
import HabitsEdit from './wellness/HabitsEdit';
import SurgeryHistoryEdit from './wellness/SurgeryHistoryEdit';
import RealTime from './wellness/RealTime';

const menuStyle = {
  border: 'none',
  borderRadius: 0,
  boxShadow: 'none',
  marginBottom: '1em',
  marginTop: '4em',
  transition: 'box-shadow 0.5s ease, padding 0.5s ease',
}

const fixedMenuStyle = {
  backgroundColor: '#fff',
  border: '1px solid #ddd',
  boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
}

const overlayStyle = {
  float: 'left',
  margin: '0em 3em 1em 0em',
}

const fixedOverlayStyle = {
  ...overlayStyle,
  position: 'fixed',
  top: '80px',
  zIndex: 10,
}

const overlayMenuStyle = {
  position: 'relative',
  left: 0,
  transition: 'left 0.5s ease',
}

const fixedOverlayMenuStyle = {
  ...overlayMenuStyle,
  left: '800px',
}

const LeftImage = () => (
  <Image
    floated='left'
    size='medium'
    src='/images/wireframe/square-image.png'
    style={{ margin: '2em 2em 2em -4em' }}
  />
)





export default class StickyLayout extends Component {
  state = {
    menuFixed: false,
    overlayFixed: false,
  }

  handleOverlayRef = (c) => {
    const { overlayRect } = this.state

    if (!overlayRect) {
      this.setState({ overlayRect: _.pick(c.getBoundingClientRect(), 'height', 'width') })
    }
  }

  stickOverlay = () => this.setState({ overlayFixed: true })

  stickTopMenu = () => this.setState({ menuFixed: true })

  unStickOverlay = () => this.setState({ overlayFixed: false })

  unStickTopMenu = () => this.setState({ menuFixed: false })

  render() {
    const { menuFixed, overlayFixed, overlayRect } = this.state

    return (
      <Router>
        <div>
          {/* Heads up, style below isn't necessary for correct work of example, simply our docs defines other
            background color.
          */}
          <style>
            {`
          html1, body1 {
            
  font-family: "Roboto", sans-serif;
  background-size: cover;
  min-height: 101%;
          }
          .form-controlq {
  outline: none;
  border: 1px solid #28696d;
}
body {
  color:#7d7d7d;
  background-color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
body::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}
html {
  overflow-y: scroll;
}

body1 {
 
  font-family: "Roboto", sans-serif;
  background-size: cover;
  min-height: 101%;
}
.form-control1::placeholder1 {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ffffff !important;
  opacity: 1; /* Firefox */
}
.card{
  border-radius: 20px 20px;

}

.form-control1:-ms-input-placeholder1 {
  /* Internet Explorer 10-11 */
  color: #ffffff !important;
}

.form-control1::-ms-input-placeholder1 {
  /* Microsoft Edge */
  color: #ffffff !important;
}
.form-control1 {
  outline: none;
  border: 1px solid #28696d;
  background: white;
}
.form-control1:focus1 {
  border-color: #28696d;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px #28696d;
}
.text-boston {
  color: #28696d;
}
.text-white {
  color: #ffffff ;
}
.text16 {
  color: black
}
.background-blue {
  background: #1d7ced ;
}
.responsive {
  width: 100%;
  height: auto;
}
.card-results {
  background-image: url(../assets/web2images/Blueboxes.png); /* The image used */
  paddind-top: 20px;
  height: 100%; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}
.curveresults {
  // border-collapse: separate;
  // border-spacing: 15px 50px;
  // padding: 5px;
  border-radius: 60px 60px;
  border-bottom-left-radius: 60px 60px;
  // border-color:#cae3f4
  // background-color:#cae3f4
  border-style: solid;
  border-style: solid;
  border-width: 1px
}
.button {
  background-color: #ddd;
  border: none;
  color: black;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
 
  cursor: pointer;
  border-radius: 16px;
}
.darkbluebackground{
  background: #0595d4;
  color: white
}
.roundedtd {
  // border: 2px solid red;
  // padding: 10px;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  background-color:#0595d4;
  color: white
  
}
.allroundedtd {
  // border: 2px solid red;
  padding: 1px;
  border-radius: 25px;
  // border-bottom-right-radius: 25px;
  background-color:#cae3f4
}
table, th, td {
  // border: 1px solid black;
  padding-left:0px
}
.resultsbluebackground{
  background-color:#cae3f4


}
.resultswhitebackground{
  background-color:white


}
.logininstruction{
  border-radius: 30px 30px;
  background-color:#cae3f4;
  padding: 10px;
  font-size:15px

}

.go-results-background{
background-color: #79bd36
}
.warning-results-background{
background-color: orange
}
.red-results-background{
background-color: red
}

.go-results{
color: #79bd36
}
.warning-results{
color: orange
}
.red-results{
color: red
}
/* Hide scrollbar for Chrome, Safari and Opera */
body2::-webkit-scrollbar {
  display: none;
}

 .line{
width: 112px;
height: 5px;
// border-bottom: 1px solid black;
// position: absolute;
}

/* Hide scrollbar for IE, Edge and Firefox */
body2 {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #9c9c9c
  
}
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.Blink {
    animation: blinker .5s cubic-bezier(.5, 0, 1, 1) infinite alternate;  
}

@keyframes blinker {  
  from { opacity: 1; }
  to { opacity: 0; }



}

.alerts-border {
    border: 3px #ff0000 solid;
    
    animation: blink 1s;
    animation-iteration-count: 200;
}

.border{
  border: 3px #ff0000 solid;

}
.border2{
  border-right: 8px solid white

}

@keyframes blink { 50% { border-color:#fff ; }  }
#menu {
  list-style: none;
}

#menu .pipe {
  display: inline;
  padding: 0 10px;
  border-left: solid 1px black;
}

#menu pipe:first-child {
  border-left: none;
}
        `},


            

          </style>

          {/* <Container text style={{ marginTop: '2em' }}>
          <Header as='h1'>Sticky Example</Header>
          <p>
            This example shows how to use lazy loaded images, a sticky menu, and a simple text
            container
          </p>
        </Container> */}

          {/* Attaching the top menu is a simple operation, we only switch `fixed` prop and add another style if it has
            gone beyond the scope of visibility
          */}
          <Visibility
            onBottomPassed={this.stickTopMenu}
            onBottomVisible={this.unStickTopMenu}
            once={false}
          >
            {/* <Navbar /> */}
            <Navbar2 />
            {/* < hr /> */}

          </Visibility>

          {/* <Container text> */}
          <div className="container">


            {/* Example with overlay menu is more complex, SUI simply clones all elements inside, but we should use a
              different approach.
              An empty Visibility element controls the need to change the fixing of element below, it also uses height
              and width params received from its ref for correct display.
            */}
            <Visibility
              offset={80}
              once={false}
              onTopPassed={this.stickOverlay}
              onTopVisible={this.unStickOverlay}
              style={overlayFixed ? { ...overlayStyle, ...overlayRect } : {}}
            />

            <div ref={this.handleOverlayRef} style={overlayFixed ? fixedOverlayStyle : overlayStyle}>

            </div>

            <Switch>
              <PrivateRoute exact path="/">
                <SignIn />
              </PrivateRoute>
              <PrivateRoute exact path="/signout">
                <SignOut />
              </PrivateRoute>
              <Route exact path="/signin">
                <SignIn />
              </Route>
              <Route exact path="/trans">
                <Translate />
              </Route>
              <Route exact path="/registration-success">
                <RegistrationSuccess />
              </Route>
              <PrivateRoute exact path="/profile">
                <Profile />
              </PrivateRoute>
              <Route exact path="/validate-diagnosis">
                <ValidateDiagnosis />
              </Route>
              <Route exact path="/download">
                <DownloadApk />
              </Route>
              <Route exact path="/validate-diagnosis-no-params">
                <ValidateDiagnosisNoParams />
              </Route>


              <Route exact path="/auth/send-link">
                <SendLink />
              </Route>
              <Route exact path="/terms-conditions">
                <Terms />
              </Route>
              <PrivateRoute exact path="/profile-data">
                <ProfileData />
              </PrivateRoute>
              <PrivateRoute exact path="/realtime">
                <RealTime />
              </PrivateRoute>
              <PrivateRoute exact path="/demographics">
                <Demographics />
              </PrivateRoute>
              <PrivateRoute exact path="/question1">
                <QuickQuestion1 />
              </PrivateRoute>
              <PrivateRoute exact path="/question2">
                <QuickQuestion2 />
              </PrivateRoute>
              <PrivateRoute exact path="/question3">
                <QuickQuestion3 />
              </PrivateRoute>
              <PrivateRoute exact path="/question3edit">
                <QuickQuestion3Edit />
              </PrivateRoute>
              <PrivateRoute exact path="/allergyedit">
                <AllergyEdit/>
              </PrivateRoute>
              <PrivateRoute exact path="/allergy">
                <Allergy />
              </PrivateRoute>
              <PrivateRoute exact path="/recent-acute">
                <RecentAcute />
              </PrivateRoute>
              <PrivateRoute exact path="/recent-acute-edit">
                <RecentAcuteEdit />
              </PrivateRoute>
              <PrivateRoute exact path="/habits">
                <Habits />
              </PrivateRoute>
              <PrivateRoute exact path="/habitsedit">
                <HabitsEdit />
              </PrivateRoute>
              <PrivateRoute exact path="/surgery-history">
                <SurgeryHistory />
              </PrivateRoute>
              <PrivateRoute exact path="/surgery-history-edit">
                <SurgeryHistoryEdit />
              </PrivateRoute>
              <PrivateRoute exact path="/home">
                <Home />
              </PrivateRoute>
              <PrivateRoute exact path="/heart-rate">
                <HeartRate />
              </PrivateRoute>
              <PrivateRoute exact path="/resources">
                <Resources />
              </PrivateRoute>
              <PrivateRoute exact path="/notification">
                <Notification />
              </PrivateRoute>
              <PrivateRoute exact path="/notification/:id">
                <NotificationDetails />
              </PrivateRoute>
              <PrivateRoute exact path="/contact-us">
                <Contact />
              </PrivateRoute>
              <PrivateRoute exact path="/my-profile-data">
                <MyProfiledata />
              </PrivateRoute>
              <PrivateRoute exact path="/landing">
                <Landing />
              </PrivateRoute>

              {/* End of wellness */}
              
              <Route exact path="/auth/complete-reset/:id">
                <CompleteReset />
              </Route>
              <Route exact path="/auth/password-reset/:id">
                <CompleteReset />
              </Route>
              <Route exact path="/signup">
                <SignUp />
              </Route>
              <Route exact path="/auth/resend-verication">
                <ResendVerication />
              </Route>
              <Route exact path="/verify-account/:token">
                <Verify />
              </Route>
              <PrivateRoute exact path="/dashboard/faq">
                <Faq />
              </PrivateRoute>
              <PrivateRoute exact path="/dashboard/dianosis">
                <AllDiagnosis />
              </PrivateRoute>
              <PrivateRoute exact path="/dashboard">
                {/* <Dashboard /> */}
                <RecordVideo />
              </PrivateRoute>
              <PrivateRoute exact path="/uploadvideo">
                <RecordVideo />
              </PrivateRoute>
              <PrivateRoute exact path="/changepassword">
                <ChangePassword />
              </PrivateRoute>
              {/* <Route path="/users">
            <Users />
          </Route>
          <Route path="/">
            <Home />
          </Route> */}
            </Switch>


{/* auth/password-reset/5sjXWlAhpl1zAWvmjD6b4von67Fo91PHL2mBnGJRl6zBFFWSPJh4LXXUdR5f */}





            {/* </Container> */}
          </div>


        </div>
      </Router>
    )
  }
}