import axios from 'axios';
import React,{useContext, useEffect, useState} from 'react';
import { Card, Button, Spinner } from 'react-bootstrap'
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { QuestionContext } from '../../App';
import url from '../url';
import { Trans, useTranslation } from 'react-i18next';
const year = new Date().getFullYear() - 10

const Demographics = () => {
    const { t, i18n } = useTranslation()
    const [redirect, setRedirect] = useState(false)
    const [races, setRaces] = useState([])
    const [occupations, setOccupations] = useState([])
    const [levels, setLevels] = useState([])
    const [spin, setSpin] = useState(false)
    const [years, setYears] = useState([])
    const { questionstate, dispatchQuestion } = useContext(QuestionContext)
    
    useEffect(() => {
        
        getRaces()
        getOccupations()
        getLevels()
        renderyears()
    }, [])
    const getRaces = () => {
        setSpin('border')
        // alert('yes')
        
        var token=sessionStorage.getItem('mytoken', false)
                const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        axios.get(url + 'wellness/races', config)
            .then(res => {
                // alert('races')
                console.log('res.data.result')
                setRaces(res.data.result)
            
            }).catch(e => {
                console.log(e)
            
            }).then(res => {
                // setSpin(false)
                // alert('yes')
            
        })
    }
    const getOccupations = () => {
        setSpin('border')
        
        var token=sessionStorage.getItem('mytoken', false)
                const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        axios.get(url + 'wellness/occupations', config)
            .then(res => {
                // console.log(res.data.result)
                setOccupations(res.data.result)
            
            }).catch(e => {
                console.log(e)
            
            }).then(res => {
                setSpin(false)
            
        })
    }
    const getLevels = () => {
        // setSpin('border')
        
        var token=sessionStorage.getItem('mytoken', false)
                const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        axios.get(url + 'wellness/educations', config)
            .then(res => {
                // console.log(res.data.result)
                setLevels(res.data.result)
            
            }).catch(e => {
                console.log(e)
            
            }).then(res => {
                // setSpin(false)
            
        })
    }

    const renderraces = races.map((race, index) => (
        <option value={race.id} key={index}>{ race.name}</option>
    ))
    const renderoccupations = occupations.map((race, index) => (
        <option value={race.id} key={index}>{ race.name}</option>
    ))
    const renderlevels = levels.map((race, index) => (
        <option value={race.id} key={index}>{ race.name}</option>
    ))

    const renderyears = () => {
        for (let i = year; i > 1950; i--) {
            setYears(years=>[...years,i])
//             return (
//       <option>Hello</option>
//   )
}
    }
    // console.log(years)
    const yearsrender = years.map((year, index) => (
        <option value={year} key={index}>{ year}</option>
    ))
    return (<div className="container">
        {redirect ? (<>
            <Redirect to="/question2" />
            
            

        </>) : (<></>)}
        
        
        
        <div className="row">
            <div className="col-sm-2" />
            <div className="col-sm-6">
                {/* <Card style={{ border: 'rounded-pill', borderRadius: "30px" }} >
                    <Card.Body> */}
                
                       
                    
                
                <div className="row">

                    
                    <div className=" darkbluebackground alert text-white col-sm-12">
                        <div className="row">
                        
                        <table>
                            <tr>
                                    <td>
                                        <div className="col-sm-2">
                        <Button variant="link" as={Link} size="lg" to="/profile-data" style={{ color: 'white', fontWeight: '600' }} ><i class="fa fa-arrow-left" aria-hidden="true"></i></Button>
                    </div>
                                    
                                </td>
                                    <td>
                                        <div className="col-6 text-center">
                               <h4> {t('wellness.form.domographics')}</h4>

                            </div>

                                </td>
                            </tr>
                        </table>
                        </div>
                        
                            
                            
                                
                            
                            
                           
                           
                        </div>
                </div>
                <div className="row">
            
            
            <center><Spinner animation={spin} variant="primary" /></center>
        </div>
                       
    <div class="form-group row">
    <label for="inputPassword" class="col-sm-4 col-form-label">{t('wellness.form.birt_year')}</label>
    <div class="col-sm-4">
        {/* <select className="form-control" value={questionstate.birth_year} onChange={(e) => dispatchQuestion({ type: 'birth_year', value: e.target.value })}>
        <option value="">Select...</option>
        {yearsrender}
                                    
         </select> */}
                        <input type="number" className="form-control"  value={questionstate.birth_year} onChange={(e) => dispatchQuestion({ type: 'birth_year', value: e.target.value })} />
    </div>
                        </div>
                       
    <div class="form-group row">
    <label for="inputPassword" class="col-sm-4 col-form-label">{t('wellness.form.gender')}</label>
    <div class="col-sm-4">
        <select className="form-control" value={questionstate.gender} onChange={(e) => dispatchQuestion({ type: 'gender', value: e.target.value })}>
        <option value="">{t('wellness.optional.select')}...</option>
        <option value="M">{t('wellness.form.male')}</option>
        <option value="F">{t('wellness.form.female')}</option>
        
                                    
         </select>
    </div>
                        </div>
                        

                        
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label>
                                        {t('wellness.form.race')}
                                    </label>
                                    <select className="form-control" value={questionstate.race} onChange={(e) => dispatchQuestion({ type: 'race', value: e.target.value })}>
                                        <option value="">{t('wellness.optional.select')}...</option>
                                        {renderraces}

                                    </select>
                                </div>
                            </div>

                        </div>
                        
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label>
                                        {t('wellness.form.occupation')}
                                    </label>
                                     <select className="form-control" value={questionstate.occupation} onChange={(e) => dispatchQuestion({ type: 'occupation', value: e.target.value })}>
                                    <option value="">{t('wellness.optional.select')}...</option>
                                    {renderoccupations}
                                </select>
                                </div>
                               
                            </div>
                            
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label>
                                        {t('wellness.form.level_education')}
                                    </label>
                                     <select className="form-control" value={questionstate.education_level} onChange={(e) => dispatchQuestion({ type: 'education_level', value: e.target.value })}>
                                    <option value="">{t('wellness.optional.select')}...</option>
                                        {renderlevels}
                                    
                                </select>
                                </div>
                               
                            </div>
                            
                        </div>
                        


                <br />
                
                <div className="row" style={{paddingTop:'5px'}}>
                            <div className="col-sm-6">
                                <div>
                                    
                                    {/* <Button variant="link" as={Link} to="/profile-data" style={{ color: '#1d7ced', fontWeight: '600' }} > <i class="icons-edit1"></i><i className="fas1 fa-edit2"></i>Previous</Button> */}
                                </div>
                                
                               
                            </div>
                            <div className="col-sm-6">
                                
                                <div className="float-right">
                                    
                                    <Button variant="link" as={Link} to="/question2" style={{ color: '#1d7ced', fontWeight: '600' }} > <i class="icons-edit1"></i><i className="fas1 fa-edit2"></i><img src='../assets/wellness/Next.png' class="responsive" style={{width:'40px'}} />< br />Next</Button>
                                    
                                </div>
                               
                            </div>
                            
                        </div>
                {/* </Card.Body>
                </Card> */}
            </div>
            

            
        </div>
        


    </div>);
}
 
export default Demographics;