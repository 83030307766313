import React from 'react';

const Landing = () => {
    return (
        <section class="features-icons text-center">
            {/* <div className="row" style={{ paddingLeft: '20px', paddingRight: '30px' }}>
              <div className="col-sm-3" />
              <div className="col-sm-6">
                    <img src='../assets/wellness/Logo.png' style={{ width: '200px' }} class="responsive" />
                    
                    <br />

              </div>
              <div className="col-sm-3" />
            </div> */}
            <br />
            <div className="row">

                <div className="col-sm-12">
                  {/* <h4 className="text-center">{t('login.form.signin')}</h4>
                   */}
                  <center>
                  <span className="text-center">Welcome To The </span>
                  <h1>DOCSUN HEALTH AND WELLNESS APP</h1> 
                  </center>
                </div>
            </div>
            <br />
            <br />
            <div class="container">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                            <div class="features-icons-icon d-flex"><i class="bi-window m-auto text-primary"></i></div>
                            <img src='../assets/wellness/new/Trackvitalsigns.png' class="responsive" style={{width:'150px'}} />
                            {/* <h3>Fully Responsive</h3> */}
                            <p class="lead mb-0">Track your vital signs & wellness stats.
Keep a record of your health & provide access
a full health report when visiting your doctor.</p>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                            <div class="features-icons-icon d-flex"><i class="bi-layers m-auto text-primary"></i></div>
                            <img src='../assets/wellness/new/Maintainhealthlifestyle.png' class="responsive" style={{width:'150px'}} />
                            {/* <h3>Bootstrap 5 Ready</h3> */}
                            <p class="lead mb-0">Maintain a healthy lifestyle by monitoring your fitness, exercise, sleep & calories burnt</p>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="features-icons-item mx-auto mb-0 mb-lg-3">
                            <img src='../assets/wellness/new/Interactwithexperts.png' class="responsive" style={{width:'150px'}} />
                            {/* <h3>Easy to Use</h3> */}
                            <p class="lead mb-0">Interact with experts and get free content teaching you how to maintain a healthy lifestyle in our ‘Learn’ section of the app</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      );
}
 
export default Landing;