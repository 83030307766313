import React from 'react';
import { Image } from 'semantic-ui-react';
import { Card } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
const RegistrationSuccess = () => {
    const { t, i18n } = useTranslation()
    return (<div className="container" style={{height:'100vh'}}>
        
        <div className="row">
            <div className="col-sm-2"></div>
            <div className="col-sm-8">
                {/* <Card style={{backgroundImage: `url("https://app-test.docsun.health/img/bg.9cd18d37.jpg")`, color:'white'}}> */}
                
  
                {/* </Card> <br /><br /> */}
                <Card>
                    <Card.Body>
                        <div className="row" style={{ paddingLeft: '20px', paddingRight: '30px' }}>
              <div className="col-sm-3" />
              <div className="col-sm-6">
                                <img src='../assets/Webappimages16/Logo.png' class="responsive" />
                                <br />
                                <br />

              </div>
                            <div className="col-sm-3" />
                            <br />
                        </div>
                        <p className="text-center">{t('registration-success.title')}</p>
                        <p>{t('registration-success.p1')}</p>
                        
                        <p>{t('registration-success.p2')}</p>
                        
  </Card.Body>
</Card>

            </div>
            <div className="col-sm-2"></div>
        </div>
        
    </div>    );
}
 
export default RegistrationSuccess;