// import logo from './logo.svg';
import './assets/global.css';
import { useEffect, useReducer, useState } from 'react';
import Nav from './components/Nav'
import Nav2 from './components/Nav2'
import Nav3 from './components/Nav3';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import axios from 'axios';
import url from './components/url'
import { Redirect, Router } from 'react-router';
import SignOut from './components/auth/SignOut';


export const LanguageContext = React.createContext();
export const AuthContext = React.createContext();
export const QuestionContext= React.createContext()

const initiallanguagestate = 'de'
const initialauthstate = false;

const initialgoal={}
const allergystateinitial = {
    iodine:false,
    latex:false,
    gluten:false,
    seafood:false,
    peanut:false,
    milk:false,
    nuts: false,
    
    
  }
const recentacutestateinitial = {
    // start of recent acute
  highbloodpressure:false,
  heartattack:false,
  highcholestorol:false,
  diabeties:false,
  cancer:false,
  heartfailure:false,
  arrhythmia:false,
  stroke:false,
  clothingdisorder: false,
    
    
  }
const surgeryhistoryinitial = {
    // start of recent acute
  jointreplacement: false,
  circumcision: false,
  brokenbonerepair: false,
  sternprocedure: false,
  angioplastyandatherectomy: false,
  hysterectomy: false,
  gallbladderremoval: false,
  heartbypasssurgery: false,
    
    
  }


const initialstatequestionstate = {
  
  
    
  allergies: [],

  allergy_other:'',
  recentacute_other:'',
    
  
  // Start of SUrgery history
  

  // profile data
  drink_other: ' ',
  drink_frequency:'No',
  drinks: 'No',
  
  
  first_name: '',
  last_name: '',
  country: '',
  city:'',
  education_level:'',
  occupation:'',
  race:'',
  gender:'',
  birth_year: '',
  blood_type: '',
  rhesus_factor: '',
  weight: '',
  weight_unit_measure: 'Kgs',
  height_cm: '',
  height_feet: '',
  height_inches: '',
  save_spin: false,
  save_success: false,
  session_expired: true,
  save_error: [],
  countries: [],
  cities: [],
  races: [],
  occupations: [],
  education_levels: [],
  picture:null
  // height_inches: '',
  
  

  
}


const allergyreducer = (state, action) => {
  switch (action.type) {
    case 'iodine':
      return { ...state, iodine: !state.iodine }

          case 'latex':
      // allergies: [1,2]

      return {
        ...state, latex: !state.latex
      }
    case 'gluten':
      // allergies: [1,2]

      return {
        ...state, gluten: !state.gluten
      }
    case 'eggs':
      // allergies: [1,2]

      return {
        ...state, eggs: !state.eggs
      }
    case 'seafood':
      // allergies: [1,2]

      return {
        ...state, seafood: !state.seafood
      }
    case 'milk':
      // allergies: [1,2]

      return {
        ...state, milk: !state.milk
      }
    case 'peanut':
      // allergies: [1,2]

      return {
        ...state, peanut: !state.peanut
      }
    case 'nuts':
      // allergies: [1,2]

      return {
        ...state, nuts: !state.nuts
      }
    case 'all_state':
      // allergies: [1,2]


      return state= action.value
    
    case 'allergy_other':
      // allergies: [1,2]

      return {
        ...state, allergy_other: action.value
      }
    case 'recentacute_other':
      // allergies: [1,2]

      return {
        ...state, recentacute_other: action.value
      }

  }
}
const surgeryhistoryreducer = (state, action) => {
  switch (action.type) {
    case 'jointreplacement':
      return {
        ...state, jointreplacement: !state.jointreplacement
      }
    case 'circumcision':
      return {
        ...state, circumcision: !state.circumcision
      }
    case 'brokenbonerepair':
      return {
        ...state, brokenbonerepair: !state.brokenbonerepair
      }
    case 'sternprocedure':
      return {
        ...state, sternprocedure: !state.sternprocedure
      }
    case 'angioplastyandatherectomy':
      return {
        ...state, angioplastyandatherectomy: !state.angioplastyandatherectomy
      }
    case 'hysterectomy':
      return {
        ...state, hysterectomy: !state.hysterectomy
      }
    case 'gallbladderremoval':
      return {
        ...state, gallbladderremoval: !state.gallbladderremoval
      }
    case 'heartbypasssurgery':
      return {
        ...state, heartbypasssurgery: !state.heartbypasssurgery
      }
    case 'all_state':
      // allergies: [1,2]


      return state= action.value
    

  }
}
const recentacutereducer = (state, action) => {
  switch (action.type) {
        case 'highbloodpressure':
      return {
        ...state, highbloodpressure: !state.highbloodpressure
      }
    case 'heartattack':
      return {
        ...state, heartattack: !state.heartattack
      }
    
    case 'highcholestorol':
      return {
        ...state, highcholestorol: !state.highcholestorol
      }
    case 'diabeties':
      return {
        ...state, diabeties: !state.diabeties
      }
    case 'cancer':
      return {
        ...state, cancer: !state.cancer
      }
    case 'heartfailure':
      return {
        ...state, heartfailure: !state.heartfailure
      }
    case 'arrhythmia':
      return {
        ...state, arrhythmia: !state.arrhythmia
      }
    case 'stroke':
      return {
        ...state, stroke: !state.stroke
      }
    case 'clothingdisorder':
      return {
        ...state, clothingdisorder: !state.clothingdisorder
      }
    case 'all_state':
      // allergies: [1,2]


      return state= action.value
    

  }
}

const questionreducer = (state, action) => {
  switch (action.type) {

    // Start of allergy
    case 'iodine':
      // allergies: [1,2]
      
      console.log('hello world')
      return {
        ...state.allergy, iodine: !state.iodine
      }

      
    case 'latex':
      // allergies: [1,2]

      return {
        ...state, latex: !state.latex
      }
    case 'gluten':
      // allergies: [1,2]

      return {
        ...state, gluten: !state.gluten
      }
    case 'eggs':
      // allergies: [1,2]

      return {
        ...state, eggs: !state.eggs
      }
    case 'seafood':
      // allergies: [1,2]

      return {
        ...state, seafood: !state.seafood
      }
    case 'milk':
      // allergies: [1,2]

      return {
        ...state, milk: !state.milk
      }
    case 'peanut':
      // allergies: [1,2]

      return {
        ...state, peanut: !state.peanut
      }
    case 'nuts':
      // allergies: [1,2]

      return {
        ...state, nuts: !state.nuts
      }

    case 'allegies':
      // allergies: [1,2]
      console.log('action.value')
      console.log(action)

      return { ...state, allergies: action.value
        
      }
    // End of allergy
    //start of recent acute
    case 'highbloodpressure':
      return {
        ...state, highbloodpressure: !state.highbloodpressure
      }
    case 'heartattack':
      return {
        ...state, heartattack: !state.heartattack
      }
    
    case 'highcholestorol':
      return {
        ...state, highcholestorol: !state.highcholestorol
      }
    case 'diabeties':
      return {
        ...state, diabeties: !state.diabeties
      }
    case 'cancer':
      return {
        ...state, cancer: !state.cancer
      }
    case 'heartfailure':
      return {
        ...state, heartfailure: !state.heartfailure
      }
    case 'arrhythmia':
      return {
        ...state, arrhythmia: !state.arrhythmia
      }
    case 'stroke':
      return {
        ...state, stroke: !state.stroke
      }
    case 'clothingdisorder':
      return {
        ...state, clothingdisorder: !state.clothingdisorder
      }
      // Start of surgery history
    case 'jointreplacement':
      return {
        ...state, jointreplacement: !state.jointreplacement
      }
    case 'circumcision':
      return {
        ...state, circumcision: !state.circumcision
      }
    case 'brokenbonerepair':
      return {
        ...state, brokenbonerepair: !state.brokenbonerepair
      }
    case 'sternprocedure':
      return {
        ...state, sternprocedure: !state.sternprocedure
      }
    case 'angioplastyandatherectomy':
      return {
        ...state, angioplastyandatherectomy: !state.angioplastyandatherectomy
      }
    case 'hysterectomy':
      return {
        ...state, hysterectomy: !state.hysterectomy
      }
    case 'gallbladderremoval':
      return {
        ...state, gallbladderremoval: !state.gallbladderremoval
      }
    case 'heartbypasssurgery':
      return {
        ...state, heartbypasssurgery: !state.heartbypasssurgery
      }
    
    // Profile data
    case 'first_name':

      return {
        ...state, first_name:action.value

      }
    case 'drinks':

      return {
        ...state, drinks:action.value

      }
    case 'drink_frequency':

      return {
        ...state, drink_frequency:action.value

      }
    case 'drink_other':

      return {
        ...state, drink_other:action.value

      }
    case 'last_name':

      return {
        ...state, last_name:action.value

      }
    case 'country':

      return {
        ...state, country:action.value

      }
    case 'city':

      return {
        ...state, city:action.value

      }
    case 'birth_year':

      return {
        ...state, birth_year:action.value

      }
    case 'gender':

      return {
        ...state, gender:action.value

      }
    case 'race':

      return {
        ...state, race:action.value

      }
    case 'occupation':

      return {
        ...state, occupation:action.value

      }
    case 'education_level':

      return {
        ...state, education_level:action.value

      }
    case 'education_level':

      return {
        ...state, education_level:action.value

      }
    case 'blood_type':

      return {
        ...state, blood_type:action.value

      }
    case 'rhesus_factor':

      return {
        ...state, rhesus_factor:action.value

      }
    case 'weight_unit_measure':

      return {
        ...state, weight_unit_measure:action.value

      }
    case 'weight':

      return {
        ...state, weight:action.value

      }
    case 'height_cm':

      return {
        ...state, height_cm:action.value

      }
    case 'height_feet':

      return {
        ...state, height_feet:action.value

      }
    case 'height_inches':

      return {
        ...state, height_inches:action.value

      }
    
    case 'save_spin':
      return {
        ...state, save_spin: action.value
      }
    case 'save_error':
      return {
        ...state, save_error: action.value
      }
    case 'save_success':
      return {
        ...state, save_success: action.value
      }
    case 'session_expired':
      return {
        ...state, session_expired: action.value
      }
    case 'countries':
      return {
        ...state, countries: action.value
      }
    case 'cities':
      return {
        ...state, cities: action.value
      }
    case 'races':
      return {
        ...state, races: action.value
      }
    case 'occupations':
      return {
        ...state, occupations: action.value
      }
    case 'education_levels':
      return {
        ...state, education_levels: action.value
      }
    case 'picture':
      return {
        ...state, picture: action.value
      }
    
    case 'allergy_other':
      // allergies: [1,2]

      return {
        ...state, allergy_other: action.value
      }
    case 'surgeryhistory_other':
      // allergies: [1,2]

      return {
        ...state, surgeryhistory_other: action.value
      }

      
    default:
      return state
  }
}

const reducer = (state, action) => {
  switch (action) {

    case 'en':
      return 'en';
    case 'zh_TW':
      return 'zh_TW'
    default:
      return state
  }
};
const authreducer = (state, action) => {
  switch (action) {

    case 'true':
      return true;
    case 'false':
      return false
    default:
      return state
  }
};


const goalsreducer = (state, action) => {
  switch (action.type) {

    

    case 'set_goals':
      
      return state=action.value
  
  }
  
}
  




function App() {
  const [state, dispatch] = useReducer(reducer, initiallanguagestate)
  const [authstate, authDispatch] = useReducer(authreducer, initialauthstate)
  const [questionstate, dispatchQuestion] = useReducer(questionreducer, initialstatequestionstate)
  const [allergystate, dispatAllery] = useReducer(allergyreducer, allergystateinitial)
  const [recentacutestate, dispatRecentAcute] = useReducer(recentacutereducer, recentacutestateinitial)
  const [initialgoalstate, dispatGoals] = useReducer(goalsreducer, initialgoal)
  const [surgeryhistory, dispatRecentHistory] = useReducer(surgeryhistoryreducer, surgeryhistoryinitial)
  const [redirectprofile,setRedirectProfile]=useState(false)
  const { t, i18n } = useTranslation()
  useEffect(() => {
    // 
    i18n.changeLanguage(state)
    fetchBio()

  }, [state])

  const fetchBio = () => {
        var token=sessionStorage.getItem('mytoken', false)
        const config = {
            headers: { Authorization: `Bearer ${token}` }
            
        };
        
        // setSpin('border')
        axios.get(url + 'wellness/user/profile', config)
            .then(res => {
                console.log(res.data.result.first_name)
                var dt=res.data.result
                // 
                dispatchQuestion({type:'first_name', value:dt.first_name})
                dispatchQuestion({type:'last_name', value:dt.last_name})
                dispatchQuestion({type:'country', value:dt.country_id})
                dispatchQuestion({type:'city', value:dt.city_id})
                dispatchQuestion({type:'birth_year', value:dt.year_of_birth})
                dispatchQuestion({type:'gender', value:dt.gender})
                dispatchQuestion({type:'race', value:dt.race_id})
                dispatchQuestion({type:'occupation', value:dt.occupation_id})
                dispatchQuestion({type:'occupation', value:dt.occupation_id})
                dispatchQuestion({type:'education_level', value:dt.education_level_id})
                dispatchQuestion({type:'blood_type', value:dt.blood_type})
                dispatchQuestion({ type: 'rhesus_factor', value: dt.rhesus_factor })
                dispatchQuestion({ type: 'allergy_other', value: dt.other_allergy })
                dispatchQuestion({ type: 'surgeryhistory_other', value: dt.surgery_history_other })
                dispatchQuestion({ type: 'drink_other', value: dt.drink_other })
                dispatchQuestion({ type: 'recentacute_other', value: dt.recent_acute_other })
                dispatAllery({ type: 'all_state', value: dt.allergy })
                dispatRecentAcute({ type: 'all_state', value: dt.recent_acute_diseases })
                dispatRecentAcute({ type: 'all_state', value: dt.recent_acute_diseases })
                dispatRecentHistory({ type: 'all_state', value: dt.surgery_history })

                console.log("Start Allergy")
                console.log(dt.allergy)
                console.log("End Allergy")
                
                // format 
                var text = dt.weight
                
                const myArray = text.split(" ");

                
                

                dispatchQuestion({ type: 'weight', value: myArray[0] })
                dispatchQuestion({ type: 'weight_unit_measure', value: myArray[1] })

                // Format height

                var height = dt.height
                const heights = height.split(" ");

                console.log(dt)
                console.log(heights)


                dispatchQuestion({ type: 'height_feet', value: heights[0] })
                dispatchQuestion({ type: 'height_inches', value: heights[2] })
            
            }).catch(e => {
                console.log(e.message)
                if (e.message=='Request failed with status code 404') {
                    setRedirectProfile(true)
                }
            
            }).then(r => {
                // setSpin('false')
            
        })
    }
  

  const saveProfile = () => {
    

    // return
  
    dispatchQuestion({type:'save_spin', value:true})

    var myheight = 0
    if (questionstate.height_cm != '') {
      var n= questionstate.height_cm
      var realFeet = ((n*0.393700) / 12);
      var feet = Math.floor(realFeet);
      var inches = Math.round((realFeet - feet) * 12);
      myheight= feet + " ft " + inches + ' inches '
    } else {
      myheight= questionstate.height_feet + "feets" + questionstate.height_inches  + 'inches'
    }

    

  var token=sessionStorage.getItem('mytoken', false)
                const config = {
            headers: { Authorization: `Bearer ${token}` }
                };
    
    const formData = new FormData();

        // Update the formData object
        formData.append(
            "profile_photo",
            questionstate.picture,

        );
        // formData.append(
        //     "first_name",
        //     questionstate.first_name,

        // );
        // formData.append(
        //     "last_name",
        //     questionstate.last_name,

        // );
        // formData.append(
        //     "drink",
        //     questionstate.drinks,

        // );
        // formData.append(
        //     "drink_other",
        //     questionstate.drink_other,

        // );
        // formData.append(
        //     "drink_frequency",
        //     questionstate.drink_frequency,

        // );
        // formData.append(
        //     "surgery_history",
        //     surgeryhistory,

        // );
        // formData.append(
        //     "surgery_history_other",
        //     questionstate.surgeryhistory_other,

        // );
        // formData.append(
        //     "allergy",
        //     allergystate,

        // );
        // formData.append(
        //     "allergy_other",
        //     questionstate.allergy_other,

        // );
        // formData.append(
        //     "recent_acute_diseases",
        //     recentacutestate,

        // );
        // formData.append(
        //     "recent_acute_other",
        //     questionstate.recentacute_other,

        // );
        // formData.append(
        //     "middle_name",
        //     '',

        // );
        // formData.append(
        //     "gender",
        //     questionstate.gender,

        // );
        // formData.append(
        //     "gender",
        //     questionstate.gender,

        // );
        // formData.append(
        //     "year_of_birth",
        //     questionstate.birth_year,

        // );
        // formData.append(
        //     "occupation_id",
        //     questionstate.occupation,

        // );
        // formData.append(
        //     "race_id",
        //     questionstate.race,

        // );
        // formData.append(
        //     "education_level_id",
        //     questionstate.education_level,

        // );
        // formData.append(
        //     "country_id",
        //     questionstate.country,

        // );
        // formData.append(
        //     "blood_type",
        //     questionstate.blood_type,

        // );
        // formData.append(
        //     "rhesus_factor",
        //     questionstate.rhesus_factor,

        // );
        // formData.append(
        //     "city_id",
        //     questionstate.city,

        // );
        // formData.append(
        //     "weight",
        //     questionstate.weight,

        // );
        // formData.append(
        //     "weight_unit",
        //     questionstate.weight_unit_measure,

        // );
    
    // format height
    if (questionstate.height_cm != '') {
      var height=questionstate.height_cm
      var height_unit='cm'
    } else {
      var height_unit='ft'
      var height=questionstate.height_feet+'.'+questionstate.height_inches
      
    }
        // formData.append(
        //     "height",
        //     height,

        // );
        // formData.append(
        //     "height_unit",
        //     height_unit,

        // );
        const postdata = {
      allergy: allergystate,
      first_name: questionstate.first_name,
      last_name: questionstate.last_name,
      middle_name: '',
      middle_name: questionstate.last_name,
      gender: questionstate.gender,
      year_of_birth: questionstate.birth_year,
      occupation_id: questionstate.occupation,
      race_id: questionstate.race,
      education_level_id: questionstate.education_level,
      country_id: questionstate.country,
      blood_type: questionstate.blood_type,
      rhesus_factor: questionstate.rhesus_factor,
      city_id: questionstate.city,
      weight: questionstate.weight,
      weight_unit: questionstate.weight_unit_measure,
      drink: questionstate.drinks,
      drink_other: questionstate.drink_other,
      drink_frequency: questionstate.drink_frequency,
      surgery_history: surgeryhistory,
      surgery_history_other: questionstate.surgeryhistory_other,
      allergy_other: questionstate.allergy_other,
      recent_acute_diseases: recentacutestate,
      recent_acute_other: questionstate.recentacute_other,
      height_unit: height_unit,
      height: height,
      
        }
    
    const datatosend = {
      postdata, formData
    }
    axios.post(url + 'wellness/user/profile', postdata, config)
      .then(res => {
        console.log('res')
        console.log(res)
        // save_success
        dispatchQuestion({type:'save_success', value:true})
        dispatchQuestion({type:'save_error', value:[]})
        dispatchQuestion({type:'save_spin', value:false})
        
      }).catch(e => {
        console.log('e')
        console.log(e.response.data)
        // var = e.response.data.result
        if (e.response.data.result.constructor === Array) {
          dispatchQuestion({type:'save_error', value:e.response.data.result})
          
        }
        
        
      }).then(r => {
        // console.log(r)
        
        dispatchQuestion({type:'save_spin', value:false})
        
      })
    
    // Send form data
    // axios.post(url + 'wellness/user/profile', formData, config)
    //   .then(res => {
    //     console.log('res')
    //     console.log(res)
    //     // save_success
    //     dispatchQuestion({type:'save_success', value:true})
    //     dispatchQuestion({type:'save_error', value:[]})
    //     dispatchQuestion({type:'save_spin', value:false})
        
    //   }).catch(e => {
    //     console.log('e')
    //     console.log(e.response.data)
    //     // var = e.response.data.result
    //     if (e.response.data.result.constructor === Array) {
    //       dispatchQuestion({type:'save_error', value:e.response.data.result})
          
    //     }
        
        
    //   }).then(r => {
    //     // console.log(r)
        
    //     dispatchQuestion({type:'save_spin', value:false})
        
    //   })
  
  }
  const updateProfile = () => {

    // alert('l')


    

    // return
  
    dispatchQuestion({ type: 'save_spin', value: true })

    var myheight = 0
    if (questionstate.height_cm != '') {
      var n = questionstate.height_cm
      var realFeet = ((n * 0.393700) / 12);
      var feet = Math.floor(realFeet);
      var inches = Math.round((realFeet - feet) * 12);
      myheight = feet + " ft " + inches + ' inches '
    } else {
      myheight = questionstate.height_feet + "feets" + questionstate.height_inches + 'inches'
    }

    

    var token = sessionStorage.getItem('mytoken', false)
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    
    const formData = new FormData();

    // Update the formData object
    // console.log('questionstate.picture')
    // console.log(questionstate.picture)
    // return
    // console.log(allergystate);
    


    formData.append(
      "profile_photo",
      questionstate.picture,

    );
    // formData.append(
    //   "first_name",
    //   questionstate.first_name,

    // );
    // formData.append(
    //   "last_name",
    //   questionstate.last_name,

    // );
    // formData.append(
    //   "middle_name",
    //   '',

    // );
    // formData.append(
    //   "gender",
    //   questionstate.gender,

    // );
    // formData.append(
    //   "gender",
    //   questionstate.gender,

    // );
    // formData.append(
    //   "year_of_birth",
    //   questionstate.birth_year,

    // );
    // formData.append(
    //   "occupation_id",
    //   questionstate.occupation,

    // );
    // formData.append(
    //   "race_id",
    //   questionstate.race,

    // );
    // formData.append(
    //   "education_level_id",
    //   questionstate.education_level,

    // );
    // formData.append(
    //   "country_id",
    //   questionstate.country,

    // );
    // formData.append(
    //   "blood_type",
    //   questionstate.blood_type,

    // );
    // formData.append(
    //   "rhesus_factor",
    //   questionstate.rhesus_factor,

    // );
    // formData.append(
    //   "city_id",
    //   questionstate.city,

    // );
    // formData.append(
    //   "weight",
    //   questionstate.weight,

    // );
    // formData.append(
    //   "weight_unit",
    //   questionstate.weight_unit_measure,

    // );
    
    // formData.append(
    //   "drink",
    //   questionstate.drinks,

    // );
    // formData.append(
    //   "drink_other",
    //   questionstate.drink_other,

    // );
    // formData.append(
    //   "drink_frequency",
    //   questionstate.drink_frequency,

    // );
    // formData.append(
    //   "surgery_history",
    //   surgeryhistory,

    // );
    // formData.append(
    //   "surgery_history_other",
    //   questionstate.surgeryhistory_other,

    // );
    // formData.append(
    //   "allergy",
    //   allergystate,

    // );
    // formData.append(
    //   "allergy_other",
    //   questionstate.allergy_other,

    // );
    // formData.append(
    //   "recent_acute_diseases",
    //   recentacutestate,

    // );
    // formData.append(
    //   "recent_acute_other",
    //   questionstate.recentacute_other,

    // );
    
    // format height
    if (questionstate.height_cm != '') {
      var height = questionstate.height_cm
      var height_unit = 'cm'
    } else {
      var height_unit = 'ft'
      var height = questionstate.height_feet + ',' + questionstate.height_inches
      
    }

    console.log('questionstate.picture')
    console.log(questionstate.picture)
    // formData.append(
    //   "height",
    //   height,

    // );
    // formData.append(
    //   "height_unit",
    //   height_unit,

    // );
    
    const postdata = {
      allergy: allergystate,
      first_name: questionstate.first_name,
      last_name: questionstate.last_name,
      middle_name: '',
      middle_name: questionstate.last_name,
      gender: questionstate.gender,
      year_of_birth: questionstate.birth_year,
      occupation_id: questionstate.occupation,
      race_id: questionstate.race,
      education_level_id: questionstate.education_level,
      country_id: questionstate.country,
      blood_type: questionstate.blood_type,
      rhesus_factor: questionstate.rhesus_factor,
      city_id: questionstate.city,
      weight: questionstate.weight,
      weight_unit: questionstate.weight_unit_measure,
      drink: questionstate.drinks,
      drink_other: questionstate.drink_other,
      drink_frequency: questionstate.drink_frequency,
      surgery_history: surgeryhistory,
      surgery_history_other: questionstate.surgeryhistory_other,
      allergy_other: questionstate.allergy_other,
      recent_acute_diseases: recentacutestate,
      recent_acute_other: questionstate.recentacute_other,
      height_unit: height_unit,
      height: height,
      
    }
    axios.post( url + 'wellness/user/edit-profile', postdata, config)
      .then(res => {
        console.log('res')
        console.log(res)
        console.log(res.data.meta)
        // save_success
        dispatchQuestion({type:'save_success', value:true})
        dispatchQuestion({type:'save_error', value:[]})
        dispatchQuestion({type:'save_spin', value:false})
        
      }).catch(e => {
        console.log('e')
        console.log(e.response.data)
        // dispatchQuestion({type:'save_error', value:e.response.data.message})
      }).then(r => {
        // console.log(r)
        dispatchQuestion({type:'save_spin', value:false})
        
      })
    axios.post( url + 'wellness/user/edit-profile', formData, config)
      .then(res => {
        console.log('res')
        console.log(res)
        console.log(res.data.meta)
        // save_success
        dispatchQuestion({type:'save_success', value:true})
        dispatchQuestion({type:'save_error', value:[]})
        dispatchQuestion({type:'save_spin', value:false})
        
      }).catch(e => {
        console.log('e')
        console.log(e.response.data)
        // dispatchQuestion({type:'save_error', value:e.response.data.message})
      }).then(r => {
        // console.log(r)
        dispatchQuestion({type:'save_spin', value:false})
        
      })
  
  }
 
  
 
  return (<>
    
    

    <QuestionContext.Provider value={{
      questionstate: questionstate, dispatchQuestion: dispatchQuestion, saveProfile: saveProfile, updateProfile: updateProfile,
      allergystate, dispatAllery,recentacutestate, dispatRecentAcute,surgeryhistory, dispatRecentHistory,initialgoalstate, dispatGoals
    }}>

    

    <LanguageContext.Provider value={{ languagestate: state, languageDispatch: dispatch }}>

      <AuthContext.Provider value={{ authstate: authstate, authDispatch: authDispatch }}>
        <Nav2 />
      </AuthContext.Provider>

      </LanguageContext.Provider>
      </QuestionContext.Provider>
 </> );
}

export default App;
