import React,{useState} from 'react';
import { Card, Button } from 'react-bootstrap'
import { Redirect } from 'react-router';

const QuickQuestion1 = () => {
    const [redirect, setRedirect]=useState(false)
    return (<div className="container">
        {redirect ? (<>
            <Redirect to="/question2" />
            
            

      </>) : (<></>)}
        
        <div className="row">
            <div className="col-sm-3" />
            <div className="col-sm-6">
                <Card style={{ border: 'rounded-pill', borderRadius: "30px" }} >
                    <Card.Body>
                        <div className="row" style={{ paddingLeft: '20px', paddingRight: '30px' }}>
              <div className="col-sm-3" />
              <div className="col-sm-6">
                <img src='../assets/Webappimages16/Logo.png' class="responsive" />

              </div>
              <div className="col-sm-3" />
                        </div>
                        <br />

                    
                        <h3 className="text-center">Quick Questions</h3>
                        <hr />

                        
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label>
                                        First Name
                                    </label>
                                    <input type="text" className="form-control" placeholder="Enter Your First Name" />
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label>
                                        Last Name
                                    </label>
                                    <input type="text" className="form-control" placeholder="Enter Your Last Name" />
                                </div>
                            </div>

                        </div>
                        <hr />
                        <h3>Location</h3>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label>
                                        Country
                                    </label>
                                     <select className="form-control">
                                    <option>Select...</option>
                                    <option value="USA">USA</option>
                                </select>
                                </div>
                               
                            </div>
                            
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label>
                                        City
                                    </label>
                                     <select className="form-control">
                                    <option>Select...</option>
                                    <option value="Califonia">Califonia</option>
                                </select>
                                </div>
                               
                            </div>
                            
                        </div>
                        <hr />


                <br />
                
                <br />
                <button class="btn btn-primary btn-block" type="button" onClick={()=>setRedirect(true)} width="100%">Save</button>
                </Card.Body>
                </Card>
            </div>
            

            
        </div>
        


    </div>);
}
 
export default QuickQuestion1;