import React from 'react';

const RealTime = () => {
    return ( 
        <>
     <h1 className='text-center'>Docsun Real Time Detection Demo</h1>
            <br />
            
            <div className='row'>
                <div className='col-sm-6'>
                     <div id="vital_history" > 
    <h4> Diagnosis :  <b id="diagnosis"></b></h4>
    <h4> Pulse Rate : <b id="heart_rate"></b> beats per minute</h4>
    <h4> SPO2 Level : <b id="spo2_rate"> - </b> % </h4>
    <h4> Breathing Rate : <b id="br_rate"> - </b> breaths per minute</h4>
    <h4> Blood Pressure : <b id="syst_rate"> - </b> / <b id="diast_rate"> - </b> mmHg</h4>
    <h4> Blood Sugar : <b id="bs_rate"></b> mmol/L </h4>
  </div>
                    {/* <div id="vital_history">
    <h4> Diagnosis : <b id="diagnosis"></b></h4>
    <h4> Pulse Rate : <b id="heart_rate"></b> beats per minute</h4>
    <h4> SPO2 Level : <b id="spo2_rate"> - </b> % </h4>
    <h4> Breathing Rate : <b id="br_rate"> - </b> breaths per minute</h4>
    <h4> Blood Pressure : <b id="syst_rate"> - </b> / <b id="diast_rate"> - </b> mmHg</h4>
    <h4> Blood Sugar : <b id="bs_rate"></b> mmol/L </h4>

                        
  </div> */}
                    
                </div>
                <div className='col-sm-5'>

                    <video hidden id="webcam" width="640" height="480"></video>
    <canvas id="canvas" width="640" height="480"></canvas>
    <button id="scan" className='btn btn-primary'>Start Scanning</button>
    <button id="stop" disabled className='btn btn-info'>Stop Scanning</button>
                    {/* <div>
    <video hidden id="webcam" width="640" height="480"></video>
    <canvas id="canvas" width="640" height="480"></canvas>
  </div> */}

                </div>
            </div>
  
  {/* </br> */}
  
            
        </>
     );
}
 
export default RealTime;