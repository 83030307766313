import axios from 'axios'
import React, { useState } from 'react'
import { Card, Button } from 'react-bootstrap'
import { Image } from 'semantic-ui-react'
import url from './url'
import Spinner from 'react-bootstrap/Spinner'
import { Redirect } from "react-router-dom";
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import HideNav from './auth/HideNav'
const SignIn = () => {

    const { t, i18n } = useTranslation()
  return (
    <div className="container" style={{ height: "100vh" }}>
     


      <div className="row">
        <div className="col-sm-3">
          <HideNav />
              </div>
              <div className="col-sm-1" />
              <div className="col-sm-4" >
                  <div className="logininstruction">
                    <center>  Android v1.6.0q<br />
                      2021/10/10</center>
                  </div>
              </div>
          </div>
          <br />
            <div className="row" style={{paddingLeft:'70px'}}>
            <div className="col-sm-3">

            </div>
            <div className="col-sm-6">
              <center>
                              <a href="https://drive.google.com/file/d/1aFDoN5t36gvG0MjhpGnOPkBgO0QJVV8h/view?usp=sharing" style={{ background: "#1d7ced", borderColor: "#28696D", color: "#ffffff" }} className="btn btn-primary btn-sm shadow"> <i class="fa fa-arrow-down" aria-hidden="true"></i>  {t('login.form.download_app')}</a><br />
                              
              

            </center>
            </div>
                  </div>
                  <br />
                  <div className="row" style={{paddingLeft:'70px'}}>
                    <Button variant="link" as={Link} to="/" style={{ color: '#1d7ced', fontWeight: '600' }} > <i class="icons-edit1"></i><i className="fas1 fa-edit2"></i>{t('login.form.back_to_login')}</Button>
                  </div>
             
              
           


   
          
          <br />
          

        
        
      

    </div >

  )
}


export default SignIn