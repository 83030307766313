import React,{useContext, useEffect, useState} from 'react';
import { Card, Button, ToggleButton, ButtonGroup,ToggleButtonGroup } from 'react-bootstrap'
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { QuestionContext } from '../../App';
import ToggleButtonExample from './Testbuttons';
import { Trans, useTranslation } from 'react-i18next';

const Allergy = () => {
    const {questionstate,dispatchQuestion,allergystate, dispatAllery} = useContext(QuestionContext);
    const [redirect, setRedirect] = useState(false)
    // const [iodine, setIodine] = useState(false);
    const [latex, setLatex] = useState(false);
    const [gluten, setGluten] = useState(false);
    const [eggs, setEggs] = useState(false);
    const [seafood, setSeaFood] = useState(false);
    const [peanut, setPeanut] = useState(false);
    const [milk, setMilk] = useState(false);
    const [nuts, setNuts] = useState(false);
    const [allergies, setAllergies] = useState([])
    const { t, i18n } = useTranslation()

    console.log( 'dispatAllery')
    console.log( dispatAllery)
    console.log(allergystate)

    useEffect(() => {

        console.log(questionstate)
        
    },[questionstate])


    // This function updates allergy list and call the reducer
    const updateAllargylist = (allergy, action) => {

        setAllergies(allergies=> [...allergies, 'allergy'])
        // console.log('start of first allergies')
        // console.log('=================================')
        // console.log(allergies)
        // console.log('End of first  allergies')
        // console.log('=================================')
        // // setAllergies(allergies=> [...allergies, 'place of space']) 
        // console.log('After adding space')
        // console.log('=================================')
        // console.log(allergies)

        
        

        if (action !== "add") {
        

            setAllergies(allergies.filter(item => item.name !== allergy))
            console.log('remove')

            for( var i = 0; i < allergies.length; i++){ 
    
                if ( allergies[i] === allergy) { 
            
                    allergies.splice(i, 1); 
                }
    
            }

            setAllergies(allergies)
            
            
        } else {

            setAllergies(allergies=> [...allergies, allergy])
            
            
            
           
            
        }
        
        let allas=['John','josphat']

        dispatchQuestion({type:"allegies",value:allas})


         
        

        
    }
    
    const setIodine = () => {
        // alert()
        if (questionstate.iodine) {
            updateAllargylist('iodine','remove')
            
        } else {
            updateAllargylist('iodine','add')
            
        }

        dispatchQuestion({type:"iodine"})
    }
    
    const latexfn = (e) => {
        setLatex(e.currentTarget.checked)
    }
    
    useEffect(() => {
         console.log('allergies here')
    console.log(questionstate.allergies)
        
    },[questionstate.allergies])
   
    return (<div className="container">
        {redirect ? (<>
            <Redirect to="/question2" />
            
            

        </>) : (<></>)}

        
        {/* <ToggleButtonExample /> */}

        

        {/* {updateAllargylist} */}

        {/* <button onClick={()=>updateAllargylist('iodine','remove')}>Click</button> */}
        
        
        
        <div className="row">
            <div className="col-sm-3" />
            <div className="col-sm-6">
                {/* <Card style={{ border: 'rounded-pill', borderRadius: "30px" }} >
                    <Card.Body> */}
                        
                <div className="darkbluebackground row alert text-white ">
                    <table>
                        <tr>
                            <td>
                                <div className="col-sm-2">
                        <Button variant="link" as={Link} size="lg" to="/question3" style={{ color: 'white', fontWeight: '600' }} ><i class="fa fa-arrow-left" aria-hidden="true"></i></Button>
                    </div>
                                
                            </td>
                            <td>
                                <div className="col-sm-6" >
                            {/* <div className="row"> */}
                                
                            {/* <div className="col-12"> */}
                            <h4>{t('wellness.optional.optional')}</h4>

                            {/* </div> */}
                            {/* </div> */}
                           
                           
                        </div>

                            </td>
                        </tr>
                    </table>
                    
            
        </div>

                    
                        <h3 className="text-center">{t('wellness.optional.allergy')}</h3>
                        <div className="text-center">{t('wellness.optional.allergy_sub')}</div>
                        <hr />

                        <table class="">
                            <tr>
                                <th>
                                    <ToggleButton
                                    className="mb-2"
                                    id="iodine"
                                    type="checkbox"
                                    variant="outline-primary"
                                    checked={allergystate.iodine} 
                                    value="1"
                                    onChange={(e) => dispatAllery({type:"iodine"})}
                                    // onChange={(e) => setIodine(e.currentTarget.checked)}
                                >
                                          &nbsp;&nbsp;{t('wellness.allergy.iodine')}
                                    </ToggleButton>
                                </th>
                                <td>
                                    <ToggleButton
                                    className="mb-2"
                                    id="latex"
                                    type="checkbox"
                                    variant="outline-primary"
                                    checked={allergystate.latex}
                                    value="1"
                                    onChange={(e) => dispatAllery({type:"latex"})}
                                >
                                    &nbsp;&nbsp;{t('wellness.allergy.latex')}
                                </ToggleButton>

                                </td>
                                <td>
                                        <ToggleButton
                                    className="mb-2"
                                    id="gluten"
                                    type="checkbox"
                                    variant="outline-primary"
                                    checked={allergystate.gluten}
                                    value="1"
                                    onChange={(e) => dispatAllery({type:"gluten"})}
                                >
                                       &nbsp;&nbsp;{t('wellness.allergy.gluten')}
                                </ToggleButton>

                                    </td>
                                    <td>
                                        <ToggleButton
                                    className="mb-2"
                                    id="gluten"
                                    type="checkbox"
                                    variant="outline-primary"
                                    checked={allergystate.eggs}
                                    value="1"
                                    onChange={(e) => dispatAllery({type:"eggs"})}
                                >
                                       &nbsp;&nbsp;{t('wellness.allergy.egg')}
                                </ToggleButton>

                                    </td>

                            
                            </tr>
                            <tr>
                                <td>
                                    <ToggleButton
                                    className="mb-2"
                                    id="gluten"
                                    type="checkbox"
                                    variant="outline-primary"
                                    checked={allergystate.seafood}
                                    value="1"
                                    onChange={(e) => dispatAllery({type:"seafood"})}
                                >
                                       &nbsp;&nbsp;{t('wellness.allergy.seafood')}
                                </ToggleButton>
                                    
                                </td>

                                <td>
                                <ToggleButton
                                    className="mb-2"
                                    id="gluten"
                                    type="checkbox"
                                    variant="outline-primary"
                                    checked={allergystate.peanut}
                                    value="1"
                                    onChange={(e) => dispatAllery({type:"peanut"})}
                                >
                                       &nbsp;&nbsp;{t('wellness.allergy.peanut')}
                                </ToggleButton>
                                </td>
                                <td>
                                    <ToggleButton
                                    className="mb-2"
                                    id="gluten"
                                    type="checkbox"
                                    variant="outline-primary"
                                    checked={allergystate.milk}
                                    value="1"
                                    onChange={(e) => dispatAllery({type:"milk"})}
                                >
                                      &nbsp;&nbsp; {t('wellness.allergy.milk')}
                                </ToggleButton>

                                </td>
                                <td>
                                    <ToggleButton
                                    className="mb-2"
                                    id="gluten"
                                    type="checkbox"
                                    variant="outline-primary"
                                    checked={allergystate.nuts}
                                    value="1"
                                    onChange={(e) => dispatAllery({type:"nuts"})}
                                >
                                       &nbsp;&nbsp;{t('wellness.allergy.nuts')}
                                </ToggleButton>

                                </td>
                            </tr>
                        </table>

                        
                        

                        <br />
                        

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label> {t('wellness.optional.cant_find')} </label>
                            <textarea rows="4" className="form-control" onChange={(e) => dispatchQuestion({ type: "allergy_other", value: e.target.value })}>{questionstate.allergy_other }</textarea>
                                </div>
                            </div>
                        </div>
                        
                        
                        
                        
                        
                        <div className="row" style={{paddingTop:'20px'}}>
                            <div className="col-sm-4">
                                
                                
                               
                            </div>
                            <div className="col-sm-6">
                                
                                <div>
                                    
                                    <Button variant="link" as={Link} size="lg" to="/question3" style={{ color: '#1d7ced', fontWeight: '600' }} ><i class="fa fa-arrow-left" aria-hidden="true"></i></Button><br />
                                    {t('wellness.optional.back')}
                                    
                                </div>
                               
                            </div>
                            
                        </div>
                        


                <br />
                
                
                {/* <button class="btn btn-primary btn-block" type="button" onClick={()=>setRedirect(true)} width="100%">Save</button> */}
                {/* </Card.Body>
                </Card> */}
            </div>
            

            
        </div>
        


    </div>);
}
 
export default Allergy;