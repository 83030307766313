import React,{useState} from 'react';
import { Card, Button } from 'react-bootstrap'
import { Redirect } from 'react-router';
import {NotificationContainer, NotificationManager} from 'react-notifications';

const Terms = () => {
    const [checked, setChecked] = useState(false)
    const [redirect, setRedirect]=useState(false)
    const handleClick = () => setChecked(!checked)
    const agreed = () => {
        if (!checked) {
            // alert("accept terms and condition");
            NotificationManager.info('Accept terms and condition');
            return;
        }
        setRedirect(true)

        // alert('terms accepted')
    }
    return (<div className="container">
        {redirect ? (<>
            <Redirect to="/registration-success" />
            

      </>) : (<></>)}
        <div className="row">
            < NotificationContainer />
            {/* <div className="col-sm-3" /> */}
            <div className="col-sm-6">
                <Card style={{ border: 'rounded-pill', borderRadius: "30px" }} className="text-center" >
                    <Card.Body>
                        <div className="row" style={{ paddingLeft: '20px', paddingRight: '30px' }}>
              <div className="col-sm-3" />
              <div className="col-sm-6">
                <img src='../assets/Webappimages16/Logo.png' class="responsive" />

              </div>
              <div className="col-sm-3 " />
                        </div>
                        <br />
                    
                        <h3>Privacy Policy</h3>
        <p>
We promise to keep your data private and not share with any third party.
        </p>
        <p>
            We are GDPR Compaint
        </p>
        <p>
            We have control of your data and can delete it upon personal request.

        </p>
        <p>
            We may provide links to websites and other third-party content that is not owned or operated
            by Docsun Computation MedLab Ltd.
                </p>
                <br />
                <p>
                <input onClick={handleClick} checked={checked} type="checkbox" /> I hereby agree to the terms & conditions
                </p>
                <br />
                <button class="btn btn-primary btn-block" type="button" onClick={()=>agreed()} width="100%">AGREE</button>
                </Card.Body>
                </Card>
            </div>
            

            
        </div>
        


    </div>);
}
 
export default Terms;