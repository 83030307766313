import React, { useState } from 'react';
import { Image } from 'semantic-ui-react';
import { Spinner } from 'react-bootstrap';

import { Card, Button } from 'react-bootstrap';
import axios from 'axios';
import url from '../url';
import { useParams } from 'react-router-dom';
const CompleteReset = () => {
    let { id } = useParams();
    

    
    
    const [error, setError] = useState('')
    const [spin, setSpin] = useState('false')
    const [success, setSuccess] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword]=useState('')
    const [confirmpassword, setConfirmPassword]=useState('')
    
    const resetPassword = () => {
        setError('')
        setSuccess('')
        if (password == '' || confirmpassword=='') {
            setError('All fields are required')
            return;
        }
        if (password !=  confirmpassword) {
            setError('Passwords mismatch')
            return;
        }

        setSpin('grow')
            var token=sessionStorage.getItem('mytoken', false)
                const config = {
            headers: { Authorization: `Bearer ${token}` }
                };
        var postData = {
      email: email,
      
        };
        
        axios.put(url + 'wellness/user/complete-reset',{password,token:id,email})
        // axios.put(url + 'auth/complete-reset',{password,id,email})
            .then(res => {
                console.log(res)
                setSuccess(res.data.message)
            
            }).catch(e => {
                // setError('We didn\'t find your email in the system Kindly create an account')
                console.log(e.response)
                setError(e.response.data.message)
            
            }).then(r => {
                setSpin('false')
            
        })
        
    }
    return (<div className="container" style={{ height: '100vh', color:'black' }}>
        <br />
            <br />
        <div className="row">
           
            
            <div className="col-sm-3"></div>
            <div className="col-sm-6">

                <Card style={{backgroundImage: `url("https://app-test.docsun.health/img/bg.9cd18d37.jpg")`, color:'white'}}>
                    <Card.Header ><div className="row">
                        <div className="col-sm-7">
                            <Image size='small' src='../../assets/Webappimages16/Logo.png' /> 
                            {/* <img src='../../assets/Webappimages16/Logo.png' class="responsive" /> */}
                            
                        </div>
                        <div className="col-sm-4">
                            <span className="float-right">Reset Password</span>

                        </div>
                    </div></Card.Header>
                    <Card.Body>
                        <div className="row">
                        <div className="col-sm-12">
                        <Spinner animation={spin} variant="primary" />
                        {error ? <>
                            <div className="alert alert-danger">
                            {error}
                            </div>
                        </> : <></>}
                        {success ? <>
                            <div className="alert alert-info">
                            {success}
                            </div>
                        </> : <></>}
                        
                        </div>
                    </div>
                    
                        {/* <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label style={{color:'black'}}>Email</label>
                                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)}
                                    className="form-control" />
                                </div>
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label style={{color:'black'}}>Password</label>
                                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)}
                        className="form-control" placeholder='New Password' />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label style={{color:'black'}}>Confirm Password</label>
                                    <input type="password" value={confirmpassword} onChange={(e) => setConfirmPassword(e.target.value)}
                        className="form-control" placeholder='New Password' />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-4">
                                <Button variant="primary" onClick={resetPassword }>Reset Password</Button>
                            </div>
                        </div>
                </Card.Body>
                </Card>

            </div>
            <div className="col-sm-3"></div>
        </div>
    </div>    );
}
 
export default CompleteReset;