import moment from 'moment';
import React,{useContext, useEffect, useState} from 'react';
import { Card, Button, ButtonGroup, Modal, Spinner } from 'react-bootstrap'
import { NotificationContainer } from 'react-notifications';
import { NotificationManager } from 'react-notifications';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import DashboardDiagnosis from '../DashboardDiagnosisWellness';
import { Button as Button2 } from 'react-bootstrap';
// import { Modal } from 'semantic-ui-react';
import Profile from './nav/profile';
import HeartRateChart from './charts/BloodPressureChart';
import { QuestionContext } from '../../App';
import url from '../url';
import axios from 'axios';
// import Spo2Rate from './charts/HeartRateChart';
import Chart from "react-google-charts";
import CommonChart from './charts/CommonChart';
import { Trans, useTranslation } from 'react-i18next';


const TemperatureRate = ({ sentdata }) => {
    const { t, i18n } = useTranslation()
    const [redirect, setRedirect] = useState(false)
    const [showtarget, setShowTarget] = useState(false);
    const [showmanually, setShowManually] = useState(false);
    const [showchart, setShowChart] = useState(false);
    const [target, setTarget] = useState(0)
    const [date, setDate]=useState('')
    const [heartrate, setHeartRate] = useState('')
    const [spin, setSpin]=useState('false')
    const [goal, setGoal] = useState({})
    const [chartdata, setChartDate] = useState([]);

  const handleCloseTarget = () => setShowTarget(false);
    const handleShowTarget = () => setShowTarget(true);
    
  const handleCloseManually = () => setShowManually(false);
    const handleShowManually = () => setShowManually(true);

  const handleCloseChart = () => setShowChart(false);
    const handleShowChart = () => setShowChart(true);

    const { initialgoalstate, dispatGoals } = useContext(QuestionContext)
    
    // console.log('initialgoalstate.blood_pressure')
    

    
    // alert('hey')
    
    useEffect(() => {
        fetchMetrics("Daily")
        const lastItem = initialgoalstate[0]
console.log('Goals')
console.log(lastItem)
        setTarget(lastItem.blood_sugar)
        setGoal(lastItem)
        // console.log('diagnosis')
        // console.log(lastItem)
        
    }, [])
    
    const saveTarget = () => {
    
        setSpin('border')
        
            var token=sessionStorage.getItem('mytoken', false)
                const config = {
            headers: { Authorization: `Bearer ${token}` }
                };
        var postData = {
      blood_sugar: target,
     
        };

        // alert(goal.id)
        
        axios.put(url + 'wellness/goals/' + goal.id, postData, config)
            .then(res => {
                // console.log(res)
                NotificationManager.success("Goal saved successfully")
                setShowTarget(false)

            
            }).catch(e => {

                console.log(e.message)
                NotificationManager.warning(e.message)
            
            }).then(r => {
            setSpin('false')
        })

    }
        
    const fetchMetrics = (duration) => {

        console.log(duration)
        var token=sessionStorage.getItem('mytoken', false)
                const config = {
            headers: { Authorization: `Bearer ${token}` }
                };
        const postdata = {
            frequency: duration
            
        }
        axios.get(url + 'wellness/metric-history?frequency='+duration+'&vital_sign=blood_pressure_range',config)
            .then(res => {
                console.log(res)
                setChartDate(res.data.result)
            
            }).catch(e => {
            
            }).then(r => {
            
        })
        
    }
    
    
    return (<div className="containerq">
        {redirect ? (<>
            <Redirect to="/question2" />
            
            

      </>) : (<></>)}
        
        <div className="row">
            <NotificationContainer />
            
            <div className="col-sm-12">
                {/* <Card style={{ border: 'rounded-pill', borderRadius: "30px" }} >
                    <Card.Body> */}
                        
              <div className="col-sm-3" />
                        
                        <br />
                        
                       
                {/* <hr /> */}
                <Spinner animation={spin} />
                        <div className=" alert text-white darkbluebackground">
                            <div className="row">
                                
                            <div className="col-12 text-center">
                               <h4> {t('wellness.pages.home.blood_sugar')}</h4>

                            </div>
                            </div>
                           
                           
                        </div>
                <div className="alert" style={{ background: '#ebf4fa' }}>
                    
                    <div className="row border" >
                        <div className="col-sm-5 border2">
                            {/* Left Part */}
                            
                            <div className="row">
                                
                                <table>
                                    <tr>
                                        <td>
                                            <div className="col-sm-2">
                                <img src="assets/wellness/new/IconsTemperature.png"  height="60px"  alt="Heart rate" />
                            </div>
                            
                                            
                                        </td>
                                        <td>
                                            {/* <div className="col-sm-1">
                                

                                <Button2 variant="link" as={Link} size="lg" to="#" ><i class="fa fa-caret-left" aria-hidden="true"></i></Button2>

                            </div> */}

                                        </td>
                                        <td>
                                            <div className="col-sm-8 text-center">
                                    
                                    <h4>{moment(sentdata.created_at).format('dddd, MMM DD')}</h4>
<h3 style={{color:'#79d5f7'}}><bold>{sentdata.blood_sugar_range}  </bold></h3>
                                

                                

                            </div>

                                        </td>
                                        
                                        <td>
                                            {/* <div className="col-sm-1">
                                

                                <Button2 variant="link" as={Link} size="lg" to="#" ><i class="fa fa-caret-right" aria-hidden="true"></i></Button2>

                            </div> */}

                                        </td>
                                    </tr>
                                </table>

                            
                            
                            

                            

                            </div>
                            <br />
                            
                                    <div className="row">
                            <div className="col-sm-12">
                             {/* <center>   <button className="curveresults" style={{color:'black', background:'#white'}} onClick={handleShowManually}> <i class="fa fa-plus-circle" aria-hidden="true"></i>Add Manually</button> </center> */}
                            </div>

                        </div>
                                <br />

                            <div className="row ">
                                <div className="col-sm-12">
                                    <div className=" alert text-white text-center darkbluebackground">
                            
                                
                            <div className="col-12">
                               <h4>  {t('wellness.pages.home.health_goals')} </h4>

                            </div>
                            
                           
                           
                        </div>
                                </div>
                            </div>
                            <div className="row" style={{paddingLeft:'12px'}}>

                                <div className="col-sm-8" >
                                    <div className="row curveresults">

                                        <table>
                                            <tr>
                                                <td>
                                                    <div className="col-sm-2">
                                         {t('wellness.pages.home.target')}
                                        
                                    </div>
                                                    
                                                </td>
                                                <td className="roundedtd ">
                                                    <div className="col-sm-6 text-white ">
                                        {target}&nbsp;{goal.blood_sugar_unit}

                                    </div>

                                                </td>
                                            </tr>
                                        </table>
                                    
                                    
                                </div>

                                </div>
                                
                                <div className="col-sm-3">
                              <center>  <button className=" button darkbluebackground"  onClick={handleShowTarget}>{t('wellness.pages.home.edit')}</button> </center>
                            </div>
                            </div>
                        </div>
                        {/* <div className="col-sm-1"> <div class="line"></div></div> */}
                        {/* <div class="line"></div> */}
                        
                        <div className="col-sm-5  " style={{paddingLeft:'30px'}}>
                            {/* Left Part */}
                            <div className="row">

                                <table>
                                    <tr>
                                        <td>
                                            <div className="col-sm-10 text-center">
                               <h4 style={{whiteSpace:'nowrap'}}>  {t('wellness.pages.home.metric_history')}</h4>
                                </div>
                                            
                                        </td>
                                        <td>
                                            <div className="col-sm-2 text-center">
                            
                                <ButtonGroup size="lg" className="mb-2">
                                    <Button variant='outline-primary' onClick={()=>fetchMetrics('daily')} >{t('wellness.pages.home.daily')}</Button>
                                        <Button variant='outline-primary' onClick={() => fetchMetrics('weekly')}>{t('wellness.pages.home.weekly')}</Button>
                                    <Button variant="outline-primary" onClick={() => fetchMetrics('monthly')}>{t('wellness.pages.home.month')}</Button>
                                    
                                </ButtonGroup>
  
                                
                                
                            
                        </div>

                                        </td>
                                    </tr>

                                </table>
                            
                                
                            </div>
                            <div className="row">
                                {/* <div className="col-sm-12"> */}
                                    {chartdata.length > 1 ? <>
                                        <Chart
  width={'600px'}
  height={'550px'}
  chartType="LineChart"
  loader={<div>Loading Chart</div>}
  data={chartdata}
  options={{
    hAxis: {
      title: 'Date',
    },
    vAxis: {
      title: 'Time',
      },
    intervals: { style: 'sticks' },
  }}
  rootProps={{ 'data-testid': '1' }}
/>
                                    
                                    </> : <>
                                            No data
                                    
                                    </>}

                                    

                                    
                                    
                                    
                                {/* </div> */}
                            </div>
                        
                            

                        </div>
                    </div>

                        </div>
                        
                        
                        <br />
                        
                        
                        
                        
                        
                {/* </Card.Body>
                </Card> */}
            </div>
            

            
        </div>
        {/* Modals. Edit target */}
        
        <Modal show={showtarget} onHide={handleCloseTarget} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t('wellness.pages.home.edit_target')}</Modal.Title>
        </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-sm-10">
                        <div className="form-group">
                            
                            <span class="label">{t('wellness.pages.home.target')}</span>
                            <input type="text" className="form-control" value={target} onChange={(e)=>setTarget(e.target.value)} />
                            
                        </div>
                    </div>
                </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseTarget}>
            Close
          </Button>
          <Button variant="primary" onClick={saveTarget}>
            Save Changes
          </Button>
        </Modal.Footer>
        </Modal>
        {/* Add manually */}

        <Modal show={showmanually} onHide={handleCloseManually} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Manually</Modal.Title>
        </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-sm-5">
                        <div className="form-group">
                            
                            <span class="label">Heart Rate</span>
                            <input type="number" className="form-control" value={heartrate} onChange={(e)=>setHeartRate(e.target.value)} />
                            
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            
                            <span class="label">Date</span>
                            <input type="date" className="form-control" value={date} onChange={(e)=>setDate(e.target.value)} />
                            
                        </div>
                    </div>
                </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseManually}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCloseManually}>
            Add
          </Button>
        </Modal.Footer>
        </Modal>
        {/* Chart */}
        <Modal show={showchart} onHide={handleCloseChart} centered>
        {/* <Modal.Header closeButton>
          <Modal.Title>Edit Target</Modal.Title>
        </Modal.Header> */}
            <Modal.Body>
                {/* <HeartRateChart /> */}
        </Modal.Body>
        
        </Modal>
        


    </div>);
}
 
export default TemperatureRate;