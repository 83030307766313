import axios from 'axios';
import React,{useEffect, useState} from 'react';
import { Card, Button, Spinner } from 'react-bootstrap'
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import url from '../url';
import { NotificationContainer, NotificationManager } from 'react-notifications';

const Notification = () => {
    const [redirect, setRedirect] = useState(false)
    const [notifications, setNotifications]=useState([])
    const [spin, setSpin]=useState('false')
    
    useEffect(() => {
        fetchnotification()
        
        
    }, [])

    const fetchnotification = () => {
        setSpin('border')
        var token=sessionStorage.getItem('mytoken', false)
                const config = {
            headers: { Authorization: `Bearer ${token}` }
                };
        
        axios.get(url + 'wellness/notifications', config)
            .then(res => {

                // console.log(res)
                setNotifications(res.data.result.data)
            
            }).catch(e => {
                NotificationManager.warning('Message', e.message, 3000);
            
            }).then(r => {
                setSpin('false')
            
        })
    }

    const markAdRead = (id) => {
        // alert(id)
        var token=sessionStorage.getItem('mytoken', false)
                const config = {
            headers: { Authorization: `Bearer ${token}` }
                };
        var postData = {
      notification_id: id,
      
        };
        
        setSpin('border')
        axios.post(url + 'wellness/notification/mark-as-read/'+id, postData, config)
            .then(res => {

                console.log(res)
            
            }).catch(e => {
                NotificationManager.warning('Message', e.message, 3000);
            
            }).then(r => {
                setSpin(false)
            
        })
        
    }
    const markAllAsRead = () => {
        
        var token=sessionStorage.getItem('mytoken', false)
                const config = {
            headers: { Authorization: `Bearer ${token}` }
                };
    //     var postData = {
    //   notification_id: id,
      
    //     };
        
        setSpin('border')
        axios.post(url + 'wellness/notification/mark-all-as-read', config)
            .then(res => {

                console.log(res)
            
            }).catch(e => {
                NotificationManager.warning('Message', e.message, 3000);
                console.log(e)
            
            }).then(r => {
                setSpin(false)
            
        })
        
    }
    
    const rendernotifications =  notifications.map((notification, index) => (
        <span key={index}>
        <div className="row">
                    <div className="col-sm-6">
                        <img src='../../assets/wellness/avatar.png' class="img-thumnail" />
                        
                    </div>
                    
                <div className="col-sm-6">
                    
                    {notification.message.length > 300 ? <>
                        {notification.message.slice(0,300)} ....
                        
                    </> : <>
                            {notification.message}
                    </>}
                    

                    
                    <div className="row">
                        
                        <table style={{paddingTop:'80px'}}>
                            <tr>
                                <td>

                                    {notification.message.length > 300 ? <>
                                         <div className="col-sm-4">
                                        {/* <button className="btn btn-primary btn-sm" onClick={(e) => markAdRead(notification.id)} >Read</button> */}
                                        <Button as={Link} to={'notification/'+notification.id }>Read</Button>
                        </div>
                                    </> : <>
                                    
                                    </>}
                                   
                                    
                                </td>
                                <td>
                                    {notification.read == 0 ? <>
                        <div className="col-sm-8">
                            <button className="btn btn-primary btn-sm" onClick={(e)=>markAdRead(notification.id)} >Mark as read</button>
                        </div>
                        </> : <>
                            {/* <span >Read</span> */}
                            {/* <span class="badge bg-secondary">Read</span> */}
                    </>}

                                </td>
                            </tr>
                        </table>
                        
                        
                    </div>
                    
                    

                    </div>
                </div>
                <div class="line"></div>
            <hr />
            </span>
        
    ))
    return (<div className="container">
        {redirect ? (<>
            <Redirect to="/question2" />
            
            

        </>) : (<></>)}
        <NotificationContainer />
        
        <Spinner animation={spin} />
        
        <div className="row">
            <div className="col-sm-2" />
            <div className="col-sm-8">
                {/* <Card style={{ border: 'rounded-pill', borderRadius: "30px" }} >
                    <Card.Body> */}
                      
                       
                        <div className="text-center">
                            <h4>You have {notifications.length} notifications</h4> <button className="btn btn-primary" onClick={()=>markAllAsRead()}>Mark al as Read</button>
                          
                </div>
                <hr />
                
                {rendernotifications}

                        
                        
                        


                
               
                {/* </Card.Body>
                </Card> */}
            </div>
            

            
        </div>
        


    </div>);
}
 
export default Notification;