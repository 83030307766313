import React, { useState } from 'react';

const SetGoal = () => {
    const [bloodpressure, setBloodPressure]=useState('')
    const [bloodpressureunit, setBloodPressureUnit]=useState('')
    const [bloodsugar, setSugar]=useState('')
    const [bloodsugarunit, setSugarUnit]=useState('')
    const [spo2, setSpo2]=useState('')
    const [temperature, setTemperature]=useState('')
    const [heartrate, setHeartRate]=useState('')
    const [respiratoryrate, setRespiratoryRate]=useState('')
    const [bmi, setBMI]=useState('')
    const [temperatureunit, setTemperatureUnit] = useState('')
    
    const saveGoals = () => {
        
    }
    return (
        <>
        <div className="row">
            <div className="col-sm-4">
                <div className="form-group">
                    
                    <span class="label">Blood Pressure</span>
                    <input type="text" className="form-control" value={bloodpressure} onClick={(e)=>setBloodPressure(e.target.value)} placeholder="blood pressure" />
                    
                </div>
            </div>
            <div className="col-sm-4">
                <div className="form-group">
                    
                    <span class="label">Blood Sugar</span>
                    <input type="text" className="form-control" value={bloodsugar} onClick={(e)=>setSugar(e.target.value)} placeholder="blood Sugar" />
                    
                </div>
            </div>
            <div className="col-sm-4">
                <div className="form-group">
                    
                    <span class="label">Spo2</span>
                    <input type="text" className="form-control" value={spo2} onClick={(e)=>setSpo2(e.target.value)} placeholder="Spo2" />
                    
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-sm-4">
                <div className="form-group">
                    
                    <span class="label">Temperature</span>
                    <input type="text" className="form-control" value={temperature} onClick={(e)=>setTemperature(e.target.value)} placeholder="Temperature" />
                    
                </div>
            </div>
            <div className="col-sm-4">
                <div className="form-group">
                    
                    <span class="label">Heart rate</span>
                    <input type="text" className="form-control" value={heartrate} onClick={(e)=>setHeartRate(e.target.value)} placeholder="Heart rate" />
                    
                </div>
            </div>
            <div className="col-sm-4">
                <div className="form-group">
                    
                    <span class="label">Respiration Rate</span>
                    <input type="text" className="form-control" value={respiratoryrate} onClick={(e)=>setRespiratoryRate(e.target.value)} placeholder="Respiration Rate" />
                    
                </div>
            </div>
            </div>
        <div className="row">
            <div className="col-sm-4">
                <div className="form-group">
                    
                    <span class="label">BMI</span>
                    <input type="text" className="form-control" value={bmi} onClick={(e)=>setBMI(e.target.value)} placeholder="BMI" />
                    
                </div>
            </div>
            
            
            </div>
            </>
     );
}
 
export default SetGoal;