import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import url from './url';
import { Card, Spinner } from 'react-bootstrap'
import { Link, Redirect } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import moment from 'moment'
import { Trans, useTranslation } from 'react-i18next';
import { LanguageContext } from '../App';

import {

    Image

} from 'semantic-ui-react'
const DashboardDiagnosis = () => {
    const { t, i18n } = useTranslation()
    const languagecontext = useContext(LanguageContext)
    const [spin, setSpin] = useState('false')
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')
    const [diagnosis, setDiagnosis] = useState({})
    const [empty, setEmpty] = useState(false)
    const [language, setLanguage] = useState('')
    const [message, setMessage] = useState('')
    const [humanfigure, setHumanFigure] = useState('assets/Webappimages16/')
    // const { questionstate, dispatchQuestion } = useContext(QuestionContext)
    const [redirect, setRedirect]= useState(false)
    

    useEffect(() => {
        // console.log(languagecontext.languagestate)
        if (languagecontext.languagestate == 'en') {
            setLanguage('en')

        } else {
            setLanguage('zh_TW')

        }

        fetchDiagnosis()


    }, [languagecontext.languagestate])
    useEffect(() => {
        // console.log(languagecontext.languagestate)
        // if (languagecontext.languagestate == 'en') {
        //     setLanguage('en')

        // } else {
        //     setLanguage('zh_TW')

        // }

        fetchDiagnosis()


    }, [language])
    // Fetch updates every 10 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            //   if (languagecontext.languagestate == 'en') {
            //         setLanguage('en')

            //     } else {
            //         setLanguage('zh_TW')

            //     }
            setLanguage('language')
        }, 10000);
        return () => clearInterval(interval);
    }, []);
    const fetchDiagnosis = () => {

        setSpin('border')
        setMessage(t('responses.fetch_results'))

        if (languagecontext.languagestate == 'en') {
            setLanguage('en')

        } else {
            setLanguage('zh_TW')

        }
        var token = sessionStorage.getItem('mytoken', false)
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                language: languagecontext.languagestate
            }
        };


        axios.get(url + 'wellness/user/diagnosis', config)
            .then(res => {
                // console.log(res.data)
                setSuccess(res.data.message)

                let array = res.data.result;
                if (array === undefined || array.length == 0) {
                    setEmpty(true)

                } else {
                    const lastItem = array[0]
                    // console.log(lastItem);
                    setDiagnosis(lastItem)

                }


            }).catch(e => {
                // setError('We didn\'t find your email in the system Kindly create an account')
                // console.log(e)
                // setError(e.message+'hello world')
                // dispatchQuestion({ type: 'session_expired', value: true })
                if (e.message == 'Request failed with status code 401') {
                    setRedirect(true)
                    
                }

            }).then(r => {
                setSpin('false')
                setMessage('')

            })
    }
    const renderresultsimage = (healthstatus) => {
        if (healthstatus == 0) {
            // setHumanFigure('assets/web2images/goresults.png')
            return (
                <img src="assets/green.png" height="25"
                    width="25"
                    alt="green" />

            )


        } else if (healthstatus == 1) {
            // setHumanFigure('assets/web2images/warningresults.png')
            return <img src="assets/yellow.png" height="25"
                width="25"
                alt="yello" />


        } else {
            // setHumanFigure('assets/web2images/warningresults.png')
            return <img src="assets/red.png" height="25"
                width="25"
                alt="red" />
        }

    }
        const renderresultscolor = (healthstatus) => {
        if (healthstatus == 0) {
            // setHumanFigure('assets/web2images/goresults.png')
            
            return "go-results"


        } else if (healthstatus == 1) {
            // setHumanFigure('assets/web2images/warningresults.png')
            return "warning-results"


        } else {
            // setHumanFigure('assets/web2images/warningresults.png')
            return "red-results"
        }

        }
    const renderresultsicon = (healthstatus) => {
        if (healthstatus == 0) {
            // setHumanFigure('assets/web2images/goresults.png')
            
            return "Go.png"


        } else if (healthstatus == 1) {
            // setHumanFigure('assets/web2images/warningresults.png')
            return "Caution.png"


        } else {
            // setHumanFigure('assets/web2images/warningresults.png')
            return "Stop.png"
        }

    }
    return (<>
        {/* {empty ? <>
            <br />
            <br />
            
            <div className="row">
                <div className="col-sm-12">
                    <span className="alert alert-info">
                        {t('diagnosis_results.no_results')}
                    </span>
                </div>
            </div>
            
        </> :
            <> */}
        {/* {language} */}
        {redirect ? <>
      {/* <Router> */}
            <Redirect to="/" />
            

            

      {/* </Router> */}
      
    </> : <></>}
        <div className="row">
            <div className="col-sm-12">
                <Spinner animation={spin} variant="primary" /> {message}
                {error ? <>
                    <div className="alert alert-danger">
                        {error}
                    </div>
                </> : <></>}

            </div>
        </div>
        <Card
            className="card p-1 bg-transparent"
            // style={{ border: 'none' }}
             >
            {/* <div className="row">
                <div className="col-sm-3" />
                <div className="col-sm-5" style={{ background: '#173f82' }}>
                    <Image size='small' src='../assets/docsun.png' sstyle={{ height: 50, paddingLeft: "-20px" }} />
                </div>
                <div className="col-sm-3" />
            </div> */}

            <Card.Body>


                {diagnosis.created_at != null ? <>
                    <p>
                        <b> {moment(diagnosis.created_at).format('YYYY-MM-DD h:mm:ss a')}</b>
                    </p>
                    {/* <p> */}
                    {/* <strong>{t('diagnosis_results.results')}:</strong>
                    {renderresultsimage(diagnosis.health_status)} */}

                    
                    {/* </p> */}
                   <p> <h4> {t('diagnosis_results.health_report')}</h4> </p>
                    {diagnosis.status == 3 ? <div>
                        {/* failed */}
                        <em class="bold">
                            {t('diagnosis_results.failed_results.title')}
                        </em>

                        <ol>
                            <li>{t('diagnosis_results.failed_results.no1')}</li>
                            <li>{t('diagnosis_results.failed_results.no2')}</li>
                            <li>{t('diagnosis_results.failed_results.no3')}</li>
                        </ol>




                    </div> : <>
                        {/* SUccessful */}
                        {/* <p> */}
                        {/* <b>{t('diagnosis_results.viral_signs')}</b> */}
                            {/* </p > */}
                            


                    <p className={renderresultscolor(diagnosis.health_status)}>
                        {diagnosis.diagnosis}
                    </p>

                        <div className="row">
                            <div className="col-sm-9">
                                    <p>
                                        <div className="curveresults">
                                           
                                            <div className="rowe">
                                                 <table>
                                                    <td>
                                                        <div className="col-sm-1s" >
                                                    <img src="assets/Webappimages16/Icon01.png" height="60px" width="60px" alt="Sp02" />
                                                
                                                </div>

                                                </td>
                                                    <td>
                                                        <div className="col-sm-10d" style={{paddingLeft:'20px'}}>
                                               <b >{t('diagnosis_results.spo2')}:</b> <br />
                                                <span className={renderresultscolor(diagnosis.health_status)} >{diagnosis.spo2} </span><br />
                                                    
                                                </div>
                                                    
                                                </td>
                                            </table>
                                                
                                                

                                            </div>
                                        </div>
                                        <div class="line"></div>
                                        <div className="curveresults">
                                            <div className="rowe">

                                                <table>
                                                    <td>
                                                        <div className="col-sm-1w" >
                                                    <img src="assets/Webappimages16/Icon02.png" height="60px" width="60px" alt="Breathe" />
                                                
                                                </div>

                                                    </td>
                                                    <td style={{ paddingLeft: '20px' }}>
                                                        <div className="col-sm-10w">
                                                    <b>{t('diagnosis_results.breathing_rate')}:
                                            <br /></b> <span className={renderresultscolor(diagnosis.health_status)}>{diagnosis.respiration_rate} </span> <br />

                                                </div>
                                                    </td>
                                                </table>
                                                
                                                

                                            </div>
                                        </div>
                                        
                                        
                                        <div class="line"></div>
                                        <div className="curveresults">
                                            
                                            <div className="rowt">
                                                <table>
                                                    <td>
                                                        <div className="col-sm-3t">
                                                    <img src="assets/Webappimages16/Icon03.png" height="60px" width="60px" alt="Heart rate" />
                                                </div>

                                                </td>
                                                    <td style={{ paddingLeft: '20px' }}>
                                                        <div className="col-sm-8h">
                                                    <b> {t('diagnosis_results.heart_rate')}:</b> <br />
                                        <span className={renderresultscolor(diagnosis.health_status)} >{diagnosis.heart_rate} </span><br />

                                                </div>
                                                    
                                                </td>
                                            </table>
                                                
                                                
                                            </div>
                                            
                                            
                                        </div>
                                        <div class="line"></div>
                                        <div className="curveresults">
                                            
                                            <div className="rowe">
                                                <table>
                                                    <td>
                                                        <div className="col-sm-3f" >
                                                    <img src="assets/Webappimages16/Icon04.png" height="60px" width="60px" alt="Temperature" />
                                                
                                                </div>

                                                </td>
                                                    <td style={{ paddingLeft: '20px' }}>
                                                        <div className="col-sm-8f">
                                                    
                                                <b>{t('diagnosis_results.temperature')}: <br />
                                            </b> <span className={renderresultscolor(diagnosis.health_status)} > {diagnosis.temperature == "" ? <> n/a </> : <>{ diagnosis.temperature}</>}</span> <br />
                                                    
                                                </div>
                                                    
                                                </td>
                                            </table>
                                                
                                                

                                            </div>
                                             
                                        </div>
                                        <div class="line"></div>
                                        <div className="curveresults">
                                            
                                            <div className="rowr">
                                                <table>
                                                    <td>
                                                        <div className="col-sm-3g">
                                                    <img src="assets/Webappimages16/Icon05.png" height="60px" width="60px" lat="eye" />

                                                </div>

                                                </td>
                                                    <td style={{ paddingLeft: '20px' }}>
                                                        <div className="col-sm-8r">
                                                    <b>{t('diagnosis_results.eye_coloration')}: </b><br>
                                        </br><span className={renderresultscolor(diagnosis.health_status)}>{diagnosis.eye_coloration}</span> <br />

                                                </div>
                                                    
                                                </td>
                                            </table>
                                                
                                                
                                            </div>
                                        
                                        
                                        </div>
                                        <div class="line"></div>

                                        

                                        
                                        
                                        


                                    {/* <div  className="curveresults">
                                    <div className="row">
                                                <div className="col-sm-2" style={{ paddingTop: '5px' }}>
                                                <img src="assets/web2images/Iconbloodpressure.png" height="30px" width="30px" lat="eye" /> 
                                                </div>
                                                <div className="col-sm-8">
                                                <b style={{ paddingLeft: '5px', color: 'white' }}> {t('diagnosis_results.blood_pressure')}:
                                        </b> <br /><span style={{ color: 'white', paddingLeft: '34px' }}>{diagnosis.blood_pressure_code} {diagnosis.blood_pressure_range}</span> <br />

                                                </div>
                                            </div>
                                        
                                        
                                    </div>
                                    <div  className="curveresults">
                                    <div className="row">
                                                    <div className="col-sm-2" style={{ paddingTop: '5px' }}>
                                                    <img src="assets/web2images/Iconsugarlevel.png" height="30px" width="30px" lat="eye" /> 
                                                    </div>
                                                    <div className="col-sm-8">
                                                    <b style={{ paddingLeft: '5px', color: 'white' }}> {t('diagnosis_results.blood_sugar')}: <br />
                                        </b><span style={{ color: 'white', paddingLeft: '34px' }}>{diagnosis.blood_sugar_code} {diagnosis.blood_sugar_range}</span> <br />
                                                        
                                                    </div>
                                                </div>

                                        
                                        
                                    </div> */}
                                </p>
                            </div>
                            <div className="col-sm-3 d-none d-md-block" style={{ color: 'white', paddingTop:'110px' }}>
                                <img src={humanfigure+renderresultsicon(diagnosis.health_status)} alt="Figure" height="100px" />
                            </div>
                        </div>

                    </>}





                    

                </> : <>
                    {t('diagnosis_results.no_results')}

                </>}
            </Card.Body>
        </Card>
        <br />
        <span style={{ paddingRight: '200px' }}>
                        <Button
                            // className="btn btn-sm " 
                            style={{ background: "#1d7ced", color: "#ffffff", borderColor: "#28696D", height: "33px" }}
                            as={Link} to="/dashboard/dianosis">{t('diagnosis_results.all_diagnosis_button')}</Button>
                    </span>
        {/* <span style={{ paddingRight: '200px' }}>
                        <Button
                            // className="btn btn-sm " 
                            style={{ background: "#1d7ced", color: "#ffffff", borderColor: "#28696D", height: "33px" }}
                            as={Link} to="/dashboard/dianosis">{t('diagnosis_results.all_diagnosis_button')}</Button>
                    </span> */}

                    <a href="/#home"
                        // style={{ background: "#1d7ced", color: "#ffffff", borderColor: "#28696D", height: "33px" }}
                        style={{ background: "#1d7ced", color: "#ffffff", borderColor: "#28696D", padding: '10px' }}
                    >{t('diagnosis_results.wellnes_button')}</a>
    </>

        //     }



        //   </>
    );
}

export default DashboardDiagnosis;