import React,{useState, useContext} from 'react';
import { Card, Button, ToggleButton, ButtonGroup,ToggleButtonGroup } from 'react-bootstrap'
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import ToggleButtonExample from './Testbuttons';
import { QuestionContext } from '../../App';
import { Trans, useTranslation } from 'react-i18next';

const SurgeryHistory = () => {
     const {questionstate,dispatchQuestion,surgeryhistory, dispatRecentHistory} = useContext(QuestionContext);
    const [redirect, setRedirect] = useState(false)
    const [highbloodpressure, setHighBloodPressure] = useState(false);
    const [heartattack, setHeartAttack] = useState(false);
    const [highcholestorol, setHighChelosterol] = useState(false);
    const [diabeties, setDiabeties] = useState(false);
    const [cancer, setCancer] = useState(false);
    const [heartfailure, setHeartFailure] = useState(false);
    const [arrhythmia, setArrhythmia] = useState(false);
    const [stroke, setStroke] = useState(false);
    const [clothingdisorder, setClothingDisorder] = useState(false);
    const { t, i18n } = useTranslation()
    
    const latexfn = (e) => {
        // setLatex(e.currentTarget.checked)
    }
    return (<div className="container">
        {redirect ? (<>
            <Redirect to="/question2" />
            
            

        </>) : (<></>)}

        
        {/* <ToggleButtonExample /> */}
        
        
        <div className="row">
            <div className="col-sm-3" />
            <div className="col-sm-6">
                {/* <Card style={{ border: 'rounded-pill', borderRadius: "30px" }} >
                    <Card.Body> */}
                        
                <div className="darkbluebackground row alert text-white ">
                    
                    <table>
                        <tr>
                            <td>
                                <div className="col-sm-2">
                        <Button variant="link" as={Link} size="lg" to="/question3" style={{ color: 'white', fontWeight: '600' }} ><i class="fa fa-arrow-left" aria-hidden="true"></i></Button>
                    </div>
                                
                            </td>
                            <td>
<div className="col-sm-6" >
                            <div className="row">
                                
                            <div className="col-12">
                           <h4> {t('wellness.optional.optional')}</h4>

                            </div>
                            </div>
                           
                           
                        </div>
                                
                            </td>
                        </tr>
                    </table>
                    
            
        </div>

                    
                        <h4 className="text-center">{t('wellness.optional.surgery_history')}</h4>
                       
                        <hr />

                        
                        <table>
                            <tr>
                                <td>
                                    <ToggleButton
                                    className="mb-2"
                                    id="iodine"
                                    type="checkbox"
                                    variant="outline-primary"
                                    checked={surgeryhistory.jointreplacement}
                                    value="1"
                                    onChange={(e) => dispatRecentHistory({type:"jointreplacement"})}
                                >
                                      &nbsp; {t('wellness.surgery_history.joint_replacement')}
                                    </ToggleButton>
                                    
                                </td>
                                <td>
                                    <ToggleButton
                                    className="mb-2"
                                    id="latex"
                                    type="checkbox"
                                    variant="outline-primary"
                                    checked={surgeryhistory.circumcision}
                                    value="1"
                                    onChange={(e) => dispatRecentHistory({type:"circumcision"})}
                                >
                                    &nbsp; {t('wellness.surgery_history.circumcision')}
                                </ToggleButton>

                                </td>

                            </tr>
                            <tr>
                                <td>
                                    <ToggleButton
                                    className="mb-1"
                                    id="gluten"
                                    type="checkbox"
                                    variant="outline-primary"
                                    checked={surgeryhistory.brokenbonerepair}
                                    value="1"
                                    onChange={(e) =>dispatRecentHistory({type:"brokenbonerepair"}) }
                                >
                                       &nbsp;{t('wellness.surgery_history.broken_bone_repair')}
                                </ToggleButton>
                                    
                                </td>
                                <td>
                                    <ToggleButton
                                    className="mb-2"
                                    id="gluten"
                                    type="checkbox"
                                    variant="outline-primary"
                                    checked={surgeryhistory.sternprocedure}
                                    value="1"
                                    onChange={(e) => dispatRecentHistory({type:"sternprocedure"})}
                                >
                                       &nbsp;Stern procedure
                                </ToggleButton>

                                </td>

                            </tr>
                            <tr>
                                <td>
                                    <ToggleButton
                                    className="mb-3"
                                    id="gluten"
                                    type="checkbox"
                                    variant="outline-primary"
                                    checked={surgeryhistory.angioplastyandatherectomy}
                                    value="1"
                                    onChange={(e) => dispatRecentHistory({type:"angioplastyandatherectomy"})}
                                >
                                       &nbsp;Angioplasty and Atherectomy
                                </ToggleButton>
                                    
                                </td>
                                <td>
                                    <ToggleButton
                                    className="mb-2"
                                    id="gluten"
                                    type="checkbox"
                                    variant="outline-primary"
                                    checked={surgeryhistory.hysterectomy}
                                    value="1"
                                    onChange={(e) => dispatRecentHistory({type:"hysterectomy"})}
                                >
                                      &nbsp; Hysterectomy
                                </ToggleButton>

                                </td>

                            </tr>
                            <tr>
                                <td>
                                    <ToggleButton
                                    className="mb-2"
                                    id="gluten"
                                    type="checkbox"
                                    variant="outline-primary"
                                    checked={surgeryhistory.gallbladderremoval}
                                    value="1"
                                    onChange={(e) => dispatRecentHistory({type:"gallbladderremoval"})}
                                >
                                       &nbsp;Gallbladder Removal
                                </ToggleButton>
                                </td>
                                <td>
                                    <ToggleButton
                                    className="mb-2"
                                    id="gluten"
                                    type="checkbox"
                                    variant="outline-primary"
                                    checked={surgeryhistory.heartbypasssurgery}
                                    value="1"
                                    onChange={(e) => dispatRecentHistory({type:"heartbypasssurgery"})}
                                >
                                      &nbsp;Heart Bypass Surgery
                                </ToggleButton>

                                </td>
{console.log(surgeryhistory)}
                            </tr>
                            
                        </table>

                        
                        

                        <br />
                        

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label>If you can’t find one above,type below</label>
                            <textarea rows="4" className="form-control" onChange={(e)=>dispatchQuestion({type:'surgeryhistory_other', value:e.target.value})}>{ questionstate.surgeryhistory_other}</textarea>
                                </div>
                            </div>
                        </div>
                        
                        
                        
                        
                        
                        <div className="row" style={{paddingTop:'20px'}}>
                            <div className="col-sm-4">
                                
                                
                               
                            </div>
                            <div className="col-sm-6">
                                
                                <div>
                                    
                            <Button variant="link" as={Link} size="lg" to="/question3" style={{ color: '#1d7ced', fontWeight: '600' }} ><i class="fa fa-arrow-left" aria-hidden="true"></i></Button>
                            <br />
                            Back
                                    
                                </div>
                               
                            </div>
                            
                        </div>
                        


                <br />
                
                
                {/* <button class="btn btn-primary btn-block" type="button" onClick={()=>setRedirect(true)} width="100%">Save</button> */}
                {/* </Card.Body>
                </Card> */}
            </div>
            

            
        </div>
        


    </div>);
}
 
export default SurgeryHistory;