import axios from 'axios';
import moment from 'moment';
import React,{useEffect, useState} from 'react';
import { Card, Button } from 'react-bootstrap'
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import ShowNav from '../auth/ShowNav';
import DashboardDiagnosis from '../DashboardDiagnosisWellness';
import url from '../url';
import HeartRate from './HeartRate';
import Profile from './nav/profile';

const Home = () => {
    const [redirect, setRedirect] = useState(false)
    const [chart, setChart] = useState('')
    const [bio, setBio] = useState({})
    const [redirectprofile,setRedirectProfile]=useState(false)
    
    useEffect(() => {

        fetchBio()
        
    },[])

    
    
    const fetchBio = () => {
        var token = sessionStorage.getItem('mytoken', false)
        const config = {
            headers: { Authorization: `Bearer ${token}` }
            
        };
        
        // setSpin('border')
        axios.get(url+'wellness/user/profile', config)
            .then(res => {

                console.log(res)
            
            }).catch(e => {

                console.log(e.message)

                if (e.message == 'Request failed with status code 404') {
                    setRedirectProfile(true)
                }

            
        })

    }
    return (<div className="container1">
        <ShowNav />
        {redirect ? (<>
            <Redirect to="/question2" />
            
            

        </>) : (<></>)}
        {redirectprofile ? (<>
            <Redirect to="/profile-data" />
            
            

        </>) : (<></>)}
        {/* <Card>
                    <Card.Body> */}
        
        <div className="row">
            {/* <div className="col-sm-1" /> */}
            <div className="col-sm-12">
                {/* <Card style={{ border: 'rounded-pill', borderRadius: "30px" }} >
                    <Card.Body> */}

                        
                        
              
             
                        
                        <br />
                        {/* <div className="row">
                            <div className="col-sm-8">
                                <h4>Hi James</h4>
                               <small> {moment().format("Do MMM,  YYYY")}</small>

                            </div>
                            <div className="col-sm-3">
                                <table>
                                    <tr>
                                        <td>
                                            <img src='../assets/wellness/avatar.png' class="responsive" />
                                            
                                
                                        </td>
                                        <td>
                                             <Profile />
                                        </td>

                                    </tr>
                                </table>

                            </div>
                        </div> */}
                       
                

                    
                <div className="row">
                     
                    <center>
                        <div className="col-sm-12">
                            <hr />

                        </div>
                        <div className="col-sm-8 text-center">
                             
                                    <div className="row curveresults text-white darkbluebackground" style={{ padding: '7px',}}>
                                        
                                        <table>
                                            <tr>
                                                <td>
                                            <div className="col-sm-12 " >
                                              
                                                
                                       <span ><h4>Health Statistics</h4></span> 
                                        
                                    </div>
                                                    
                                                </td>
                                                
                                            </tr>
                                        </table>
                                    
                                    
                                    </div>
                            </div>
                            </center>
                </div>


                
                        <DashboardDiagnosis />
                {/* </Card.Body>
                </Card> */}
                {chart != '' ? <>
                    <HeartRate type={chart} />
                </> : <>
                </>}
                
            </div>
            

            
        </div>
        

{/* </Card.Body>
                    
                        </Card> */}
    </div>);
}
 
export default Home;