import axios from 'axios';
import React,{useEffect, useState} from 'react';
import { Card, Button, Spinner } from 'react-bootstrap'
import { Redirect, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import url from '../url';
import { NotificationContainer, NotificationManager } from 'react-notifications';

const NotificationDetails = () => {
    const [redirect, setRedirect] = useState(false)
    const [notification, setNotifications]=useState({})
    const [spin, setSpin] = useState('false')
    
    let { id } = useParams();
    
    
    useEffect(() => {
        fetchnotification()
        
        
    }, [])

    const fetchnotification = () => {
        setSpin('border')
        var token=sessionStorage.getItem('mytoken', false)
                const config = {
            headers: { Authorization: `Bearer ${token}` }
                };
        
        axios.get(url + 'wellness/notifications/'+id, config)
            .then(res => {

                console.log(res)
                setNotifications(res.data.result)
            
            }).catch(e => {
                NotificationManager.warning('Message', e.message, 3000);
            
            }).then(r => {
                setSpin('false')
            
        })
    }

       const markAdRead = (id) => {
        // alert(id)
        var token=sessionStorage.getItem('mytoken', false)
                const config = {
            headers: { Authorization: `Bearer ${token}` }
                };
        var postData = {
      notification_id: id,
      
        };
        
        setSpin('border')
        axios.post(url + 'wellness/notification/mark-as-read/'+id, postData, config)
            .then(res => {

                console.log(res)
            
            }).catch(e => {
                NotificationManager.warning('Message', e.message, 3000);
            
            }).then(r => {
                setSpin(false)
            
        })
        
    }
    
    
    
    return (<div className="container">
        {redirect ? (<>
            <Redirect to="/question2" />
            
            

        </>) : (<></>)}
        <NotificationContainer />
        
        <Spinner animation={spin} />

        {Object.keys(notification).length!=0 ? <>
            <div className="row">
            <div className="col-sm-2" />
            <div className="col-sm-8">
                {/* <Card style={{ border: 'rounded-pill', borderRadius: "30px" }} >
                    <Card.Body> */}
                      
                       
                        <div className="text-center">
                            {/* <h4>You have {notifications.length} notifications</h4> <button className="btn btn-primary" onClick={()=>markAllAsRead()}>Mark al as Read</button> */}
                          
                </div>
                <hr />
                
                <div className="row">
                    <div className="col-sm-6">
                        <img src='../../assets/wellness/avatar.png' class="img-thumnail" />
                        
                    </div>
                    
                    <div className="col-sm-6">
                    {notification.message}

                    
                    <div className="row">
                        
                        <table>
                            <tr>
                                <td>
                                    
                                    
                                </td>
                                <td>
                                    {notification.read == 0 ? <>
                        <div className="col-sm-8">
                            <button className="btn btn-primary btn-sm" onClick={(e)=>markAdRead(notification.id)} >Mark as read</button>
                        </div>
                        </> : <>
                            {/* <span >Read</span> */}
                            {/* <span class="badge bg-secondary">Read</span> */}
                    </>}

                                </td>
                            </tr>
                        </table>
                        
                        
                    </div>
                    
                    

                    </div>
                </div>

                        
                        
                        


                
               
                {/* </Card.Body>
                </Card> */}
            </div>
            

            
        </div>
        
        </> : <>
                
        </>}
        
        
        


    </div>);
}
 
export default NotificationDetails;