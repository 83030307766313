import axios from 'axios';
import React,{useEffect, useState, useContext} from 'react';
import { Card, Button, Spinner } from 'react-bootstrap'
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { LanguageContext } from '../../App';
import url from '../url';

const Resources = () => {
    const [redirect, setRedirect] = useState(false)

    const lang = useContext(LanguageContext);
    const [spin, setSpinner] = useState('false')
    const [articles, setArticles]=useState([])
    

    
    
    useEffect(() => {

        fetchWordpress()
        
    }, [lang.languagestate])
    
    const fetchWordpress = () => {
        setSpinner('border')
         var token=sessionStorage.getItem('mytoken', false)
                const config = {
            headers: { Authorization: `Bearer ${token}` }
                };
        var postData = {
      language: lang.languagestate,
      
        };
        
        axios.get(url + 'get-rss-wordpress', postData, config)
            .then(res => {
                console.log(res.data)
                setArticles(res.data.result)
            }).catch(e => {
                console.log(e)
            
            }).then(r => {
                setSpinner('false')
            
        })
    }

    const renderimage = (details) => {
        if (!details) {
            return 'https://i1.wp.com/docsun.health/wp-content/uploads/2021/08/baby-1151351_1920.jpg?fit=1920%2C1440&ssl=1'
        } else {
            return details.node.sourceUrl
        }
        // console.log(details.node)
        
    }
    const renderarticles = articles.map((article, index) => (
        
        <div class="col-md-4 mb-3">
                                    <div class="card">
                                        <img class="img-fluid" alt="100%x280" src={renderimage(article.featuredImage)} />
                                        <div class="card-body">
                                            <h4 class="card-title"> <a href={article.link}>{article.title}</a> </h4>
                                            <p class="card-text">{article.title}</p>

                                        </div>
                                    </div>
                                </div>
    ))
    return (<div className="container">
        {redirect ? (<>
            <Redirect to="/question2" />
            
            

        </>) : (<></>)}
        
        <Spinner animation={spin} />
        
        <div className="row">
            {/* <div className="col-sm-3" /> */}
            <div className="col-sm-12">
                {/* <Card style={{ border: 'rounded-pill', borderRadius: "30px" }} >
                    <Card.Body> */}
                        

                    
                        <h3 className="text-center">Resources</h3>
          <hr />
          
          <section class="pt-5 pb-5">
                    <div class="container">
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                
                <span className="curveresults btn-primary btn-lg">Articles</span>
           
                            </div>
                        </div>
        <div class="row">
            <div class="col-6 text-center" >
                {/* <h3 class="mb-3">Carousel cards title </h3> */}
            </div>
            {/* <div class="col-6 text-right">
                <a class="btn btn-primary mb-3 mr-1" href="#carouselExampleIndicators2" role="button" data-slide="prev">
                    <i class="fa fa-arrow-left"></i>
                </a>
                <a class="btn btn-primary mb-3 " href="#carouselExampleIndicators2" role="button" data-slide="next">
                    <i class="fa fa-arrow-right"></i>
                </a>
            </div> */}
            <div class="col-12">
                <div id="carouselExampleIndicators2" class="carousel slide" data-ride="carousel">

                    <div class="carousel-inner">
                        <div class="carousel-item active">
                                            <div class="row">
                                                
                                                {renderarticles}

                                

                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
                        

                        
                        
                        


                
               
                {/* </Card.Body>
                </Card> */}
            </div>
            

            
        </div>
        


    </div>);
}
 
export default Resources;