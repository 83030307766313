import React, { useEffect, useState } from 'react';
import Chart from "react-google-charts";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
// import faker from 'faker';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
    // responsive: true,
    // maintainAspectRatio: false,

    
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
    //   text: chart_type + 'Chart',
    },
  },
};
// const labels = ['January', 'February', 'March'];
// export const data = {
//   labels,
//   datasets: [
//     {
//       label: 'Dataset 1',
//       data: [10,20,30],
//       borderColor: 'rgb(255, 99, 132)',
//       backgroundColor: 'rgba(255, 99, 132, 0.5)',
//     },
    
//   ],
// };

const CommonChart = ({ chartdata,chart_type }) => {

    const [values, setValues] = useState([]);
  const [labels, setLabels] = useState([])
  const [, updateState] = React.useState();
const forceUpdate = React.useCallback(() => updateState({}), []);
    
    useEffect(() => {
        setValues([])
        setLabels([])
        console.log('chartdata')
        console.log(chartdata)

        splitdata()
        
    }, [])

 const data = {
  labels,
  datasets: [
    {
      label: chart_type,
      data: values,
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    
  ],
};
    
    const splitdata = () => {
        // console.log('before')
        // console.log(chartdata)
        // chartdata.shift()
        chartdata.map(chart => {
            console.log('chart');
            console.log(chart[0]);
            setLabels(labels => [...labels, chart[0]])
            setValues(values=>[...values, parseInt(chart[1])])
        })
      forceUpdate()
    }
    return (
      <>
        
        {/* {chartdata.length > 0 ? <> */}
          <Line  data={data}  width={120}
  height={150} />
        
        {/* </> : <></>} */}
            
        </>
     );
}
 
export default CommonChart;