import axios from 'axios';
import React,{useContext, useEffect, useState} from 'react';
import { Card, Button } from 'react-bootstrap'
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { QuestionContext } from '../../App';
import url from '../url';
import { Trans, useTranslation } from 'react-i18next';

const ProfileData = () => {
    const { t, i18n } = useTranslation()
    const [redirect, setRedirect] = useState(false)
    const [picture, setPicture] = useState(null);
  const [imgData, setImgData] = useState(null);
  const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);
    const { questionstate, dispatchQuestion } = useContext(QuestionContext)
    const [redirectprofile,setRedirectProfile]=useState(false)
  const onChangePicture = e => {
    if (e.target.files[0]) {
      console.log("picture: ", e.target.files);
        setPicture(e.target.files[0]);
        dispatchQuestion({type:'picture', value:e.target.files[0]})
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
    
    useEffect(() => {
        getCountries()
        getCities()

        fetchBio()
        
        
        
        

        
    }, [])

    const fetchBio = () => {
        var token = sessionStorage.getItem('mytoken', false)
        const config = {
            headers: { Authorization: `Bearer ${token}` }
            
        };
        
        // setSpin('border')
        axios.get(url+'wellness/user/profile', config)
            .then(res => {
                var dt=res.data.result
                console.log('dt.length')
                console.log(dt)

                var lent= Object.keys(res.data.result).length
                console.log(Object.keys(res.data.result).length)
                if (lent != 0) {
                    setRedirectProfile(true)

                    console.log('Very true')
                }
            
            }).catch(e => {

                // console.log(e.message)

                // if (e.message == 'Request failed with status code 404') {
                //     setRedirectProfile(true)
                // }

            
        })

    }


    const getCountries = () => {
        var token=sessionStorage.getItem('mytoken', false)
                const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        axios.get(url + 'wellness/countries', config)
            .then(res => {
                // console.log(res.data.result)
                setCountries(res.data.result)
            
            }).catch(e => {
                console.log(e)
            
            }).then(res => {
            
        })
    }
    const getCities = () => {
        var token=sessionStorage.getItem('mytoken', false)
                const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        axios.get(url + 'wellness/cities', config)
            .then(res => {
                // console.log(res.data.result)
                setCities(res.data.result)
            
            }).catch(e => {
                console.log(e)
            
            }).then(res => {
            
        })
    }

    const rendercountries = countries.map((country, index) => (
        <option value={country.id} key={index}>{ country.name}</option>
    ))
    const rendercities = cities.map((city, index) => (
        <option value={city.id} key={index}>{ city.capital}</option>
    ))
    return (<div className="container">
        {redirect ? (<>
            <Redirect to="/demographics" />
            
            

        </>) : (<></>)}
        
        {redirectprofile ? (<>
            {/* <Redirect to="/home" /> */}

            
            
            

        </>) : (<></>)}
        
        
        <div className="row">
            <div className="col-sm-3" />
            
            <div className="col-sm-5">
                <div className="row">
            <div className="darkbluebackground alert text-white col-sm-12">
                            <div className="row">
                                
                            <div className="col-12 text-center">
                               <h4> {t('wellness.form.profile_data')}</h4>

                            </div>
                            </div>
                           
                           
                        </div>
        </div>
              
                        
              
                        
                        
                              
                        
                        

                <div className="row">
                    
                    <table>
                            <tr>
                            <td>
                                 <div className="col-sm-9">
                        
                        
                        
                        <button onClick={()=>document.getElementById('profile-image').click()} className="btn btn-sm btn-primary" style={{whiteSpace:'nowrap'}}>  <span style={{whiteSpace:'nowrap'}}></span>{t('wellness.form.photo')}</button>
                            </div>
                                    
                            </td>
                            <td>
                                
                            </td>
                            <td>

                            </td>
                            <td>
                                <div className="col-sm-3">
                        

                        {!imgData ? <>
                            <img src="../../assets/wellness/avatar.png" height="50px" />
                        </> : <>
                                <img className="img-thumbnail" src={imgData} />
                        </>}

                        
                        
                        
                        
                        <input id="profilePic" type="file" onChange={onChangePicture} id="profile-image" accept="image/*" style={{ visibility: "hidden" }} />
                            </div>

                                </td>
                            </tr>
                        </table>
                   
                            

                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label>
                                         {t('wellness.form.first_name')}
                                    </label>
                            <input type="text" className="form-control" value={questionstate.first_name}
                                onChange={(e) => dispatchQuestion({ type: 'first_name', value: e.target.value })} placeholder="Enter your first name" />
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label>
                                        {t('wellness.form.last_name')}
                                    </label>
                                    <input type="text" className="form-control"  value={questionstate.last_name} onChange={(e) => dispatchQuestion({ type: 'last_name', value: e.target.value })} placeholder="Enter your last name" />
                                </div>
                            </div>

                        </div>
                        {/* <hr /> */}
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>
                                        {t('wellness.form.country')}
                                    </label>
                                     <select className="form-control" value={questionstate.country} onChange={(e) => dispatchQuestion({ type: 'country', value: e.target.value })}>
                                    <option>{t('wellness.optional.select')}...</option>
                                    {rendercountries}
                                </select>
                                </div>
                               
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>
                                        {t('wellness.form.city')}
                                    </label>
                                     <select className="form-control" value={questionstate.city} onChange={(e) => dispatchQuestion({ type: 'city', value: e.target.value })}>
                                    <option>{t('wellness.optional.select')}...</option>
                                    {rendercities}
                                </select>
                                </div>
                               
                            </div>
                        </div>
                        {/* <hr /> */}


                <br />
                
                <br />
                <div className="row" style={{paddingTop:'20px'}}>
                            <div className="col-sm-6">
                                {/* <div>
                                    
                                    <Button variant="link" as={Link} to="/question3" style={{ color: '#1d7ced', fontWeight: '600' }} > <i class="icons-edit1"></i><i className="fas1 fa-edit2"></i>Previous</Button>
                                </div> */}
                                
                               
                            </div>
                            <div className="col-sm-6">
                                
                                <div className="float-right">
                                    
                                    <Button variant="link" as={Link} to="/demographics" style={{ color: '#1d7ced', fontWeight: '600' }} > <i class="icons-edit1"></i><i className="fas1 fa-edit2"></i><img src='../assets/wellness/Next.png' class="responsive" style={{width:'40px'}} />< br />Next</Button>
                                    
                                </div>
                               
                            </div>
                            
                        </div>
              
            </div>
            

            
        </div>
        


    </div>);
}
 
export default ProfileData;