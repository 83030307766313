import axios from 'axios';
import React,{useState} from 'react';
import { Card, Button } from 'react-bootstrap'
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import url from '../url';

const Contact = () => {
    const [redirect, setRedirect] = useState(false)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage]=useState('')
    

    const submitForm = () => {
    
        var token=sessionStorage.getItem('mytoken', false)
                const config = {
            headers: { Authorization: `Bearer ${token}` }
                };
        var postData = {
      name,email,message
        };

        
        
        axios.post(url + 'wellness/contact-us', postData, config)
            .then(res => {
                console.log(res)
                if (res.data.message == 'success') {
                    alert('Your query has been sent.')
                    
                }
            
            }).catch(e => {
                console.log(e)
            
            }).then(r => {

            
        })
    }
    return (<div className="container">
        {redirect ? (<>
            <Redirect to="/question2" />
            
            

      </>) : (<></>)}
        
        <div className="row">
            <div className="col-sm-3" />
            <div className="col-sm-5">
                {/* <Card style={{ border: 'rounded-pill', borderRadius: "30px" }} >
                    <Card.Body> */}
                        
                        

                    
                        <div className="row alert text-white " style={{ background: '#a6cfeb' }}>
                    <div className="col-sm-2">
                        <Button variant="link" as={Link} size="lg" to="/question3" style={{ color: '#1d7ced', fontWeight: '600' }} ><i class="fa fa-arrow-left" aria-hidden="true"></i></Button>
                    </div>
                    <div className="col-sm-6" >
                        
                           <h3>@ Contact us</h3> 
                           
                           
                        </div>
        </div>
                        

                        
                        <div className="row">
                            <div className="col-sm-8">
                                <div className="form-group">
                                    <label>
                                        Name
                            </label>
                            <input type="text" className="form-control" value={name} onChange={(e)=>setName(e.target.value)} />
                                    
                                </div>
                    </div>
                    

                        </div>
                        <div className="row">
                            <div className="col-sm-8">
                                <div className="form-group">
                                    <label>
                                        Email
                            </label>
                            <input type="text" className="form-control" value={email} onChange={(e)=>setEmail(e.target.value)} />
                                    
                                </div>
                    </div>
                    

                        </div>
                        <div className="row">
                            <div className="col-sm-8">
                                <div className="form-group">
                                    <label>
                                        Message
                            </label>
                            <textarea className="form-control" rows="5" value={message}
                                onChange={(e) => setMessage(e.target.value)}></textarea>
                                    
                                </div>
                    </div>
                    

                        </div>
                <div className="row">
                    <div className="col-sm-8">
                      <center>  <button className="btn btn-sm btn-primary" title="Send" onClick={submitForm} >Send</button></center>
                    </div>
                        </div>
                        
                        
                        
                        
                       {/* <div className="row" style={{paddingTop:'20px'}}>
                            <div className="col-sm-4">
                                
                                
                               
                            </div>
                            <div className="col-sm-6">
                                
                                <div>
                                    
                            <Button variant="link" as={Link} size="lg" to="/question3" style={{ color: '#1d7ced', fontWeight: '600' }} ><i class="fa fa-arrow-left" aria-hidden="true"></i></Button>
                            <br /> Back
                                    
                                </div>
                               
                            </div>
                            
                        </div> */}
                        


                
               
                {/* </Card.Body>
                </Card> */}
            </div>
            

            
        </div>
        


    </div>);
}
 
export default Contact;