import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import url from './url';
import { Card, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { Button as Button2 } from 'react-bootstrap';
import moment from 'moment'
import { Trans, useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { LanguageContext, QuestionContext } from '../App';
import { Redirect } from 'react-router';
import {

    Image

} from 'semantic-ui-react'
import HeartRate from './wellness/HeartRate';
import Spo2 from './wellness/charts/Spo2RateChart';
import Spo2Rate from './wellness/Spo2Rate';
import BreathingRate from './wellness/BreathingRate';
import TemperatureRate from './wellness/TemperatureRate';
import EyeRate from './wellness/EyeRate';
import BloodSugarRate from './wellness/BloodSugarRate';
import BloodPressureRate from './wellness/BloodPressureRate';
import RecordVideo from './RecordVideo';
import SetGoal from './wellness/SetGoal';
const DashboardDiagnosis = () => {
    const { t, i18n } = useTranslation()
    const languagecontext = useContext(LanguageContext)
    const {initialgoalstate, dispatGoals}= useContext(QuestionContext)
    const [spin, setSpin] = useState('false')
    const [spinsave, setSpinSave] = useState('false')
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')
    const [diagnosis, setDiagnosis] = useState({})
    const [empty, setEmpty] = useState(false)
    const [language, setLanguage] = useState('')
    const [message, setMessage] = useState('')
    const [humanfigure, setHumanFigure] = useState('assets/Webappimages16/')
    const [chart, setChart] = useState('')
    const [show, setShow] = useState(false);
    const [editdiagnosis, setEditDiagnosis] = useState(false)
    const [id, setId]=useState('')
    const [heartrate, setHeartRate]=useState('')
    const [heartraterange, setHeartRateRange]=useState('')
    const [spo2, setSpo2]=useState('')
    const [spo2range, setSpo2Range]=useState('')
    const [bloodpressurecode, setBloodPressureCode]=useState('')
    const [bloodpressurerange, setBloodPressureRange]=useState('')
    const [bloodsugarcode,setBloodSugarCode ]=useState('')
    const [bmi,setBmi ]=useState('')
    const [eyecoloration,setEyeColoration ]=useState('')
    // const [eyecoloration,setEyeColoration ]=useState('')
    const [respirationrate,setRespirationRate ]=useState('')
    const [respirationraterange, setRespirationRateRange] = useState('')
    const [operation, setOperation]=useState('')
    const [temperature, setTemperature]=useState('')
    const [apidiagnosis, setApiDiagnosis] = useState('')
    const [bloodpressurelow, setBloodPressureLow]=useState('')
    const [bloodpressurehigh, setBloodPressureHigh] = useState('')
    const [lgShow, setLgShow] = useState(false);
    const [mygoals, setMyGoals] = useState({});
    // goals

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    useEffect(() => {
        // console.log(languagecontext.languagestate)
        if (languagecontext.languagestate == 'en') {
            setLanguage('en')

        } else {
            setLanguage('zh_TW')

        }

        fetchDiagnosis()
        fetchMyGoals()


    }, [languagecontext.languagestate])

    const fetchMyGoals = () => {
         var token = sessionStorage.getItem('mytoken', false)
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                language: languagecontext.languagestate
            }
        };


        axios.get(url + 'wellness/goals', config)
            .then(res => {
                console.log('start of goals')
                console.log(res)
                console.log('end of goals')
                setMyGoals(res.data.result)
                dispatGoals({type:'set_goals',value:res.data.result})
            
            }).catch(e => {
                console.log(e)
            
            }).then(res => {
                console.log(res)
            
        })
        
    }
    useEffect(() => {
        // console.log(languagecontext.languagestate)
        // if (languagecontext.languagestate == 'en') {
        //     setLanguage('en')

        // } else {
        //     setLanguage('zh_TW')

        // }

        fetchDiagnosis()


    }, [language])
    // Fetch updates every 10 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            //   if (languagecontext.languagestate == 'en') {
            //         setLanguage('en')

            //     } else {
            //         setLanguage('zh_TW')

            //     }
            setLanguage('language')
        }, 10000);
        return () => clearInterval(interval);
    }, []);
    const fetchDiagnosis = () => {

        setSpin('border')
        setMessage(t('responses.fetch_results'))

        if (languagecontext.languagestate == 'en') {
            setLanguage('en')

        } else {
            setLanguage('zh_TW')

        }
        var token = sessionStorage.getItem('mytoken', false)
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                language: languagecontext.languagestate
            }
        };


        axios.get(url + 'wellness/user/diagnosis', config)
            .then(res => {
                // console.log(res.data)
                setSuccess(res.data.message)

                let array = res.data.result;
                if (array === undefined || array.length == 0) {
                    setEmpty(true)

                } else {
                    const lastItem = array[0]
                    
                    setDiagnosis(lastItem)
                    


                }


            }).catch(e => {
                // setError('We didn\'t find your email in the system Kindly create an account')
                // console.log(e)
                setError(e.message)



            }).then(r => {
                setSpin('false')
                setMessage('')
                setShow(false)

            })
    }

    const autofill = () => {
        // setDiagnosis(lastItem)
        var lastItem=diagnosis

                    setHeartRate(lastItem.heart_rate)
                    setHeartRateRange(lastItem.heart_rate_range)
                    setSpo2(lastItem.spo2)
                    setSpo2Range(lastItem.spo2)
                    setBloodPressureCode(lastItem.blood_pressure_code)
                    setBloodPressureRange(lastItem.blood_pressure_range)
                    setBloodSugarCode(lastItem.blood_sugar_code)
                    setBmi(lastItem.bmi)
                    setEyeColoration(lastItem.eye_coloration)
                    setRespirationRate(lastItem.respiration_rate)
                    setRespirationRateRange(lastItem.respiration_rate_range)
    }
    const clearfill = () => {
        // setDiagnosis(lastItem)
        var lastItem=diagnosis

                    setHeartRate('')
                    setHeartRateRange('')
                    setSpo2('')
                    setSpo2Range('')
                    setBloodPressureCode('')
                    setBloodPressureRange('')
                    setBloodSugarCode('')
                    setBmi('')
                    setEyeColoration('')
                    setRespirationRate('')
                    setRespirationRateRange('')
    }

    const startedit = () => {
        autofill()
        setEditDiagnosis(true)
        setOperation('edit')
    }
    const startadd = () => {
        setOperation('add')
        clearfill()
        setEditDiagnosis(true)
    }

    const submitData = () => {
        if (operation == 'edit') {
            var url2="wellness/user/update-latest-diagnosis"
            // var url2="wellness/user/create-diagnosis"
        } else {
           var url2="wellness/user/create-diagnosis"
        }

        // return
        setSpinSave('border')

        var bloodpresure=bloodpressurehigh +'~'+bloodpressurelow
        var token=sessionStorage.getItem('mytoken', false)
                const config = {
            headers: { Authorization: `Bearer ${token}` }
                };
        var postData = {
      temperature: temperature,
      spo2_range: spo2,
      eye_coloration: eyecoloration,
    //   diagnosis: apidiagnosis,
    //   blood_pressure_code: bloodpressurecode,
      blood_pressure_range: bloodpresure,
    //   blood_sugar_code: bloodsugarcode,
      blood_sugar_range: bloodsugarcode,
    //   respiration_rate: respirationrate,
      respiration_rate_range: respirationrate,
    //   spo2_range: spo2range,
            heart_rate_range:heartrate
        };
        
        axios.post(url + url2, postData, config).
            then(res => {

                console.log(res)
            
            }).catch(e => {
            
            }).then(r => {
                setSpinSave('false')
                setEditDiagnosis(false)
                fetchDiagnosis()
            
        })
        
    }
    const renderresultsimage = (healthstatus) => {
        if (healthstatus == 0) {
            // setHumanFigure('assets/web2images/goresults.png')
            return (
                <img src="assets/green.png" height="25"
                    width="25"
                    alt="green" />

            )


        } else if (healthstatus == 1) {
            // setHumanFigure('assets/web2images/warningresults.png')
            return <img src="assets/yellow.png" height="25"
                width="25"
                alt="yello" />


        } else {
            // setHumanFigure('assets/web2images/warningresults.png')
            return <img src="assets/red.png" height="25"
                width="25"
                alt="red" />
        }

    }
        const renderresultscolor = (healthstatus) => {
        if (healthstatus == 0) {
            // setHumanFigure('assets/web2images/goresults.png')
            
            return "go-results"


        } else if (healthstatus == 1) {
            // setHumanFigure('assets/web2images/warningresults.png')
            return "warning-results"


        } else {
            // setHumanFigure('assets/web2images/warningresults.png')
            return "red-results"
        }

        }
    const renderresultsicon = (healthstatus) => {
        if (healthstatus == 0) {
            // setHumanFigure('assets/web2images/goresults.png')
            
            return "Go.png"


        } else if (healthstatus == 1) {
            // setHumanFigure('assets/web2images/warningresults.png')
            return "Caution.png"


        } else {
            // setHumanFigure('assets/web2images/warningresults.png')
            return "Stop.png"
        }

    }
    return (<div className="container">

        <hr />

        {error == 'Request failed with status code 401' ? <>
            <Redirect to="/" />
            
         
        </> : <></>}
        {/* {empty ? <>
            <br />
            <br />
            
            <div className="row">
                <div className="col-sm-12">
                    <span className="alert alert-info">
                        {t('diagnosis_results.no_results')}
                    </span>
                </div>
            </div>
            
        </> :
            <> */}
        {/* {language} */}
        <div className="row">
            <div className="col-sm-12">
                <Spinner animation={spin} variant="primary" /> {message}
                {error ? <>
                    <div className="alert alert-danger">
                        {error}
                    </div>
                </> : <></>}

            </div>
        </div>
        


                {diagnosis.created_at != null ? <>
                    <p>
                        <b> {moment(diagnosis.created_at).format('YYYY-MM-DD h:mm:ss a')}</b>
                    </p>
                    {/* <p> */}
                    {/* <strong>{t('diagnosis_results.results')}:</strong>
                    {renderresultsimage(diagnosis.health_status)} */}

                    
                    {/* </p> */}
                   {/* <p> <h4> {t('diagnosis_results.health_report')}</h4> </p> */}
                    {diagnosis.status == 3 ? <div>
                        {/* failed */}
                        {/* <em class="bold">
                            {t('diagnosis_results.failed_results.title')}
                        </em> */}

                        <ol>
                            <li>{t('diagnosis_results.failed_results.no1')}</li>
                            <li>{t('diagnosis_results.failed_results.no2')}</li>
                            <li>{t('diagnosis_results.failed_results.no3')}</li>
                        </ol>




                    </div> : <>
                        {/* SUccessful */}
                        {/* <p> */}
                        {/* <b>{t('diagnosis_results.viral_signs')}</b> */}
                            {/* </p > */}
                            


                    <p className={renderresultscolor(diagnosis.health_status)}>
                        {/* {diagnosis.diagnosis} */}
                    </p>


                    <div className="row">
                        
                        <div className="col-sm-5 curveresults">

                            

                             <div className="row">
                                
                                <table>
                                    <td width="5%" >
                                        <div className="col-sm-1" style={{padding:'0px'}}>
                                        <img src="assets/wellness/new/IconsSPO2.png"  height="60px" alt="Sp02" />
                                    </div>
                                    
                                </td>
                                    <td width="90%">
                                        <div className="col-sm-10" style={{fontSize:'1.6em'}}>
                                        {t('diagnosis_results.spo2')}: <span className={renderresultscolor(diagnosis.health_status)}  >{diagnosis.spo2_range} </span>
                                                
                                    </div>

                                </td>
                                    {/* <td>
                                        <div className="col-sm-9" style={{fontSize:'1em'}}>
                                        
                                                <span className={renderresultscolor(diagnosis.health_status)}  >{diagnosis.spo2} </span>
                                    </div>

                                </td> */}
                                    <td className="roundedtd" width="2%">
                                        <div className="col-sm-1">
                                        
                                      <Button2 variant="link" onClick={()=>setChart('spo2')} className="btn btn-secondary-outline text-white" ><i class="fa fa-arrow-down" aria-hidden="true"></i></Button2>
                                    </div>

                                </td>
                            </table>
                                    
                                    
                                    
                                    
                                </div> 

                        </div>
                        <div className="col-sm-1"> <div class="line"></div></div>
                        <div className="col-sm-5 curveresults">

                            

                            <div className="row">
                                
                                <table>
                                    <td width="5%" >
                                        <div className="col-sm-1" style={{padding:'0px'}}>
                                        <img src="assets/wellness/new/IconsRespiration.png"  height="60px"  alt="Breathe" />
                                    </div>
                                    
                                </td>
                                    <td width="90%">
                                        <div className="col-sm-10" style={{fontSize:'1.6em'}}>
                                        {t('diagnosis_results.breathing_rate')}: <span className={renderresultscolor(diagnosis.health_status)}>{diagnosis.respiration_rate_range} </span>
                                                
                                    </div>

                                </td>
                                    {/* <td>
                                        <div className="col-sm-9" style={{fontSize:'1em'}}>
                                        
                                                <span className={renderresultscolor(diagnosis.health_status)}>{diagnosis.respiration_rate} </span>
                                    </div>

                                </td> */}
                                    <td className="roundedtd" width="2%">
                                        <div className="col-sm-1">
                                        
                                      <Button2 variant="link" onClick={()=>setChart('breathing')} className="btn btn-secondary-outline text-white" ><i class="fa fa-arrow-down" aria-hidden="true"></i></Button2>
                                    </div>

                                </td>
                            </table>
                                    
                                    
                                    
                                    
                                </div>

                        </div>
                    </div>

                    {/* Row 2 */}
                    <div class="line"></div>
                    <div className="row">
                        
                        <div className="col-sm-5 curveresults">

                            

                            <div className="row">
                                
                                <table>
                                    <td width="5%">
                                        <div className="col-sm-1" style={{padding:'0px'}}>
                                        <img src="assets/wellness/new/IconsHeratrate.png"  height="60px"  alt="Heart rate" />
                                    </div>
                                    
                                </td>
                                    <td width="90%">
                                        <div className="col-sm-10" style={{fontSize:'1.6em'}}>
                                        {t('diagnosis_results.heart_rate')}: <span className={renderresultscolor(diagnosis.health_status)} >{diagnosis.heart_rate_range} </span>
                                                
                                    </div>

                                </td>
                                    {/* <td>
                                        <div className="col-sm-9" style={{fontSize:'1em'}}>
                                        
                                               <span className={renderresultscolor(diagnosis.health_status)} >{diagnosis.heart_rate} </span>
                                    </div>

                                </td> */}
                                    <td className="roundedtd" width="2%">
                                        <div className="col-sm-1">
                                        
                                      <Button2 variant="link" onClick={()=>setChart('heart')} className="btn btn-secondary-outline text-white"><i class="fa fa-arrow-down" aria-hidden="true"></i></Button2>
                                    </div>

                                </td>
                            </table>
                                    
                                    
                                    
                                    
                                </div>

                        </div>
                        <div className="col-sm-1"> <div class="line"></div></div>
                        <div className="col-sm-5 curveresults">

                            

                            <div className="row">
                                
                                <table>
                                    <td width="5%" >
                                        <div className="col-sm-1" style={{padding:'0px'}}>
                                        <img src="assets/wellness/new/IconsTemperature.png"  height="60px" alt="Temperature" />
                                    </div>
                                    
                                </td>
                                    {/* <td width="90%">
                                        <div className="col-sm-10" style={{fontSize:'1em', padding:'0px'}}>
                                        {t('diagnosis_results.temperature')}: <span className={renderresultscolor(diagnosis.health_status)} > {diagnosis.temperature == "" ? <> n/a </> : <>{ diagnosis.temperature}</>}</span>
                                                
                                    </div>

                                </td> */}
                                    <td width="90%">
                                        <div className="col-sm-10" style={{fontSize:'1.6em'}}>
                                        {/* {t('diagnosis_results.heart_rate')}: <span className={renderresultscolor(diagnosis.health_status)} >{diagnosis.heart_rate} </span>
                                         */}
                                            {t('diagnosis_results.temperature')}: <span className={renderresultscolor(diagnosis.health_status)} > {diagnosis.temperature == "" ? <> n/a </> : <>{ diagnosis.temperature}</>}</span>
                                                
                                    </div>

                                </td>
                                    {/* <td>
                                        <div className="col-sm-9" style={{fontSize:'1em'}}>
                                        
                                                              <span className={renderresultscolor(diagnosis.health_status)} > {diagnosis.temperature == "" ? <> n/a </> : <>{ diagnosis.temperature}</>}</span><h6>&nbsp;</h6>
                                    </div>

                                </td> */}
                                    <td className="roundedtd" width="2%">
                                        <div className="col-sm-1">
                                        
                                      <Button2 variant="link" onClick={()=>setChart('temperature')} className="btn btn-secondary-outline text-white" ><i class="fa fa-arrow-down" aria-hidden="true"></i></Button2>
                                    </div>

                                </td>
                            </table>
                                    
                                    
                                    
                                    
                                </div>

                        </div>
                    </div>
                    {/* row 3 */}
                    <div class="line"></div>
                    <div className="row">
                        
                        <div className="col-sm-5 curveresults">

                            

                            <div className="row">
                                
                                <table>
                                    <td width="5%" >
                                        <div className="col-sm-1" style={{padding:'0px'}}>
                                        <img src="assets/wellness/new/IconsEyecoloration.png"  height="60px" lat="eye" />
                                    </div>
                                    
                                </td>
                                    <td width="90%">
                                        <div className="col-sm-10" style={{fontSize:'1.6em'}}>
                                        {t('diagnosis_results.eye_coloration')}: <span className={renderresultscolor(diagnosis.health_status)}>{diagnosis.eye_coloration}</span>
                                                
                                    </div>

                                </td>
                                    {/* <td>
                                        <div className="col-sm-9" style={{fontSize:'1em'}}>
                                        
                                               <span className={renderresultscolor(diagnosis.health_status)}>{diagnosis.eye_coloration}</span>
                                    </div>

                                </td> */}
                                    <td className="roundedtd">
                                        <div className="col-sm-1" width="2%">
                                        
                                      <Button2 variant="link" onClick={()=>setChart('eye')} className="btn btn-secondary-outline text-white" ><i class="fa fa-arrow-down" aria-hidden="true" ></i></Button2>
                                    </div>

                                </td>
                            </table>
                                    
                                    
                                    
                                    
                                </div>

                        </div>
                        <div className="col-sm-1"> <div class="line"></div></div>
                        <div className="col-sm-5 curveresults">

                            

                            <div className="row">
                                
                                <table>
                                    <td  width="5%" >
                                        <div className="col-sm-1" style={{padding:'0px'}}>
                                         <img src="assets/wellness/new/IconsBloodpressure.png"  height="60px"  lat="eye" /> 
                                    </div>
                                    
                                </td>
                                    <td width="90%">
                                        <div className="col-sm-10" style={{fontSize:'1.6em'}}>
                                        {t('diagnosis_results.blood_pressure')} <span className={renderresultscolor(diagnosis.health_status)} className="btn btn-secondary-outline text-white"> {diagnosis.blood_pressure_range}</span>
                                                
                                    </div>

                                </td>
                                    {/* <td>
                                        <div className="col-sm-9" style={{fontSize:'1em'}}>
                                        
                                               <span className={renderresultscolor(diagnosis.health_status)}>{diagnosis.blood_pressure_code} {diagnosis.blood_pressure_range}</span>
                                    </div>

                                </td> */}
                                    <td className="roundedtd" width="2%">
                                        <div className="col-sm-1">
                                        
                                      <Button2 variant="link" onClick={()=>setChart('blood_pressure')} className="btn btn-secondary-outline text-white" ><i class="fa fa-arrow-down" aria-hidden="true"></i></Button2>
                                    </div>

                                </td>
                            </table>
                                    
                                    
                                    
                                    
                                </div>

                        </div>
                        <div className="col-sm-1"> <div class="line"></div></div>
                        
                    </div>
                    <div class="line"></div>
                    <div className="row">
                        
                        <div className="col-sm-5 curveresults">

                            

                            <div className="row">
                                
                                <table>
                                    <td width="5%" >
                                        <div className="col-sm-1" style={{padding:'0px'}}>
                                            {/* <img src="assets/Webappimages16/Icon05.png" className="allroundedtd" height="45px" lat="eye" /> */}
                                            <img src="assets/wellness/new/IconsSugarlevel.png"  height="60px"  lat="Sugar level" />
                                    </div>
                                    
                                </td>
                                    <td width="90%">
                                        <div className="col-sm-10" style={{fontSize:'1.6em'}}>
                                        {t('diagnosis_results.blood_sugar')} <span className={renderresultscolor(diagnosis.health_status)}>{diagnosis.blood_sugar} {diagnosis.blood_sugar_range}</span>
                                                
                                    </div>

                                </td>
                                    {/* <td>
                                        <div className="col-sm-9" style={{fontSize:'1em'}}>
                                        
                                               <span className={renderresultscolor(diagnosis.health_status)}>{diagnosis.blood_sugar_code} {diagnosis.blood_sugar_range}</span>
                                    </div>

                                </td> */}
                                    <td className="roundedtd" width="2%">
                                        <div className="col-sm-1">
                                        
                                      <button  onClick={()=>setChart('blood_sugar')} className="btn btn-secondary-outline text-white" ><i className="fa fa-arrow-down" aria-hidden="true"></i></button>
                                    </div>

                                </td>
                            </table>
                                    
                                    
                                    
                                    
                                </div>

                        </div>
                        <div className="col-sm-1"> <div class="line"></div></div>
                        
                       
                        
                    </div>


                  

                    </>}





                    

                </> : <>
                    {t('diagnosis_results.no_results')}

                </>}
           
        <br />
        <div className="row">
            {/* <center> */}

            <table width="20%">
                <tr>
                    <td>
                        <div className="col-sm-4">
               <center> <Button2 as={Link} to="dashboard" className="button btn btn-md btn-danger button"> <i class="fas fa-record-vinyl"></i> <b>Scan</b> </Button2></center> 
            </div>
                    </td>
                    <td>
                        <div className="col-sm-4">
               <center> <Button2 onClick={()=>startadd()} className="btn btn-md btn-primary darkbluebackground"> <b>Add Manually</b></Button2></center> 
            </div>

                    </td>
                    <td>
                        <div className="col-sm-4">
               <center> <Button2 as={Link} to="/dashboard/dianosis" className=" darkbluebackground btn btn-md btn-primary darkbluebackground"><b>History</b></Button2></center> 
            </div>

                    </td> 

                    
                    
                </tr>
            </table>
            
            
            
            {/* </center> */}
        </div>
        
        <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Set Goal
          </Modal.Title>
        </Modal.Header>
            <Modal.Body>
                <SetGoal />
            </Modal.Body>
            <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

        <Modal
        size="lg"
        show={editdiagnosis}
        onHide={() => setEditDiagnosis(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
            Edit 
          </Modal.Title> */}
        </Modal.Header>
            <Modal.Body>
                <input type="hidden" value={id} onChange={(e)=>setId(e.target.value)} className="form-control" />
                
                <div class="row">
                    
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                        <div className="form-group">
                            
                            <span class="label">Heart Rate</span>
                            <input type="number" value={heartrate} onChange={(e)=>setHeartRate(e.target.value)} className="form-control" placeholder="per minute" />
                            
                        </div>
                        
                    </div>
                    {/* <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                        <div className="form-group">
                            
                            <span class="label">Heart Rate Range</span>
                            <input type="text" value={heartraterange} onChange={(e)=>setHeartRateRange(e.target.value)} className="form-control" />
                            
                        </div>
                        
                    </div> */}
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                        <div className="form-group">
                            
                            <span class="label">Spo2</span>
                            <input type="number" value={spo2} onChange={(e) => setSpo2(e.target.value)} className="form-control" placeholder="%" />
                            
                            {/* <select value={spo2} onChange={(e) => setSpo2(e.target.value)} className="form-control">
                                <option value="">Select...</option>
                                <option value="spo2_normal">spo2_normal</option>
                                <option value="spo2_low">spo2_low</option>
                                <option value="spo2_high">spo2_high</option>


                            </select> */}
                            
                        </div>
                        
                    </div>
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                        <div className="form-group">
                            
                            <span class="label">Blood pressure</span>
                            <div className="row">
                                <table>
                                    <tr>
                                        <td>
                                            <input type="number" value={bloodpressurehigh} onChange={(e)=>setBloodPressureHigh(e.target.value)} className="form-control" placeholder="High (systolic)" />
                                        </td>
                                        <td>
                                            /
                                        </td>
                                        <td>
                                            <input type="number" value={bloodpressurelow} onChange={(e)=>setBloodPressureLow(e.target.value)} className="form-control" placeholder="Low (diastolic)" />

                                        </td>
                                    </tr>
                                </table>
                            </div>
                            {/* <select value={bloodpressurecode} onChange={(e) => setBloodPressureCode(e.target.value)} className="form-control">
                                <option value="">Select...</option>
                                <option value="bp_normal">bp_normal</option>
                                <option value="bp_abnormal">bp_abnormal</option>

                            </select> */}
                            
                        </div>
                        
                    </div>
                    
                    
                </div>
                {/* Third row */}
                <div class="row">
                    
                    {/* <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                        <div className="form-group">
                            
                            <span class="label">Spo2 Range</span>
                            <input type="text" value={spo2range} onChange={(e)=>setSpo2Range(e.target.value)} className="form-control" />
                            
                        </div>
                        
                    </div> */}
                    
                    {/* <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                        <div className="form-group">
                            
                            <span class="label">Blood pressure range</span>2
                            <input type="text" value={bloodpressurerange} onChange={(e)=>setBloodPressureRange(e.target.value)} className="form-control" />
                            
                        </div>
                        
                    </div> */}
                    
                    
                </div>
                {/* second Fouth*/}
                <div class="row">
                    
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                        <div className="form-group">
                            
                            <span class="label">Blood Sugar</span>
                            <input type="text" value={bloodsugarcode} onChange={(e)=>setBloodSugarCode(e.target.value)} className="form-control" placeholder="mg/dL or mmol/L" />
                            {/* <select value={bloodsugarcode} onChange={(e) => setBloodSugarCode(e.target.value)} className="form-control">
                                <option value>Select..</option>
                                <option value="bs_normal">bs_normal</option>
                                <option value="abs_normal">abs_normal</option>

                            </select> */}
                        </div>
                        
                    </div>
                    
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                        <div className="form-group">
                            
                            <span class="label">Eye coloration</span>
                            {/* <input type="text" value={eyecoloration} onChange={(e)=>setEyeColoration(e.target.value)} className="form-control" /> */}
                            <select value={eyecoloration} onChange={(e) => setEyeColoration(e.target.value)} className="form-control" >
                                <option value="">Please select</option>
                                <option value="normal">Normal</option>
                                <option value="abnormal">Abnormal</option>

                            </select>
                            
                        </div>
                        
                    </div>
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                        <div className="form-group">
                            
                            <span class="label">Respiration </span>
                            <input type="number" value={respirationrate} onChange={(e)=>setRespirationRate(e.target.value)} className="form-control" placeholder="per minute" />
                            {/* <select value={respirationrate} onChange={(e) => setRespirationRate(e.target.value)} className="form-control">
                                <option value="">Select...</option>
                                <option value="br_high">br_high</option>
                                <option value="br_normal">br_normal</option>
                                <option value="br_low">br_low</option>

                            </select> */}
                            
                        </div>
                        
                    </div>
                    
                    
                </div>
                {/* second Fift*/}
                <div class="row">
                    
                    
                    
                    
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                        <div className="form-group">
                            
                            <span class="label">Temperature</span>
                            <input type="text" value={temperature} onChange={(e)=>setTemperature(e.target.value)} className="form-control" placeholder=" O C / O F" />
                            
                        </div>
                        
                    </div>
                    
                    
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <button className="btn btn-primary" onClick={()=>submitData()}>Add</button>
                    </div>
                    <div className="col-sm-6">
                        {/* <button className="btn btn-primary" onClick={()=>submitData()}>Add Diagnosis</button>
                         */}
                        <Spinner animation={spinsave} variant="primary" /> 
                    </div>
                </div>
                {/* second Fift*/}
                
                
         </Modal.Body>
      </Modal>
        <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
            <Modal.Body>
                <RecordVideo />
        </Modal.Body>
        
        </Modal>
        
        <div className="row">
            <div className="col-sm-11">
                {chart == 'heart' ? <>
            
                    <HeartRate sentdata={diagnosis} />
                </> : <>
                </>}
        {chart == 'spo2' ? <>
            
                    <Spo2Rate sentdata={diagnosis} />
                </> : <>
                </>}
        {chart == 'breathing' ? <>
            
                    <BreathingRate sentdata={diagnosis} />
                </> : <>
                </>}
        {chart == 'temperature' ? <>
            
                    <TemperatureRate sentdata={diagnosis} />
                </> : <>
                </>}
        {chart == 'eye' ? <>
            
                    <EyeRate sentdata={diagnosis} />
                </> : <>
                </>}
        {chart == 'blood_sugar' ? <>
            
                    <BloodSugarRate sentdata={diagnosis} />
                </> : <>
                </>}
        {chart == 'blood_pressure' ? <>
            
            
            
                    <BloodPressureRate sentdata={diagnosis}  />
                </> : <>
                </>}

            </div>
        </div>
        
    </div>
        

        
    );
}

export default DashboardDiagnosis;