import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import url from './url';
import { Link } from 'react-router-dom';
import { Button,Modal } from 'react-bootstrap';

import { Card, Spinner } from 'react-bootstrap'
import moment from 'moment';
import ShowNav from './auth/ShowNav';
import { Trans, useTranslation } from 'react-i18next';
import { LanguageContext } from '../App';
import Pagination from "react-js-pagination";
import {

    Image

} from 'semantic-ui-react'
const initialstate = []
const AllDiagnosis = () => {
    const languagecontext = useContext(LanguageContext)
    const { t, i18n } = useTranslation()
    const [spin, setSpin] = useState('false');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [diagnosis, setDiagnosis] = useState([]);
    const [empty, setEmpty] = useState('');
    const [language, setLanguage] = useState('en');
    const [activePage, setActivePage] = useState('');
    const [perpage, setPerPage] = useState('')
    const [totalitem, setTotalItems] = useState('')
    const [humanfigure, setHumanFigure] = useState('../assets/Webappimages16/')
    const [editdiagnosis, setEditDiagnosis] = useState(false)
    const [id, setId]=useState('')
    const [heartrate, setHeartRate]=useState('')
    const [heartraterange, setHeartRateRange]=useState('')
    const [spo2, setSpo2]=useState('')
    const [spo2range, setSpo2Range]=useState('')
    const [bloodpressurecode, setBloodPressureCode]=useState('')
    const [bloodpressurerange, setBloodPressureRange]=useState('')
    const [bloodsugarcode,setBloodSugarCode ]=useState('')
    const [bmi,setBmi ]=useState('')
    const [eyecoloration,setEyeColoration ]=useState('')
    // const [eyecoloration,setEyeColoration ]=useState('')
    const [respirationrate,setRespirationRate ]=useState('')
    const [respirationraterange, setRespirationRateRange] = useState('')
    const [operation, setOperation]=useState('')
    const [temperature, setTemperature]=useState('')
    const [apidiagnosis, setApiDiagnosis] = useState('')
    const [bloodpressurelow, setBloodPressureLow]=useState('')
    const [bloodpressurehigh, setBloodPressureHigh] = useState('')

    useEffect(() => {
        // console.log(languagecontext.languagestate)
        if (languagecontext.languagestate == 'en') {
            setLanguage('en')

        } else {
            setLanguage('zh_TW')

        }

        fetchAllDiagnosis()


    }, [languagecontext.languagestate])
    useEffect(() => {
        // console.log(languagecontext.languagestate)
        // if (languagecontext.languagestate == 'en') {
        //     setLanguage('en')

        // } else {
        //     setLanguage('zh_TW')

        // }

        fetchAllDiagnosis()


    }, [language])
    // Fetch updates every 10 seconds
    //     useEffect(() => {
    //         const interval = setInterval(() => {
    //     //   if (languagecontext.languagestate == 'en') {
    //     //         setLanguage('en')

    //     //     } else {
    //     //         setLanguage('zh_TW')

    //     //     }
    //     setLanguage('language')
    //   }, 1000000);
    //   return () => clearInterval(interval);
    // }, []);

    const fetchAllDiagnosis = (url_ = null) => {
        if (languagecontext.languagestate == 'en') {
            setLanguage('en')

        } else {
            setLanguage('zh_TW')

        }
        if (!url_) {
            url_ = "diagnosis/all";
        }
        setSpin('border')
        // setDiagnosis([])

        var token = sessionStorage.getItem('mytoken', false)
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                language: languagecontext.languagestate
            }
        };

        // alert(language)
        // setDiagnosis([])


        axios.get(url + url_, config)
            .then(res => {
                // console.log(res.data.meta);
                setActivePage(res.data.meta.page)
                setTotalItems(res.data.meta.total)
                setPerPage(res.data.meta.per_page)
                setSuccess(res.data.message);
                setDiagnosis(initialstate)
                let arr = res.data.result;
                console.log("start json from server")
                console.log(res.data)
                console.log(res.data.result)
                // console.log("end json from server")
                if (arr === undefined || arr.length == 0) {
                    setEmpty('true')

                }
                arr.map(arr => {
                    if (arr.status != 3) {
                        setDiagnosis(diagnosis => [...diagnosis, arr])

                    }
                })



            }).catch(e => {
                // setError('We didn\'t find your email in the system Kindly create an account')

                setError(e.message)

            }).then(r => {
                setSpin('false')

            })

    }
    const renderresultsimage = (healthstatus) => {
        if (healthstatus == 0) {
            return <img src="../assets/green.png" />
        } else if (healthstatus == 1) {
            return <img src="../assets/yellow.png" />

        } else {
            return <img src="../assets/red.png" />
        }

    }
    const renderresultscolor = (healthstatus) => {
        if (healthstatus == 0) {
            // setHumanFigure('assets/web2images/goresults.png')
            
            return "go-results"


        } else if (healthstatus == 1) {
            // setHumanFigure('assets/web2images/warningresults.png')
            return "warning-results"


        } else {
            // setHumanFigure('assets/web2images/warningresults.png')
            return "red-results"
        }

    }
        const renderresultsicon = (healthstatus) => {
        if (healthstatus == 0) {
            // setHumanFigure('assets/web2images/goresults.png')
            
            
            return "Go.png"


        } else if (healthstatus == 1) {
            // setHumanFigure('assets/web2images/warningresults.png')
            return "Caution.png"


        } else {
            // setHumanFigure('assets/web2images/warningresults.png')
            return "Stop.png"
        }

    }
    const handlePageChange = (e) => {
        let pageUrl = "diagnosis/all?page=" + e;
        fetchAllDiagnosis(pageUrl);
    }

    const editDiagnosisAction = (diagnosis) => {

        console.log(diagnosis);
         var lastItem=diagnosis

                    setHeartRate(lastItem.heart_rate)
                    setId(lastItem.id)
                    setHeartRateRange(lastItem.heart_rate_range)
                    setSpo2(lastItem.spo2)
                    setSpo2Range(lastItem.spo2)
                    setBloodPressureCode(lastItem.blood_pressure_code)
                    setBloodPressureRange(lastItem.blood_pressure_range)
                    setBloodSugarCode(lastItem.blood_sugar_code)
                    setBmi(lastItem.bmi)
                    setEyeColoration(lastItem.eye_coloration)
                    setRespirationRate(lastItem.respiration_rate)
        setRespirationRateRange(lastItem.respiration_rate_range)
        
        setEditDiagnosis(true)
        
    }

        const submitData = () => {
        if (operation == 'edit') {
            var url2="wellness/user/update-latest-diagnosis"
            // var url2="wellness/user/create-diagnosis"
        } else {
           var url2="wellness/user/update-latest-diagnosis"
        }

        // return
        setSpin('border')
        var token=sessionStorage.getItem('mytoken', false)
                const config = {
            headers: { Authorization: `Bearer ${token}` }
                };
            var postData = {
            id:id,
      temperature: temperature,
      spo2: spo2,
      eye_coloration: eyecoloration,
      diagnosis: apidiagnosis,
      blood_pressure_code: bloodpressurecode,
      blood_pressure_range: bloodpressurerange,
      blood_sugar_code: bloodsugarcode,
      blood_sugar_range: bloodpressurerange,
      respiration_rate: respirationrate,
      respiration_rate_range: respirationraterange,
      spo2_range: spo2range,
        };
        
        axios.post(url + url2, postData, config).
            then(res => {
            
            }).catch(e => {
            
            }).then(r => {
                setSpin('false')
                setEditDiagnosis(false)
                fetchAllDiagnosis()
            
        })
        
    }
    const renderdiagnosis = diagnosis.map((diagnosis, index) => (

        <div className="col-sm-6" style={{ paddingTop: '10px' }}>
            <Card
                className="card p-1 bg-transparent"
                // style={{ border: 'none' }}
            >
                {/* <div className="row">
                    <div className="col-sm-3" />
                    <div className="col-sm-5" style={{ background: '#173f82' }}>
                        <Image size='small' src='../assets/docsun.png' sstyle={{ height: 50, paddingLeft: "-20px" }} />
                    </div>
                    <div className="col-sm-3" />
                </div> */}

                <Card.Body>

                    


                    {diagnosis.created_at != null ? <>
                        
                        

                        <div className="row">
                            <div className="col-sm-8">
                                <p>
                            <b> {moment(diagnosis.created_at).format('YYYY-MM-DD h:mm:ss a')}</b>
                        </p>

                            </div>
                            
                            

                            {diagnosis.is_auto ? <>
                                <div className="col-sm-4 float-right">
                                            <button className="btn btn-sm btn-primary" onClick={()=>editDiagnosisAction(diagnosis)}>Edit</button>

                                        </div>
                            
                            </> : <></>}
                                        
                                    </div>
                        {/* <p> */}
                        {/* <strong>{t('diagnosis_results.results')}:</strong>
                        {renderresultsimage(diagnosis.health_status)} */}
                        <h4><strong><h4> {t('diagnosis_results.health_report')}</h4></strong></h4>


                        <p className={renderresultscolor(diagnosis.health_status)}>
                            {diagnosis.diagnosis}
                        </p>
                        {/* </p> */}
                        {diagnosis.status == 3 ? <div style={{ fontSize: "15px" }}>
                            {/* failed */}
                            <em class="bold">
                                {t('diagnosis_results.failed_results.title')}
                            </em>

                            <ol>
                                <li>{t('diagnosis_results.failed_results.no1')}</li>
                                <li>{t('diagnosis_results.failed_results.no2')}</li>
                                <li>{t('diagnosis_results.failed_results.no3')}</li>
                            </ol>





                        </div> : <>
                            {/* SUccessful */}
                            {/* <p> */}
                            {/* <b>{t('diagnosis_results.viral_signs')}</b> */}
                            {/* </p > */}

                                <div className="row">
                                    
                                    
                                <div className="col-sm-9" >
                                        <p>
                                            <div className="curveresults">
                                                <div>
                                                    <table>
                                                        <tr>
                                                            <td>
                                                                <div >
                                                        <img src="../assets/Webappimages16/Icon01.png" height="60px" width="60px" alt="Sp02" />
                                                    </div>

                                                            </td>
                                                            <td style={{ paddingLeft: '20px' }}>
                                                                <div>
                                                        <b>{t('diagnosis_results.spo2')}:</b> <br />
                                            <span className={renderresultscolor(diagnosis.health_status)}>{diagnosis.spo2} </span><br />
                                                        
                                                    </div>
                                                                
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    
                                                    
                                                </div>
                                                
                                                
                                            </div>
                                            <div class="line"></div>
                                                <div className="curveresults">
                                                <div>
                                                    <table>
                                                        <tr>
                                                            <td>
                                                                <div>
                                                        <img src="../assets/Webappimages16/Icon02.png" height="60px" width="60px" alt="Breathe" />
                                                    </div>

                                                            </td>
                                                            <td style={{ paddingLeft: '20px' }}>
                                                                <div>
                                                        <b>{t('diagnosis_results.breathing_rate')}:
                                                <br /></b> <span className={renderresultscolor(diagnosis.health_status)}>{diagnosis.respiration_rate} </span> <br />

                                                    </div>
                                                                
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    
                                                    
                                                </div>
                                                
                                                

                                            </div>
                                            <div class="line"></div>
                                            <div className="curveresults">
                                                <div>
                                                    <table>
                                                        <tr>
                                                            <td>
                                                                <div >
                                                        <img src="../assets/Webappimages16/Icon03.png" width="60px" alt="Heart rate" />
                                                    </div>

                                                            </td>
                                                            <td style={{ paddingLeft: '20px' }}>
                                                                <div>
                                                        <b> {t('diagnosis_results.heart_rate')}:</b> <br />
                                            <span className={renderresultscolor(diagnosis.health_status)}>{diagnosis.heart_rate} </span><br />
                                                        
                                                    </div>
                                                                
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    
                                                    
                                                </div>
                                                
                                                
                                            </div>
                                            
                                            <div class="line"></div>
                                            <div  className="curveresults">
                                                <div>
                                                    <table>
                                                        <tr>
                                                            <td>
                                                                <div >
                                                        <img src="../assets/Webappimages16/Icon04.png" height="60px" width="60px" alt="Temperature" />
                                                    </div>

                                                            </td>
                                                            <td style={{ paddingLeft: '20px' }}>
                                                                <div>
                                                        <span><b>{t('diagnosis_results.temperature')}: <br />
                                            </b></span> <span className={renderresultscolor(diagnosis.health_status)}> {diagnosis.temperature == "" ? <> n/a </> : <>{ diagnosis.temperature}</>}</span> <br />
                                                        
                                                    </div>
                                                                
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    
                                                    
                                                </div>
                                                
                                                
                                            </div>
                                            <div class="line"></div>
                                            <div className="curveresults">
                                                <div>
                                                    <table>
                                                        <tr>
                                                            <td>
                                                                <div>
                                                        <img src="../assets/Webappimages16/Icon05.png" height="60px" width="60px" lat="eye" />
                                                    </div>

                                                            </td>
                                                            <td style={{ paddingLeft: '20px' }}>
                                                                <div>
                                                        <b>{t('diagnosis_results.eye_coloration')}: </b><br>
                                            </br><span className={renderresultscolor(diagnosis.health_status)}>{diagnosis.eye_coloration}</span> <br />

                                                    </div>
                                                                
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    
                                                    
                                                </div>
                                            
                                            

                                            </div>
                                            <div class="line"></div>
                                            {/*  */}
                                            <div className="curveresults">
                                                <div>
                                                    <table>
                                                        <tr>
                                                            <td>
                                                                <div>
                                                        <img src="../assets/wellness/HealthiconBloodpressure.png" height="60px" width="60px" lat="eye" />
                                                    </div>

                                                            </td>
                                                            <td style={{ paddingLeft: '20px' }}>
                                                                <div>
                                                        <b>{t('diagnosis_results.blood_pressure')}: </b><br>
                                            </br><span className={renderresultscolor(diagnosis.health_status)}>{diagnosis.blood_pressure_code} {diagnosis.blood_pressure_range}</span> <br />

                                                    </div>
                                                                
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    
                                                    
                                                </div>
                                            
                                            

                                            </div>
                                            
                                            <div class="line"></div>
                                            {/*  */}
                                            <div className="curveresults">
                                                <div>
                                                    <table>
                                                        <tr>
                                                            <td>
                                                                <div>
                                                        <img src="../assets/wellness/HealthiconSugar.png" height="60px" width="60px" lat="eye" />
                                                    </div>

                                                            </td>
                                                            <td style={{ paddingLeft: '20px' }}>
                                                                <div>
                                                        <b>{t('diagnosis_results.blood_sugar')}: </b><br>
                                            </br><span className={renderresultscolor(diagnosis.health_status)}>{diagnosis.blood_sugar_code} {diagnosis.blood_sugar_range}</span> <br />

                                                    </div>
                                                                
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    
                                                    
                                                </div>
                                            
                                            

                                            </div>
                                            <div class="line"></div>
                                            
                                            
                                            
                                            {/* <div class="line"></div> */}


                                        {/* <div style={{ background: '#0c3668' }} className="curveresults">
                                                <div class="row">
                                                    <div className="col-sm-2" style={{ paddingTop: '5px' }}>
                                                    <img src="../assets/web2images/Iconbloodpressure.png" height="30px" width="30px" lat="eye" />
                                                    </div>
                                                    <div className="col-sm-8">
                                                                    <b style={{ color: 'white' }}> {t('diagnosis_results.blood_pressure')}:
                                            </b> <br /><span style={{ color: 'white' }}>{diagnosis.blood_pressure_code} {diagnosis.blood_pressure_range}</span> <br />
                                                    </div>
                                                </div>
                                            
                             
                                        </div>
                                        <div style={{ background: '#09284d' }} className="curveresults">
                                        <div class="row">
                                                    <div className="col-sm-2" style={{ paddingTop: '5px' }}>
                                                    <img src="../assets/web2images/Iconsugarlevel.png" height="30px" width="30px" lat="eye" />
                                                    </div>
                                                    <div className="col-sm-8">
                                                    <b style={{ color: 'white' }}> {t('diagnosis_results.blood_sugar')}: <br />
                                            </b><span style={{ color: 'white' }}>{diagnosis.blood_sugar_code} {diagnosis.blood_sugar_range}</span> <br />
                                                    </div>
                                                </div>
                                             
                                            
                                        </div> */}
                                    </p>
                                </div>
                                <div className="col-sm-3 d-none d-md-block" style={{ color: 'white', paddingTop:'110px' }}>
                                <img src={humanfigure+renderresultsicon(diagnosis.health_status)} alt="Figure" height="100px" />
                            </div>
                            </div>

                        </>}




                    </> : <>
                        {t('diagnosis_results.no_results')}

                    </>}
                </Card.Body>
            </Card>



        </div >
    ))

    return (<div className="container1">
        <ShowNav />


        <div className="row1" style={{ paddingTop: "60px", paddingLeft:'37px' }}>
            <div className="col-sm-12">
                <nav aria-label="breadcrumb">

                <ol class="breadcrumb">
                    <li class="breadcrumb-item">  <Button variant="link" as={Link} to="/home"  > {t('navigation.dashboard')}</Button></li>
                    <li class="breadcrumb-item active" aria-current="page" style={{ paddingTop: '5px' }}>{t('navigation.my_history')}</li>
                </ol>
            </nav>
            </div>
        </div>
        <div style={{paddingLeft:'50px'}}>
            <div className="row">
                 <div className="col-sm-3">
               <h4> {t('diagnosis_results.results')} </h4>
                </div> 
            </div>
            
            <div className="row">

                <div className="col-sm-3">
                    <button class="btn background-blue" style={{ borderColor: "#28696D" }} onClick={() => fetchAllDiagnosis()} >
                        <i class="fas fa-sync fa-fw"></i> {t('diagnosis_results.reload_results')}
                    </button>
                </div>
            </div>
            <div className="row" style={{paddingLeft:'18px'}}>
            <div className="col-sm-12" style={{paddingLeft:'18px'}}>
                <Spinner animation={spin} variant="primary" style={{paddingLeft:'18px'}} />
                {error ? <>
                    <div className="alert alert-danger">
                        {error}
                    </div>
                </> : <></>}

            </div>
            </div>
            </div>
        
        

        <br />
        <div className="row" style={{ paddingLeft: "50px" }}>
            <div style={{ paddingLeft: "15px" }}>
                <Pagination
                    activePage={activePage}
                    itemsCountPerPage={perpage}
                    totalItemsCount={totalitem}
                    pageRangeDisplayed={5}
                    onChange={(e) => handlePageChange(e)}
                    itemClass="page-item"
                    linkClass="page-link"

                />
            </div>
            {renderdiagnosis}
            <div style={{ paddingLeft: "20px" }}>
                <Pagination
                    activePage={activePage}
                    itemsCountPerPage={perpage}
                    totalItemsCount={totalitem}
                    pageRangeDisplayed={5}
                    onChange={(e) => handlePageChange(e)}
                    itemClass="page-item"
                    linkClass="page-link"

                />
            </div>

        </div>

        <Modal
        size="lg"
        show={editdiagnosis}
        onHide={() => setEditDiagnosis(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
            Edit 
          </Modal.Title> */}
        </Modal.Header>
            <Modal.Body>
                <input type="hidden" value={id} onChange={(e)=>setId(e.target.value)} className="form-control" />
                
                <div class="row">
                    
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                        <div className="form-group">
                            
                            <span class="label">Heart Rate</span>
                            <input type="number" value={heartrate} onChange={(e)=>setHeartRate(e.target.value)} className="form-control" placeholder="[per minute]" />
                            
                        </div>
                        
                    </div>
                    {/* <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                        <div className="form-group">
                            
                            <span class="label">Heart Rate Range</span>
                            <input type="text" value={heartraterange} onChange={(e)=>setHeartRateRange(e.target.value)} className="form-control" />
                            
                        </div>
                        
                    </div> */}
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                        <div className="form-group">
                            
                            <span class="label">Spo2</span>
                            <input type="number" value={spo2} onChange={(e) => setSpo2(e.target.value)} className="form-control" placeholder="[%]" />
                            
                            {/* <select value={spo2} onChange={(e) => setSpo2(e.target.value)} className="form-control">
                                <option value="">Select...</option>
                                <option value="spo2_normal">spo2_normal</option>
                                <option value="spo2_low">spo2_low</option>
                                <option value="spo2_high">spo2_high</option>


                            </select> */}
                            
                        </div>
                        
                    </div>
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                        <div className="form-group">
                            
                            <span class="label">Blood pressure</span>
                            <div className="row">
                                <table>
                                    <tr>
                                        <td>
                                            <input type="number" value={bloodpressurehigh} onChange={(e)=>setBloodPressureHigh(e.target.value)} className="form-control" placeholder="High (systolic)" />
                                        </td>
                                        <td>
                                            /
                                        </td>
                                        <td>
                                            <input type="number" value={bloodpressurelow} onChange={(e)=>setBloodPressureLow(e.target.value)} className="form-control" placeholder="Low (diastolic)" />

                                        </td>
                                    </tr>
                                </table>
                            </div>
                            {/* <select value={bloodpressurecode} onChange={(e) => setBloodPressureCode(e.target.value)} className="form-control">
                                <option value="">Select...</option>
                                <option value="bp_normal">bp_normal</option>
                                <option value="bp_abnormal">bp_abnormal</option>

                            </select> */}
                            
                        </div>
                        
                    </div>
                    
                    
                </div>
                {/* Third row */}
                <div class="row">
                    
                    {/* <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                        <div className="form-group">
                            
                            <span class="label">Spo2 Range</span>
                            <input type="text" value={spo2range} onChange={(e)=>setSpo2Range(e.target.value)} className="form-control" />
                            
                        </div>
                        
                    </div> */}
                    
                    {/* <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                        <div className="form-group">
                            
                            <span class="label">Blood pressure range</span>2
                            <input type="text" value={bloodpressurerange} onChange={(e)=>setBloodPressureRange(e.target.value)} className="form-control" />
                            
                        </div>
                        
                    </div> */}
                    
                    
                </div>
                {/* second Fouth*/}
                <div class="row">
                    
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                        <div className="form-group">
                            
                            <span class="label">Blood Sugar</span>
                            <input type="text" value={bloodsugarcode} onChange={(e)=>setBloodSugarCode(e.target.value)} className="form-control" placeholder="mg/dL or mmol/L" />
                            {/* <select value={bloodsugarcode} onChange={(e) => setBloodSugarCode(e.target.value)} className="form-control">
                                <option value>Select..</option>
                                <option value="bs_normal">bs_normal</option>
                                <option value="abs_normal">abs_normal</option>

                            </select> */}
                        </div>
                        
                    </div>
                    
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                        <div className="form-group">
                            
                            <span class="label">Eye coloration</span>
                            {/* <input type="text" value={eyecoloration} onChange={(e)=>setEyeColoration(e.target.value)} className="form-control" /> */}
                            <select value={eyecoloration} onChange={(e) => setEyeColoration(e.target.value)} className="form-control" >
                                <option value="">Please select</option>
                                <option value="normal">Normal</option>
                                <option value="abnormal">Abnormal</option>

                            </select>
                            
                        </div>
                        
                    </div>
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                        <div className="form-group">
                            
                            <span class="label">Respiration </span>
                            <input type="number" value={respirationrate} onChange={(e)=>setRespirationRate(e.target.value)} className="form-control" placeholder="per minute" />
                            {/* <select value={respirationrate} onChange={(e) => setRespirationRate(e.target.value)} className="form-control">
                                <option value="">Select...</option>
                                <option value="br_high">br_high</option>
                                <option value="br_normal">br_normal</option>
                                <option value="br_low">br_low</option>

                            </select> */}
                            
                        </div>
                        
                    </div>
                    
                    
                </div>
                {/* second Fift*/}
                <div class="row">
                    
                    
                    
                    
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                        <div className="form-group">
                            
                            <span class="label">Temperature</span>
                            <input type="text" value={temperature} onChange={(e)=>setTemperature(e.target.value)} className="form-control" placeholder="[  ] O C  O F" />
                            
                        </div>
                        
                    </div>
                    
                    
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <button className="btn btn-primary" onClick={()=>submitData()}>Add</button>
                    </div>
                    <div className="col-sm-6">
                        {/* <button className="btn btn-primary" onClick={()=>submitData()}>Add Diagnosis</button>
                         */}
                        <Spinner animation={spin} variant="primary" /> 
                    </div>
                </div>
                {/* second Fift*/}
                
                
         </Modal.Body>
      </Modal>
        

    </div>);
}

export default AllDiagnosis;
