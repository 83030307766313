import React,{useContext, useState} from 'react';
import { Card, Button } from 'react-bootstrap'
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { QuestionContext } from '../../App';
const HabitsEdit = () => {
    const [redirect, setRedirect]=useState(false)
    const { t, i18n } = useTranslation()

    const {questionstate,dispatchQuestion} = useContext(QuestionContext);
    return (<div className="container">
        {redirect ? (<>
            <Redirect to="/question2" />
            
            

      </>) : (<></>)}
        
        <div className="row">
            <div className="col-sm-3" />
            <div className="col-sm-6">
                {/* <Card style={{ border: 'rounded-pill', borderRadius: "30px" }} >
                    <Card.Body> */}
                        
                        

                    
                <div className="darkbluebackground row alert text-white ">
                    
                    <table>
                        <tr>
                            <td>
                                <div className="col-sm-2">
                        <Button variant="link" as={Link} size="lg" to="/question3edit" style={{ color: 'white', fontWeight: '600' }} ><i class="fa fa-arrow-left" aria-hidden="true"></i></Button>
                    </div>
                                
                            </td>
                            <td>
                                <div className="col-sm-6" >
                            <div className="row">
                                
                            <div className="col-12">
                           <h4> {t('wellness.optional.optional')}</h4>

                            </div>
                            </div>
                           
                           
                        </div>

                            </td>
                        </tr>
                    </table>
                    
            
        </div>
                        <div className="text-center">
                            <h4>{t('wellness.optional.habit')}</h4>
                           <small> {t('wellness.optional.habit_sub')}</small>
                        </div>

                        
                        <div className="row">
                            <div className="col-sm-5">
                                <div className="form-group">
                                    <label>
                                    {t('wellness.habits.drink')}
                                    </label>
                            <select className="form-control" value={questionstate.drinks }  onChange={(e)=>dispatchQuestion({type:'drinks', value:e.target.value})}>
                            
                                <option value="">{t('wellness.optional.select')}...</option>
                                        <option value={t('wellness.frequency.no')}>{t('wellness.frequency.no')}</option>
                                        <option value={t('wellness.frequency.rare')}>{t('wellness.frequency.rare')}</option>
                                        <option value={t('wellness.frequency.weekly')}>{t('wellness.frequency.weekly')}</option>
                                        <option value={t('wellness.frequency.daily')}>{t('wellness.frequency.daily')}</option>
                                    </select>
                                </div>
                    </div>
                    <div className="col-sm-5">
                                <div className="form-group">
                                    <label>
                                    {t('wellness.habits.frequency')}
                                    </label>
                                    <select className="form-control" value={questionstate.drink_frequency }  onChange={(e)=>dispatchQuestion({type:'drink_frequency', value:e.target.value})}>
                                        <option value="">{t('wellness.optional.select')}...</option>
                                        <option value={t('wellness.frequency.no')}>{t('wellness.frequency.no')}</option>
                                        <option value={t('wellness.frequency.rare')}>{t('wellness.frequency.rare')}</option>
                                        <option value={t('wellness.frequency.weekly')}>{t('wellness.frequency.weekly')}</option>
                                        <option value={t('wellness.frequency.daily')}>{t('wellness.frequency.daily')}</option>
                                    </select>
                                </div>
                            </div>

                        </div>
                        
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label>
                                    {t('wellness.optional.cant_find')}
                                    </label>
                                    <textarea className="form-control" rows="4" value={questionstate.drink_other }  onChange={(e)=>dispatchQuestion({type:'drink_other', value:e.target.value})}></textarea>

                                </div>
                            </div>

                        </div>
                        
                        
                        
                       <div className="row" style={{paddingTop:'20px'}}>
                            <div className="col-sm-4">
                                
                                
                               
                            </div>
                            <div className="col-sm-6">
                                
                                <div>
                                    
                            <Button variant="link" as={Link} size="lg" to="/question3edit" style={{ color: '#1d7ced', fontWeight: '600' }} ><i class="fa fa-arrow-left" aria-hidden="true"></i></Button>
                            <br /> {t('wellness.optional.back')}
                                    
                                </div>
                               
                            </div>
                            
                        </div>
                        


                
               
                {/* </Card.Body>
                </Card> */}
            </div>
            

            
        </div>
        


    </div>);
}
 
export default HabitsEdit;